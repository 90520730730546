import { Component, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { Consenso } from '../../modello/consenso'

import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { ThemeService } from '../../servizi/theme.service'

import { Base64 } from 'js-base64'

import { Segnalante } from '../../modello/segnalante'

import * as moment from 'moment'
import { decodificaStatoRichiestaConsenso } from '../../utility/utility'
import { DirittiSegnalazione } from '../../modello/diritti-segnalazione'
import { Odv } from '../../modello/odv'
import { SessionData } from '../../sessione/dati-sessione'
import { cognomeSegnalante, nomeSegnalante, odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante } from '../../utility/helper-segnalazioni'
import { AuthService } from '../../servizi/auth.service'

@Component({
  selector: 'app-crea-modifica-informativa-dialog',
  templateUrl: 'dialog-richiesta-consenso-segnalante-component.html',
})
export class RichiestaConsensoSegnalanteDialogComponent {


  editorConfigTb: AngularEditorConfig = {
    enableToolbar: false,
    showToolbar: false,
    editable: false,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Inserisci testo qui...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }
  consenso: Consenso
  diritti: DirittiSegnalazione[]
  odvSelezionabili: DirittiSegnalazione[]
  odvCheRichiedeConsenso: Odv
  form: UntypedFormGroup
  segnalante: Segnalante
  private _language = 'it'

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RichiestaConsensoSegnalanteDialogComponent>, private snackBar: MatSnackBar,
    private dialog: MatDialog, private segnalazioniService: SegnalazioneService, private authService: AuthService
    , private sessionData: SessionData) {
    this.segnalante = data['segnalante']
    this.consenso = data['consenso']
    this.diritti = data.diritti
    this.odvSelezionabili = odvUtenteChePossonoFareRichiestaDiConsensoAlSegnalante(this.authService.getUser(),
    this.sessionData.segnalazioneSelezionata)
    if (!this.consenso) {
      this.consenso = new Consenso()
      this.consenso.nome = this.segnalante?.nome
      this.consenso.cognome = this.segnalante?.cognome
    }

    if (this.consenso?.odvRichiedente) {
      this.odvSelezionabili.push(this.consenso.odvRichiedente)
    }

    if (this.odvSelezionabili.length > 0 && !this.consenso?.odvRichiedente) {
      this.odvCheRichiedeConsenso = this.odvSelezionabili[0].odvDestinatario
    } else {
      this.odvCheRichiedeConsenso = this.consenso?.odvRichiedente.odvDestinatario
    }

    this.createForm()
  }

  sbloccato = false

  toggleLock() {
    this.sbloccato = !this.sbloccato
    this.createForm()
  }


  private createForm() {
    this.form = this.fb.group({
      id: this.fb.control({ value: this.consenso.id, disabled: true }),
      stato: this.fb.control({ value: decodificaStatoRichiestaConsenso(this.consenso.stato), disabled: true }),
      dataRichiesta: this.fb.control({ value: moment(this.consenso.dataRichiesta).format('DD/MM/YYYY'), disabled: true }),
      dataRisposta: this.fb.control({ value: moment(this.consenso.dataRisposta).format('DD/MM/YYYY'), disabled: true }),
      tipoConsenso: this.fb.control({ value: this.consenso.tipoConsenso, disabled: false }),
      nome: this.fb.control({ value: this.sbloccato ?
        nomeSegnalante(this.consenso, this.sessionData.configurazione.custodiaIdentita) : '***', disabled: true }),
      cognome: this.fb.control({ value: this.sbloccato ?
        cognomeSegnalante(this.consenso, this.sessionData.configurazione.custodiaIdentita) : '***',
        disabled: true }),
      odvRichiedente: this.fb.control({ value: this.consenso.odvRichiedente ? this.consenso.odvRichiedente :
        this.odvSelezionabili[0], disabled: false }),
      testo: this.fb.control({ value: this.consenso.informativa?.testo ? Base64.decode(this.consenso.informativa?.testo) :
        '', disabled: false }),
    })
  }

  onGruppoSelezionato(event, odv: Odv) {
    if (event.source.selected) {
      this.odvCheRichiedeConsenso = odv
    }
  }

  close() {
    this.dialogRef.close()
  }

  salva() {
    const dirittiSegnalazioneIndex = this.sessionData.segnalazioneSelezionata.dirittiSegnalazioneOdv.
      findIndex(diritto => +diritto.odvDestinatario.id === +this.odvCheRichiedeConsenso.id)
    const dirittiSegnalazione = this.sessionData.segnalazioneSelezionata.dirittiSegnalazioneOdv[dirittiSegnalazioneIndex]
    this.consenso.odvRichiedente = dirittiSegnalazione
    // this.consenso.nome = this.form.controls.nome.value
    // this.consenso.cognome = this.form.controls.cognome.value
    this.consenso.stato = Consenso.STATI.ATTESA_INOLTRO
    this.dialogRef.close({ data: this.consenso })
  }

  public set language(language: string) {
    this._language = language
  }

}
