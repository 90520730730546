import { ChatService } from './../servizi/chat.service'
import { AuthService } from './../servizi/auth.service'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core'
import { Segnalazione } from '../modello/segnalazione'
import { SessionData } from '../sessione/dati-sessione'
import { Chat } from '../modello/chat'
import { SegnalazioneService } from '../servizi/segnalazione.service'

import { Router, ActivatedRoute } from '@angular/router'
import { Esito, ESITO_IN_PROGRESS, ESITO_OK } from '../utility/esito'
import {
  puoChattareConSegnalante,
  membershipDaGestorePerSegnalazione,
  isMembroDiGruppo,
  isSegnalante,
  isUtenteMembrodiOdvSceltoDaSegnalante,
} from '../utility/helper-segnalazioni'
import { User } from '../modello/user'
import { Messaggio } from '../modello/messaggio'
import { Subscription } from 'rxjs'
import { Odv } from '../modello/odv'
import { DirittiSegnalazione } from '../modello/diritti-segnalazione'
import { NuovaChatDialogComponent } from './nuova-chat.dialog.component'
import { SelezionaGruppoOdvDialogComponent } from './seleziona-gruppo-odv.dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { MessaggiStore } from '../stores/messaggi.store'
import { DirittiLavorazione } from '../modello/diritti-lavorazione'
import { DialogService } from '../servizi/dialog.service'

@Component({
  selector: 'app-chat-manager',
  templateUrl: './chat-manager.component.html',
  styleUrls: ['./chat-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChatManagerComponent implements OnInit, OnDestroy, AfterViewInit {
  oldsegnalazioneId: number

  chats: Chat[]
  chatSelezionata: Chat
  gruppiFiltrati: Odv[]
  @ViewChild('inputPartecipanti', { static: false })
  inputPartecipante: ElementRef
  @ViewChild('inputPartecipanti', {
    static: false,
    read: MatAutocompleteTrigger,
  })
  autoTrigger: MatAutocompleteTrigger
  formPartecipanti: UntypedFormGroup

  partecipanteSelezionato: boolean
  tuttiIGruppi: Odv[]
  odvPartecipazioneChat: Odv
  // partecipantiAllaChat: Odv[];

  caricaChatSegnalanteGestore = false

  mappaPseudonimi = new Map()

  sottoscrizioni: Subscription[] = []

  @Input() input: ElementRef

  fissato: boolean
  hover: boolean

  dialogRefCreazioneChat: any
  dialogRefSelezioneGruppo: any
  idSegnalazioneSelezionata: any

  panelOpenState: boolean

  constructor(
    private segnalazioneService: SegnalazioneService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private segnalazioniService: SegnalazioneService,
    private chatService: ChatService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore,
    private dialogService: DialogService
  ) { }

  ngAfterViewInit(): void {
    if (this.segnalazioneSelezionata !== undefined) {
      this.recuperaChatSegnalazione(this.segnalazioneSelezionata?.id)
    }
  }

  ngOnInit() {
    this.panelOpenState = true
    if (this.sessionData.segnalazioni === undefined) {
      this.router.navigate(['home'])
    }
    this.segnalazione =
      this.sessionData.segnalazioneSelezionata ||
      this.sessionData.segnalazioni[0]
    const tmp = this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.caricaChatSegnalanteGestore = params.chatSegnalanteGestore
      console.log(this.caricaChatSegnalanteGestore)
    })
    this.sottoscrizioni.push(tmp)
    this.sottoscrizioni.push(
      this.chatService.notificaPerChatSelezionata.subscribe((chat: Chat) => {
        this.refreshChatSelezionata()
      })
    )
    this.createForm()
    // this.chatSelezionata = SessionData.chatSelezionata;
    if (this.sessionData.chatSelezionata) {
      // console.log('chat selezionata', SessionData.chatSelezionata)
      this.chatSelezionata = this.sessionData.chatSelezionata
      this.impostaChatSelezionata(this.chatSelezionata.id)
      this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
        +this.chatSelezionata.id
      )
      this.recuperaTuttiIGruppi()
    }

    const sottoscrizione =
      this.segnalazioneService.notifichePerMessaggioNonLettoSelezionato.subscribe(
        (messaggio: Messaggio) => {
          const segnalazione = this.sessionData.segnalazioni.find(
            (segna) => +segna.id === +messaggio.chat.segnalazione.id
          )
          if (segnalazione) {
            this.sessionData.cambiaSegnalazioneSelezionata(+segnalazione.id)
          }

          this.sessionData.chatSelezionata = messaggio.chat

          if (this.chatSelezionata !== this.sessionData.chatSelezionata) {
            this.chatSelezionata = this.sessionData.chatSelezionata
            console.log('2>>>>>>>>>>>>>>>>')

          }
          this.recuperaTuttiIGruppi()
        }
      )
    this.sottoscrizioni.push(sottoscrizione)

    this.sottoscrizioni.push(
      this.segnalazioniService.notificaPerAggiornamentiDirittoSegnalazione.subscribe(
        (dirittoSegnalazione) => {
          if (this.segnalazioneSelezionata?.dirittiSegnalazioneOdv) {
            const indice =
              this.segnalazioneSelezionata?.dirittiSegnalazioneOdv.findIndex(
                (diritto) => +diritto.id === +dirittoSegnalazione.id
              )
            if (indice !== -1) {
              this.segnalazioneSelezionata?.dirittiSegnalazioneOdv.splice(
                indice,
                1,
                dirittoSegnalazione
              )
            }
          }
        }
      )
    )
    this.sottoscrizioni.push(
      this.messaggiStore.messaggiChatSelezionataAggiornati.subscribe((messaggi) => {
        if (this.chatSelezionata) {
          this.recuperaChatById(this.chatSelezionata.id)
        }
      })
    )

    this.sottoscrizioni.push(
      this.segnalazioneService.notifichePerSegnalazioneDisinoltrata.subscribe
      ((segnalazione: Segnalazione) => {
        // se la segnalazione è la stessa aggiorno la chat
        if (this.chatSelezionata.segnalazione.id === segnalazione.id) {
          this.chatSelezionata = undefined
          this.chats = []
          this.recuperaTuttiIGruppi()
          this.snackBar.open(
            $localize`Non hai più accesso alla segnalazione con oggetto ${segnalazione.oggetto} e le relative chat.`,
            null,
            {
              duration: 8000,
            }
          )
        }
      })
    )
  }

  refreshChatSelezionata() {
    if (this.sessionData.chatSelezionata) {
      // console.log('chat selezionata', SessionData.chatSelezionata)
      this.chatSelezionata = this.sessionData.chatSelezionata
      this.impostaChatSelezionata(this.chatSelezionata.id)
      this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
        +this.chatSelezionata.id
      )
      this.recuperaTuttiIGruppi()
    }
  }

  ngOnDestroy(): void {
    this.sessionData.chatSelezionata = undefined
    this.chatService.annullaSottoscrizioniPerModificheChat()
    this.sottoscrizioni.forEach((sottoscrizione) =>
      sottoscrizione.unsubscribe()
    )
  }

  get segnalazioneSelezionata() {
    return (
      this.sessionData.segnalazioneSelezionata ||
      this.sessionData.segnalazioni[0]
    )
  }
  private createForm() {
    this.formPartecipanti = this.fb.group({
      partecipanti: this.fb.control({ value: '', disabled: false }),
    })
  }

  get segnalazioni() {
    return this.sessionData.segnalazioni
  }

  get segnalazione() {
    return this.sessionData.segnalazioneSelezionata
  }
  set segnalazione(s: Segnalazione) {
    if (s) {
      this.sessionData.nuovaSegnalazioneSelezionata(s)
    }
  }
  onSegnalazioneSelezionata(event) {
    // console.log(
    //  '!!!!!!!!!!!!!!!!!!!! chiamato onSegnalazioneSelezionata !!!!!!!!!!!!!!!!!!!!', event
    // )
    // if (event.isUserInput === true) {
    if (
      event.source.value !== undefined &&
      event.source.value !== null &&
      event.source.selected &&
      event.source.value.id !== this.idSegnalazioneSelezionata
    ) {
      this.idSegnalazioneSelezionata = event.source.value.id
      this.segnalazioniService.recuperaSegnalazione(+this.idSegnalazioneSelezionata).subscribe
        ((esito) => {
          if (esito.esito === ESITO_OK) {
            const segnalazione = JSON.parse(esito.payload)
            this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
            this.messaggiStore.messaggiChatSelezionata = []
            if (event.isUserInput) {
              this.sessionData.chatSelezionata = undefined
            }
            this.recuperaChatSegnalazione(event.source.value.id).then(() => {
              this.chatSelezionata = undefined

              if (this.sessionData.chatSelezionata) {
                this.chatSelezionata = this.sessionData.chatSelezionata
              } else {
                this.selezionaChatSegnalante()
              }
              if (this.chatSelezionata) {
                // console.log('3>>>>>>>>>>>>>>>>')
                this.recuperaTuttiIGruppi()
              }
              this.partecipanteSelezionato = undefined
            })
          }
        })

    } else {
      // this.partecipantiAllaChat = [];
      // this.chatSelezionata = null
      // this.partecipanteSelezionato = undefined
      // this.messaggiStore.messaggiChatSelezionata = []
    }
  }

  async recuperaChatSegnalazione(idSegnalazione) {
    if (!idSegnalazione) {
      console.error('segnalazione undefined!!!!')
      return
    }
    return this.chatService
      .recuperaChatBySegnalazione(idSegnalazione)
      .then((esito) => {
        this.ref.markForCheck()
        if (esito.esito === ESITO_OK) {
          this.sessionData.cambiaSegnalazioneSelezionata(+idSegnalazione)
          this.chats = JSON.parse(esito.payload)
          if (this.caricaChatSegnalanteGestore) {
            this.caricaChatSegnalanteGestore = false
            if (!this.sessionData.chatSelezionata) {
              this.selezionaChatSegnalante()
            } else {
              this.impostaChatSelezionata(this.sessionData.chatSelezionata.id)
              this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
                +this.sessionData.chatSelezionata.id
              )
            }

            // console.log('4>>>>>>>>>>>>>>>>')

          }
          this.chatSelezionata = this.sessionData.chatSelezionata
          this.recuperaTuttiIGruppi()
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare le chat collegate alla segnalazione.
      ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Non è stato possibile recuperare le chat collegate alla segnalazione.
    ${error.toString()}`,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  selezionaChatSegnalante() {
    // console.log('seleziono chat segnalante...')
    if (!this.chats) {
      this.chats = []
    }
    const chatsConSegnalante = this.chats.filter(
      (chat) => chat.chatConSegnalante === true
    )
    // prendo l'ultima
    if (chatsConSegnalante.length > 0) {
      this.chatSelezionata = chatsConSegnalante[chatsConSegnalante.length - 1]
      this.impostaChatSelezionata(this.chatSelezionata.id)
      this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
        +this.chatSelezionata.id
      )
      this.recuperaTuttiIGruppi()
    }
  }

  onChatSelezionata(event, chat: Chat) {
    const idChat = event.source.value.id
    if (event.isUserInput) {
      this.chatSelezionata = chat
      // console.log('6>>>>>>>>>>>>>>>>')
      this.recuperaTuttiIGruppi()
      this.filtraElencoGruppi()
      // console.log('imposto chat con id ' + idChat)
    } else {
      if (this.chatSelezionata === undefined) {
        console.warn('[[[[[[[[[[[[[[[[ chat undefined !!!! ]]]]]]]]]]]]]]]]]')
      }
      //  disiscrivi dalle notifiche per la vecchia chat
      // this.segnalazioneService.annullaSottoscrizionePerModificheChat(idChat);
    }
  }

  impostaChatSelezionata(idChat: any): any {
    // this.recuperaMessaggiChatSelezionata(idChat);
    this.chatService.sottoscriviPerModificheChat(idChat)
    // this.partecipantiAllaChat = this.chatSelezionata.gruppiPartecipanti;
    // this.sottoscrizioni.push(this.chatService.notificaPerAggiornamentoChat.subscribe(idChat1 => {
    //   this.recuperaChatById(idChat1)
    // }))
  }

  recuperaChatById(idChat1: any) {
    this.chatService
      .recuperaChatById(idChat1)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          //  verifica se faccio ancora parte della chat
          const chat = JSON.parse(esito.payload) as Chat
          // se la chat selezionata è la stessa gli sostituisco i partecipanti
          if (this.chatSelezionata.id === chat.id) {
            this.chatSelezionata.gruppiPartecipanti =
              chat.gruppiPartecipanti
          }
          if (
            chat.chatConSegnalante &&
            isSegnalante(
              this.segnalazioneSelezionata,
              this.authService.getUser()
            )
          ) {
            return
          }
          const indice = chat.gruppiPartecipanti.findIndex((grp) =>
            isMembroDiGruppo(this.authService.getUser(), grp)
          )
          if (indice === -1) {
            //  non fai più parte della chat!!!!
            this.chatSelezionata = undefined
            this.snackBar.open(
              $localize`Il moderatore ti ha escluso dalla chat!`,
              null,
              {
                duration: 4000,
              }
            )
          } else {
            // se la chat selezionata non è la stessa attuale la cambio
            if (this.chatSelezionata.id !== chat.id) {
              this.chatSelezionata = chat
            }
            //this.chatSelezionata = chat
            // console.log('8>>>>>>>>>>>>>>>>')
            this.recuperaTuttiIGruppi()
          }
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare le info aggiornate della chat.
          ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Non è stato possibile recuperare le info aggiornate della chat.
        ${error.toString()}`,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  /* onGruppoSelezionato (event, odv: Odv) {
this.odvPartecipazioneChat = odv;
} */

  onModeraChatClick() {
    //  verifica se è necessario selezionare un gruppo OdV

    // if (this.authService.getUser()?.odvMembership.length == 1) {
    if (this.chatSelezionata.gruppiPartecipanti.length === 1) {
      this.odvPartecipazioneChat = this.chatSelezionata.gruppiPartecipanti[0]
      this.impostaModeratore()
    } else {
      this.openDialogSelezioneGruppoModeratore()
    }
  }

  private impostaModeratore() {
    this.chatService
      .impostaModeratore(
        +this.chatSelezionata.id,
        this.odvPartecipazioneChat.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.chatSelezionata.moderatore = this.odvPartecipazioneChat
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile assegnare il moderatore alla chat.
      ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Non è stato possibile impostare il moderatore della chat.
    ${error.toString()}`,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  onRilasciaModeratoreChatClick() {
    this.chatService
      .impostaModeratore(+this.chatSelezionata.id, undefined)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.chatSelezionata.moderatore = undefined
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile disassegnare il moderatore alla chat.
      ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Non è stato possibile impostare il moderatore della chat.
    ${error.toString()}`,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  membroOdv(): boolean {
    return this.authService.getUser()?.odv
  }

  moderatore(): boolean {
    return (
      this.chatSelezionata.moderatore &&
      isMembroDiGruppo(
        this.authService.getUser(),
        this.chatSelezionata.moderatore
      )
    )
  }

  gestibile(): boolean {
    const gest =
      this.chatSelezionata.moderatore &&
      isMembroDiGruppo(
        this.authService.getUser(),
        this.chatSelezionata.moderatore
      )
    return gest
  }

  sonoIo(id) {
    return this.authService.getUser()?.id === id
  }

  puoChattareConSegnalante(): boolean {
    return puoChattareConSegnalante(
      this.authService.getUser(),
      this.segnalazioneSelezionata
    )
  }

  tuttiPartecipantiAbilitati(): boolean {
    // ricavo i diritti segnalazione dei gruppi partecipanti
    const dirittiSegnalazione = this.segnalazioneSelezionata.dirittiSegnalazioneOdv.filter(
      (diritto) =>
        this.chatSelezionata.gruppiPartecipanti.find(
          (gruppo) => +gruppo.id === +diritto.odvDestinatario.id
        ) !== undefined
    )
    //  verifico se tutti i diritti segnalazione sono abilitati
    return dirittiSegnalazione.every((diritto) => diritto.comunicazioneConSegnalante)

  }

  sonoMembroDiGruppo(gruppo: Odv) {
    return isMembroDiGruppo(this.authService.getUser(), gruppo)
  }

  isGruppoEscluso(gruppo: Odv) {
    const cond1 = this.segnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        +diritto.odvDestinatario.id === +gruppo.id
    ) === undefined
    const cond2 = this.segnalazione.dirittiSegnalazioneOdv.find(
      (diritto) =>
        +diritto.odvDestinatario.id === +gruppo.id && diritto.cancellato === true
    ) !== undefined
    return cond1 || cond2
  }

  isModeratore(gruppo: Odv, chat: Chat) {
    return chat.moderatore?.id === gruppo.id
  }

  remove(gruppo: Odv): void {
    const index = this.chatSelezionata.gruppiPartecipanti.findIndex(
      (gr) => +gr.id === +gruppo.id
    )

    if (index >= 0) {
      // const gruppoOdv = gruppoOdvCoinvolto(this.segnalazioneSelezionata, gruppo);
      this.chatService
        .eliminaGruppoPartecipanteChat(
          +this.chatSelezionata.id,
          +this.segnalazioneSelezionata?.id,
          gruppo.id
        )
        .then((esito) => {
          if (esito.esito === ESITO_OK) {
            this.chatSelezionata.gruppiPartecipanti.splice(index, 1)
            this.gruppiFiltrati.push(gruppo)
            // this.partecipantiAllaChat = this.chatSelezionata.gruppiPartecipanti;
            // this.segnalazioneSelezionata?.chatAperte.push(this.chatSelezionata);
          } else {
            this.snackBar.open(
              $localize`Non è stato possibile rimuovere l'utente dalla chat.
          ${esito.descrizioneEsito}`,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
      // this.formGestore.controls.azioniFollowUpForm.markAsDirty();
    }
  }

  removeSegnalante() {
    console.log('remove segnalante called')
  }

  add(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value

    // Add our action
    if ((value || '').trim()) {
      const tmpPartecipante = new Odv()
      // tmpPartecipante.azione = value.trim();
      this.addPartecipante(tmpPartecipante)
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
    }
  }

  addPartecipante(tmpPartecipante: Odv): any {
    let duplicato
    if (this.chatSelezionata.gruppiPartecipanti) {
      duplicato = this.chatSelezionata.gruppiPartecipanti.find(
        (partecipante) => partecipante.id === tmpPartecipante.id
      )
    } else {
      const tmp = new Array<Odv>()
      this.chatSelezionata.gruppiPartecipanti = tmp
    }

    if (!duplicato) {
      // const gruppoOdv = gruppoOdvCoinvolto(this.segnalazioneSelezionata, tmpPartecipante);
      this.chatService
        .aggiungiGruppoPartecipanteChat(
          +this.chatSelezionata.id,
          +this.segnalazioneSelezionata?.id,
          tmpPartecipante.id
        )
        .then((esito) => {
          if (esito.esito === ESITO_OK) {
            this.chatSelezionata.gruppiPartecipanti.push(tmpPartecipante)
            // this.gruppiPartecipanti = this.gruppiPartecipanti();
            this.filtraElencoGruppi()
            // this.segnalazioneSelezionata?.chatAperte.push(this.chatSelezionata);
          } else {
            this.snackBar.open(
              $localize`Non è stato possibile aggiungere l'utente alla chat.
          ${esito.descrizioneEsito}`,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
    } else {
      // console.log('azione già presente!!!!!');
    }
  }

  onAggiungiSegnalante() {
    this.chatService
      .aggiungiSegnalanteAChat(
        +this.chatSelezionata.id,
        +this.segnalazioneSelezionata?.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.chatSelezionata.chatConSegnalante = true
          this.recuperaTuttiIGruppi()
          // this.gruppiPartecipanti = this.gruppiPartecipanti();
          // this.segnalazioneSelezionata?.chatAperte.push(this.chatSelezionata);
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile aggiungere il segnalante alla chat.
      ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
  }

  onEliminaSegnalante() {
    this.chatService
      .eliminaSegnalanteDaChat(
        +this.chatSelezionata.id,
        +this.segnalazioneSelezionata?.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.chatSelezionata.chatConSegnalante = false
          this.recuperaTuttiIGruppi()
          // this.gruppiPartecipanti = this.gruppiPartecipanti();
          // this.segnalazioneSelezionata?.chatAperte.push(this.chatSelezionata);
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile eliminare il segnalante dalla chat.
      ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
  }

  openPanel(): void {
    setTimeout(() => {
      this.autoTrigger.openPanel()
    }, 1)
  }

  selected(partecipante: Odv) {
    this.partecipanteSelezionato = true
    // console.log('azione selezionata ' + azione.azione);
    this.addPartecipante(partecipante)
    this.inputPartecipante.nativeElement.value = ''
    this.formPartecipanti.controls.partecipanti.setValue('')
    // console.log('-4-');
    console.log('10>>>>>>>>>>>>>>>>')
    this.recuperaTuttiIGruppi()
  }

  gestore(partecipante: Odv) {
    return (
      partecipante &&
      this.segnalazioneSelezionata &&
      this.segnalazioneSelezionata?.gestore &&
      this.segnalazioneSelezionata?.gestore.id === partecipante.id
    )
  }

  get odv() {
    return this.authService.getUser()?.odv
  }

  get odvSceltoDaSegnalante() {
    return this.authService.getUser()?.odv && (isUtenteMembrodiOdvSceltoDaSegnalante(
      this.segnalazioneSelezionata,
      this.authService.getUser()
    ))
  }

  get possoChattareConSegnalante() {
    return puoChattareConSegnalante(
      this.authService.getUser(),
      this.segnalazioneSelezionata
    )
  }

  recuperaTuttiIGruppi(): any {
    // console.log('-6-');

    this.chatService
      .recuperaGruppiPerChat(
        this.segnalazioneSelezionata ? +this.segnalazioneSelezionata?.id : null,
        this.chatSelezionata ? +this.chatSelezionata.id : null
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.tuttiIGruppi = JSON.parse(esito.payload)
          this.gruppiFiltrati = JSON.parse(esito.payload)
        } else {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare l'elenco degli utenti.
          ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000,
            }
          )
        }

        //  Ora elimina quelle già selezionate
        this.filtraElencoGruppi()
        // this.partecipantiAllaChat = this.gruppiPartecipanti();
      })
  }

  filtraElencoGruppi(): any {
    this.gruppiFiltrati = []
    if (
      this.chatSelezionata &&
      this.chatSelezionata.gruppiPartecipanti &&
      this.tuttiIGruppi
    ) {
      this.gruppiFiltrati = this.tuttiIGruppi.filter(
        (gruppo) =>
          (!this.chatSelezionata.gruppiPartecipanti ||
            !this.chatSelezionata.gruppiPartecipanti.some(
              (part) => +part.id === +gruppo.id
            )) && !this.isGruppoEscluso(gruppo)
      )
    }
  }
  doChatCompareFn(v1: Chat, v2: Chat): boolean {
    return chatCompareFn(v1, v2)
  }

  doSegnalazioneCompareFn(v1: Segnalazione, v2: Segnalazione): boolean {
    return segnalazioneCompareFn(v1, v2)
  }

  odvMembershipToString(utente: User): string {
    return membershipDaGestorePerSegnalazione(
      this.segnalazioneSelezionata,
      utente
    )
  }

  partecipanti() {
    const soloUnPartecipantePerGruppo = []
    if (this.chatSelezionata.partecipanti) {
      this.chatSelezionata.partecipanti.forEach((partecipante) => {
        const indice = soloUnPartecipantePerGruppo.findIndex(
          (solo) =>
            solo.odvMembership &&
            solo.odvMembership.findIndex(
              (inner) =>
                partecipante.odvMembership.findIndex(
                  (finale) => +finale.id === +inner.id
                ) !== -1
            ) !== -1
        )
        if (indice === -1) {
          soloUnPartecipantePerGruppo.push(partecipante)
        }
      })
    }

    return soloUnPartecipantePerGruppo
  }

  // gruppiPartecipanti() {
  //   const destinatari = this.segnalazioneSelezionata?.dirittiSegnalazioneOdv.map(diritto => diritto.odvDestinatario);
  //   const partecipanti = this.chatSelezionata.partecipanti;
  //   const partecipantiMinimo = [];
  //   destinatari.forEach(gruppo => {
  //     partecipantiMinimo.push(partecipanti.find(part => part.odvMembership.some(odv => +odv.id === +gruppo.id)));
  //   });
  //   return partecipantiMinimo;
  // }

  apriSegnalazioneSelezionata() {
    console.log('selected:' + this.segnalazione.oggetto)
    SessionData.segnalazioneSelezionataElenco = this.segnalazione
    this.segnalazioniService
      .recuperaSegnalazione(+this.segnalazione.id)
      .subscribe(
        (esito) => {
          if (esito.esito === ESITO_OK) {
            this.sessionData.nuovaSegnalazioneSelezionata(
              JSON.parse(esito.payload)
            )
            this.router.navigate(['/segnalazione'])
          } else if (esito.esito === ESITO_IN_PROGRESS) {
            // Gestisci lo stato in progress come preferisci
            // Ad esempio, potresti mostrare un messaggio di attesa all'utente
            console.log(esito.descrizioneEsito);
          } else {
            this.snackBar.open(
              $localize`Non è stato possibile recuperare la segnalazione ` +
              JSON.stringify(esito.descrizioneEsito),
              null,
              {
                duration: 4000,
              }
            )
          }
        } ,
        () => {
          this.snackBar.open(
            $localize`Non è stato possibile recuperare la segnalazione ` +
            this.segnalazione.idPerSegnalante,
            null,
            {
              duration: 4000,
            }
          )
        }
      )
  }

  dirittiSegnalazioniGestibili(): DirittiSegnalazione[] {
    if (this.segnalazioneSelezionata?.dirittiSegnalazioneOdv) {
      const diritti =
        this.segnalazioneSelezionata?.dirittiSegnalazioneOdv.filter((diritto) =>
          isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario)
        )
      return diritti
    }
    return []
  }

  dirittiLavorazioneGestibili(): DirittiLavorazione[] {
    if (this.segnalazioneSelezionata?.dirittiLavorazione) {
      const diritti = this.segnalazioneSelezionata?.dirittiLavorazione.filter(
        (diritto) => isMembroDiGruppo(this.authService.getUser(), diritto.odv)
      )
      return diritti
    }
    return []
  }

  focusInput(input) {
    setTimeout(() => input.focus())
    // this.scroll(input);
  }

  openDialogCreazioneChat(): void {
    if (this.dialogRefCreazioneChat === undefined) {
      this.dialogRefCreazioneChat = this.dialog.open(NuovaChatDialogComponent, {
        width: '800px',
        data: {
          diritti: this.dirittiSegnalazioniGestibili(),
          lavorazioni: this.segnalazioneSelezionata?.lavorazioni,
        },
        hasBackdrop: true,
      })
      this.dialogRefCreazioneChat.afterClosed().subscribe((result) => {
        this.dialogRefCreazioneChat = undefined
        // console.log("creazione chat...." + JSON.stringify(result));
        if (result.data) {
          this.chats.push(result.data)
          this.chatSelezionata = result.data
          this.recuperaTuttiIGruppi()
        }

        // this.formChats.controls.chat.setValue(JSON.parse(esito.payload));
        // this.ref.detectChanges();
      })
    }
  }

  openDialogSelezioneGruppoModeratore(): void {
    if (this.dialogRefSelezioneGruppo === undefined) {
      this.dialogRefSelezioneGruppo = this.dialog.open(
        SelezionaGruppoOdvDialogComponent,
        {
          width: '800px',
          data: { diritti: this.dirittiSegnalazioniGestibili() },
          hasBackdrop: true,
        }
      )
      this.dialogRefSelezioneGruppo.afterClosed().subscribe((result) => {
        // console.log('modero con gruppo....' + JSON.stringify(result));
        if (result) {
          this.odvPartecipazioneChat = result.data
          this.dialogRefSelezioneGruppo = undefined
          this.impostaModeratore()
        }
      })
    }
  }

  get trascrizioneInCorso() {
    return this.segnalazioneSelezionata?.trascrizioneVocaliChat
  }

  get vocaliInibiti() {
    return this.segnalazioneSelezionata?.vocaliChatInibiti
  }

  async onInibisciAscolto() {
    const result = await this.dialogService.confirm(
      $localize`Conferma operazione`,
      $localize`Sei sicuro di aver completato il processo di trascrizione per inibire l'ascolto dei vocali del Segnalante? Confermi di volere inibire l'ascolto? L'operazione non sarà reversibile`
    )
    if (result) {
      this.chiudiProcessoTrascrizione()
    }
    return
  }

  chiudiProcessoTrascrizione() {

    this.segnalazione.trascrizioneVocaliChat = false
    this.segnalazioneService.salvaSegnalazione(this.segnalazione).then(
      (esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.segnalazione = JSON.parse(esito.payload)
          this.snackBar.open(
            $localize`Processo di inibizione ascolto messaggi audio completato con successo`,
            null,
            {
              duration: 2000,
            }
          )
        } else {
          this.snackBar.open(
            $localize`Errore durante la chiusura del processo di inibizione ascolto messaggi audio`,
            null,
            {
              duration: 6000,
            }
          )
        }
      },
      (error) => {
        this.snackBar.open(
          $localize`Errore durante il salvataggio del consenso audio`,
          null,
          {
            duration: 2000,
          }
        )
      }
    )
  }

  tooltipModeratore(chatSelezionata: Chat) {
    if (this.gestore(chatSelezionata.moderatore)) {
      return $localize`Moderatore Chat e Gestore Segnalazione`
    } else {
      return $localize`Moderatore Chat`
    }
  }

  pannelloApertoChiuso(aperto: boolean) {
    this.chatService.notificaPannelloManagerApertoChiuso.next(aperto)
  }
}

function chatCompareFn(v1: Chat, v2: Chat): boolean {
  return v1 && v2 ? v1.id === v2.id : v1 === v2
}

function segnalazioneCompareFn(v1: Segnalazione, v2: Segnalazione): boolean {
  return v1 && v2 ? v1.id === v2.id : v1 === v2
}
