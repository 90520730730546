import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { Lavorazione } from '../modello/lavorazione'
import { LogOperativo } from '../modello/log-operativo'
import { LogOperativoService } from '../servizi/log-operativo.service'
import { RicercaService } from '../servizi/ricerca.service'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../utility/esito'
import { apriSegnalazioneSelezionata, compare, compareDates, compareNumbers, Operazioni } from '../utility/utility'

@Component({
  selector: 'app-log-operativo-list',
  templateUrl: './log-operativo-list.component.html',
  styleUrls: ['./log-operativo-list.component.scss'],
})
export class LogOperativoListComponent
  implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [
    'dataOperazione',
    'operazione',
    'segnalazione',
    'lavorazione',
    'utente',
    'utenteBeneficiario',
    'odv',
    'odvDestinatario',
    'note',
  ]
  dataSource: MatTableDataSource<LogOperativo>
  private subscriptions: Array<Subscription> = []
  private paginatorData: Paginator = {
    pageIndex: 0,
    pageSize: 10,
    lowValue: 0,
    highValue: -1,
  }
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  // Variabili che saranno legate al paginator nel template
  totalCount = 0 // Inizialmente impostata a 0, deve essere aggiornata con il numero totale di elementi
  pageSize = 10 // Numero di elementi per pagina. Puoi impostarlo su un valore predefinito
  pageSizeOptions: number[] = [5, 10, 25, 100] // Opzioni per il numero di elementi per pagina
  logOperativi: LogOperativo[]

  constructor(
    private logOperativoService: LogOperativoService,
    private snackBar: MatSnackBar,
    private segnalazioniService: SegnalazioneService,
    private sessionData: SessionData,
    private router: Router,
    private route: ActivatedRoute,
    private ricercaService: RicercaService
  ) {}
  ngAfterViewInit(): void {
    this.loadLogOperativi()
  }
  ngOnDestroy(): void {
    // annulla le sottoscrizioni
    this.subscriptions?.forEach((sub) => sub.unsubscribe())
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], {
          queryParams: { initialize: true },
        })
      }
      return
    })
    this.dataSource = new MatTableDataSource()
    // Gestione evento cambiamento di pagina

    this.subscriptions.push(
      this.ricercaService.filtraElenco.subscribe((cercami: string) => {
        this.applyFilter(cercami)
      })
    )
  }

  configurePaginator() {
    const paginatorIntl = this.paginator._intl
    paginatorIntl.nextPageLabel = ''
    paginatorIntl.previousPageLabel = ''
    paginatorIntl.lastPageLabel = ''
    paginatorIntl.firstPageLabel = ''
  }

  public getPaginatorData(event) {
    console.log(event)
    /*
     */
    if (event.pageIndex === this.paginatorData.pageIndex + 1) {
      this.paginatorData.lowValue =
        this.paginatorData.lowValue + this.paginatorData.pageSize
      this.paginatorData.highValue =
        this.paginatorData.highValue + this.paginatorData.pageSize
    } else if (event.pageIndex === this.paginatorData.pageIndex - 1) {
      this.paginatorData.lowValue =
        this.paginatorData.lowValue - this.paginatorData.pageSize
      this.paginatorData.highValue =
        this.paginatorData.highValue - this.paginatorData.pageSize
    }
    this.paginatorData.pageIndex = event.pageIndex
    this.loadLogOperativi(event)
    console.log(
      '*** getPaginatorData -> ' + JSON.stringify(this.paginatorData)
    )
  }

  loadLogOperativi(pageEvent?: PageEvent, sortingCriteria?: any) {
    // Se pageEvent è fornito, utilizza pageEvent, altrimenti utilizza il paginator
    const pageIndex = pageEvent
      ? pageEvent.pageIndex
      : this.paginator.pageIndex
    const pageSize = pageEvent ? pageEvent.pageSize : this.paginator.pageSize

    this.logOperativoService
      .getAllLogOperativo(pageIndex, pageSize, sortingCriteria)
      .subscribe((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          // Estrai sia i dati che il conteggio totale dalla risposta
          const response = JSON.parse(esito.payload)
          this.logOperativi = response.data
          this.totalCount = response.total

          // Imposta i dati per la tabella
          this.dataSource.data = this.logOperativi

          // Imposta il conteggio totale dei record per il paginator
          // this.paginator.length = this.totalCount
          // this.paginator.pageSize = pageSize
          // this.paginator.pageIndex = pageIndex

          // this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.sort

          this.configurePaginator()
        } else {
          this.snackBar.open(
            $localize`Errore nel recupero del log delle operazioni. Errore ${esito.descrizioneEsito}`,
            'Errore',
            {
              duration: 6000,
            }
          )
        }
      })
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return
    }

    // Crea un oggetto che rappresenti i criteri di ordinamento
    const sortingCriteria = {
      orderBy: sort?.active? sort.active : '',
      direction: sort?.direction? sort.direction : '',
    }

    // Invia una richiesta al server con i criteri di ordinamento
    this.loadLogOperativi(undefined, sortingCriteria)
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase()

    this.dataSource.filterPredicate = (logOperativo, filter) => {
      console.log(logOperativo.dataOperazione?.toLocaleString())
      return (
        logOperativo.operazione.includes(filterValue) ||
        logOperativo.utente?.nome?.toLowerCase().includes(filterValue) ||
        logOperativo.utente?.cognome?.toLowerCase().includes(filterValue) ||
        logOperativo.utenteBeneficiario?.nome
          ?.toLowerCase()
          .includes(filterValue) ||
        logOperativo.utenteBeneficiario?.cognome
          ?.toLowerCase()
          .includes(filterValue) ||
        logOperativo.dataOperazione?.toLocaleString().includes(filterValue) ||
        logOperativo.segnalazione?.id?.toString().includes(filterValue) ||
        logOperativo.lavorazione?.id?.toString().includes(filterValue) ||
        logOperativo.segnalazione?.descrizione?.includes(filterValue) ||
        logOperativo.segnalazione?.descrizioneFatto?.includes(filterValue) ||
        logOperativo.note?.includes(filterValue)
      )
    }
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  getOperazioneLabel(operazione: Operazioni): string {
    switch (operazione) {
      case Operazioni.RicevimentoSegnalazione:
        return $localize`Ricevimento Segnalazione`
      case Operazioni.PresaInCarico:
        return $localize`Presa in Carico`
      case Operazioni.PresaVisioneDatiSegnalante:
        return $localize`Presa Visione Dati Segnalante`
      case Operazioni.PresaVisioneDatiSegnalati:
        return $localize`Presa Visione Dati Segnalati`
      case Operazioni.AperturaIstruttoria:
        return $localize`Apertura Istruttoria`
      case Operazioni.RiaperturaIstruttoria:
        return $localize`Riapertura Istruttoria`
      case Operazioni.RifiutoAssegnazione:
        return $localize`Rifiuto Assegnazione`
      case Operazioni.CondivisioneConAltroGestore:
        return $localize`Condivisione con Altro Gestore`
      case Operazioni.TermineCondivisioneConAltroGestore:
        return $localize`Termine Condivisione con Altro Gestore`
      case Operazioni.RichiestaConsensoSegnalante:
        return $localize`Richiesta Consenso Segnalante`
      case Operazioni.AnnullamentoRichiestaConsensoSegnalante:
        return $localize`Annullamento Richiesta Consenso Segnalante`
      case Operazioni.RispostaSegnalanteAllaRichiestaConsenso:
        return $localize`Risposta Segnalante alla Richiesta Consenso`
      case Operazioni.ChiusuraLavorazione:
        return $localize`Chiusura Lavorazione`
      case Operazioni.RiaperturaLavorazione:
        return $localize`Riapertura Lavorazione`
      case Operazioni.ConsensoAudioChatConcesso:
        return $localize`Consenso Audio Chat Concesso`
      case Operazioni.ConsensoAudioChatRevocato:
        return $localize`Consenso Audio Chat Non Concesso`
      case Operazioni.ConsensoAudioSegnalazioneConcesso:
        return $localize`Consenso Audio Segnalazione Concesso`
      case Operazioni.ConsensoAudioSegnalazioneRevocato:
        return $localize`Consenso Audio Segnalazione Non Concesso`
      case Operazioni.BloccoAscoltoAudioSegnalazione:
        return $localize`Ascolto Audio Segnalazione Disabilitato`
      case Operazioni.SbloccoAscoltoAudioSegnalazione:
        return $localize`Ascolto Audio Segnalazione Abilitato`
      case Operazioni.TrascrizioneVocaliTerminata:
        return $localize`Trascrizione Vocale Terminata`
      case Operazioni.AutoesclusioneUtenteGestore:
        return $localize`Autoesclusione all'accesso alla segnalazione`
      case Operazioni.RiammissioneUtenteGestore:
        return $localize`Riabilitazione all'accesso alla segnalazione`
      case Operazioni.ModificaDatiIdentificativiSegnalante:
        return $localize`Modifica Dati Identificativi Segnalante`
      case Operazioni.RitiroSegnalazione:
        return $localize`Ritiro Segnalazione`
      case Operazioni.AnnullamentoRitiroSegnalazione:
        return $localize`Annullamento Ritiro Segnalazione`
      case Operazioni.PianificazioneCancellazioneSegnalazione:
        return $localize`Cancellazione Segnalazione Pianificata`
      case Operazioni.AnnullamentoPianificazioneCancellazioneSegnalazione:
        return $localize`Pianificazione della Cancellazione Segnalazione Annullata`

      default:
        return operazione
    }
  }

  apriSegnalazioneSelezionata(segnalazione) {
    apriSegnalazioneSelezionata(segnalazione, this.segnalazioniService, this.sessionData, this.router, this.snackBar)
  }

  testoCellaLavorazione(lavorazione: Lavorazione) {
    if (lavorazione) {
      return (
        'Lavorazione ' +
        lavorazione.id +
        (lavorazione.noteFollowUp
          ? ' - ' + lavorazione.noteFollowUp.slice(0, 20) + '...'
          : '')
      )
    } else {
      return $localize`Nessuna Lavorazione`
    }
  }
  nomeUtente(logOperativo: LogOperativo) {
    if (logOperativo.utente) {
      if (!logOperativo.utente.odv) {
        return '********'
      }

      // se il nome inizia per ENCRYPTED: mostra asterischi
      if (
        (logOperativo.utente.nome &&
          logOperativo.utente.nome.startsWith('ENCRYPTED:')) ||
        (logOperativo.utente.cognome &&
          logOperativo.utente.cognome.startsWith('ENCRYPTED:')) ||
        !logOperativo.utente.nome ||
        !logOperativo.utente.cognome
      ) {
        return logOperativo.utente.userId
      } else {
        return logOperativo.utente.nome + ' ' + logOperativo.utente.cognome
      }
    }
  }

  nomeUtenteBeneficiario(logOperativo: LogOperativo) {
    if (logOperativo.utenteBeneficiario) {
      if (!logOperativo.utenteBeneficiario.odv) {
        return '********'
      }

      // se il nome inizia per ENCRYPTED: mostra asterischi
      if (
        (logOperativo.utenteBeneficiario.nome &&
          logOperativo.utenteBeneficiario.nome.startsWith('ENCRYPTED:')) ||
        (logOperativo.utenteBeneficiario.cognome &&
          logOperativo.utenteBeneficiario.cognome.startsWith('ENCRYPTED:')) ||
        !logOperativo.utenteBeneficiario.nome ||
        !logOperativo.utenteBeneficiario.cognome
      ) {
        return logOperativo.utenteBeneficiario.userId
      } else {
        return (
          logOperativo.utenteBeneficiario.nome +
          ' ' +
          logOperativo.utenteBeneficiario.cognome
        )
      }
    }
  }
}

export interface Paginator {
  pageIndex: number;
  pageSize: number;
  lowValue: number;
  highValue: number;
}
