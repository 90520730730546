<div class="overlay flex-row flex centered" *ngIf="!dataLoaded || !inizializzato">
  <mat-spinner diameter="50" strokeWidth="5" style="margin:auto"></mat-spinner>
</div>
<div class="theme flex-column flex-gap-30" *ngIf="dataLoaded && inizializzato ">
  <app-toolbar-landing></app-toolbar-landing>
  <div class="mini-padding flex-row">
    <div class="my-flex-element"></div>
    <mat-card class="no-border sfondo-bianco flex-item" [style.margin-top]="'30px'">
      <mat-card-content>
        <div class="sezione-1-landing">
          <div class="flex-column flex-fill flex-gap-30">
            <div class="flex-column flex-fill flex-gap-30">
              <div class="titolo-sezioni-landing-34 padding-4" i18n>SICURO E DISCRETO</div>
              <div class="testo-presentazione-landing-hind giustificato padding-4" [innerHTML]="getTestoHome()">
              </div>
            </div>

            <div class="sezione-2-landing">
              <div class="flex-column">
                <p class="sottotitolo-landing" i18n>DOCUMENTAZIONE WHISTLEBLOWING</p>
                <div *ngIf="mostraLinkProcedureWhistleblowing"> <a mat-button
                    (click)="apriDialogProceduraWhistleblowing()">
                    <i class="material-icons-outlined icona-documentazione-whistleblowing">description</i><span
                      class="testo-documentazione-whistleblowing" i18n> Procedura Whistleblowing
                    </span></a></div>
                <div *ngIf="mostraLinkInformativa"><a mat-button (click)="apriDialogInformativaTrattamentoDati()">
                    <i class="material-icons-outlined icona-documentazione-whistleblowing">description</i><span
                      class="testo-documentazione-whistleblowing" i18n> Informativa Privacy
                    </span></a></div>
                <div *ngIf="mostraLinkManualeUtente"><a mat-button (click)="apriDialogManualeUtente()">
                    <i class="material-icons-outlined icona-documentazione-whistleblowing">description</i><span
                      class="testo-documentazione-whistleblowing" i18n> Manuale Utente
                    </span></a></div>
                <div *ngIf="true"><a mat-button (click)="apriMisureDiSicurezza()">
                    <i class="material-icons-outlined icona-documentazione-whistleblowing">description</i><span
                      class="testo-documentazione-whistleblowing" i18n> Misure di Sicurezza
                    </span></a></div>
              </div>
              <div class="flex-column-start">
                <p class="sottotitolo-landing" i18n>NORMATIVA DI RIFERIMENTO</p>
                <div [innerHtml]="getNormativa()"></div>
              </div>
            </div>
          </div>
          <div class="flex-column-0p4 flex-gap-30 mini-padding">
            <mat-card class="sfondo-grigio-chiaro no-shadow" style="padding:48px!important">
              <mat-card-content>
                <div class="flex-column flex-gap-20">
                  <div class="titolo-sezioni-landing" i18n> INVIA UNA SEGNALAZIONE </div>
                  <div class="bottone-apertura-segnalazione-landing pointer"
                    (click)="onClickNuovaSegnalazione($event)" i18n>NUOVA
                    SEGNALAZIONE
                  </div>
                  <hr class="chiaro" />
                  <div class="testo-normale-landing" i18n>Hai già inviato una segnalazione?
                    Inserisci il relativo codice PIN per accedere.</div>
                  <form *ngIf="loginForm!==undefined" [formGroup]="loginForm" style="width:100%">
                    <div>
                      <div class="flex-fill flex-row">
                        <input type="{{textOrPassword}}" class="wpcf7-form-control input-pin" formControlName="password"
                          autocomplete="new-password" style="width:100%"
                          [placeholder]="modalitaPinUnico ? 'PIN*' : 'Password*'" required>
                        <button class="bottone-landing pointer" [disabled]="!loginForm.valid"
                          [ngClass]="{'bottone-abilitato':loginForm.valid, 'bottone-disabilitato': !loginForm.valid}"
                          (click)="onClickLogin($event)" i18n>Invia
                        </button>
                      </div>
                      <div *ngIf="password && password.invalid && (password.dirty || password.touched)"
                        style="margin-top: 20px;" class="alert alert-danger posizione-errori-password errori-form">

                        <mat-error *ngIf="password.errors.required && modalitaPinUnico" i18n>
                          - Il PIN è obbligatorio </mat-error>
                        <mat-error *ngIf="password.errors.required && !modalitaPinUnico" i18n>La
                          Password è obbligatoria
                        </mat-error>
                        <mat-error *ngIf="password.errors" i18n>
                          Il formato del PIN inserito non è corretto
                        </mat-error>

                      </div>
                    </div>


                  </form>
                </div>

              </mat-card-content>
            </mat-card>
          </div>

        </div>

      </mat-card-content>
    </mat-card>
    <div class="my-flex-element"></div>
  </div>
  <div class="footer-landing flex-column-start" style="padding-bottom: 40px!important;padding-top: 40px!important;"
    class="mini-padding">
    <div class="flex-row">
      <div class="colore-testo-toolbar my-flex-element sotto-toolbar-landing"></div>
      <div class="flex-container flex-item">
        <div class="flex-column-start" padding-top-30>
          <div class="titolo-sezioni-landing-34 colore-testo-toolbar sotto-toolbar-landing">F.A.Q.
          </div>
          <hr class="meno-chiaro" />

          <mat-accordion class="flex-column-start sezione-faq" style="margin-bottom: 20px; margin-right:25px">
            <mat-expansion-panel *ngFor="let domanda of faq; let i = index" [expanded]="i === 0" class="pannello-faq">
              <mat-expansion-panel-header class="testata-accordion">
                <mat-panel-title [innerHTML]="domanda.domanda">
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <div class="righe-serrate" [innerHTML]="calcolaInnerHtmlRisposta(domanda.risposta)"></div>

            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="flex-column-start">
          <div class="sezione-video colore-testo-toolbar titoli-sezione-video" i18n>
            VEDI TU STESSO
          </div>
          <hr class="meno-chiaro" />
          <div class="video_wrapper">
            <video #myVideo class="dimensioni-video" controls i18n>
              <source src="/assets/video/secure-blowing-presentazione-{{codiceLingua}}-{{subdomain}}.mp4"
                type="video/mp4">
              Il tuo browser non supporta il tag video.
            </video>

            <div #videoError style="display: none;">Il video non è disponibile al momento. Riprova più tardi.</div>

          </div>
        </div>
      </div>
      <div class="my-flex-element colore-testo-toolbar sotto-toolbar-landing" ></div>
    </div>
  </div>
  <div class="div-flottante footer-extra flex-row">
    <div class="testo-footer div-flex-element">
      {{versione}}
    </div>
    <div class="flex-item flex-row">
      <div style="padding: 16px" class="flex flex-gap-10">
        <img src="{{getLogoSecure()}}" alt="" style="width:74px;">
        <!--a routerLink="." id="privacy-policy" class="testo-powered" (click)="apriDialogPrivacyPolicy()" i18n> -
          Privacy</a-->
      </div>

      <div>
        <button mat-mini-fab color="primary" aria-label="Su" (click)="scrollToTop();">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
    <div class="div-flex-element">
    </div>
  </div>
</div>
