import { Component, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AngularEditorConfig } from '@kolkov/angular-editor'
import { DirittiSegnalazione } from '../../modello/diritti-segnalazione'
import { Odv } from '../../modello/odv'
import { SegnalazioneService } from '../../servizi/segnalazione.service'
import { SessionData } from '../../sessione/dati-sessione'
import { ESITO_OK } from '../../utility/esito'


@Component({
  selector: 'app-richiesta-sblocco-segnalante-dialog',
  templateUrl: 'richiesta-sblocco-segnalante.dialog.component.html'
})
export class RichiestaSbloccoSegnalanteDialogComponent {
  diritti: DirittiSegnalazione[]
  odvCheRichiedeSblocco: Odv
  formRichiesta: UntypedFormGroup

  editorConfig: AngularEditorConfig = {
    enableToolbar: true,
    showToolbar: true,
    toolbarHiddenButtons: [[],['insertImage', 'insertVideo',]],
    editable: true,
    spellcheck: false,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize `Motivazione sblocco`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RichiestaSbloccoSegnalanteDialogComponent>,
    private segnalazioneService: SegnalazioneService,
    private snackBar: MatSnackBar, private sessionData: SessionData
  ) {
    this.diritti = data.diritti
    if (this.diritti.length > 0) {
      this.odvCheRichiedeSblocco = this.diritti?.[0].odvDestinatario
    }
    this.createForm()
  }

  private createForm() {

    this.formRichiesta = this.fb.group({
      motivazioneRichiesta: this.fb.control({ value: '', disabled: false }),
      odv: this.fb.control({ value: this.diritti?.[0], disabled: false }),
    })
  }

  chiudiDialog() {
    this.dialogRef.close()
  }

  onGruppoSelezionato(event, odv: Odv) {
    if (event.source.selected) {
      this.odvCheRichiedeSblocco = odv
    }

  }

  onInoltraRichiesta() {

    const dirittiSegnalazioneIndex = this.sessionData.segnalazioneSelezionata.dirittiSegnalazioneOdv.
      findIndex(diritto => +diritto.odvDestinatario.id === +this.odvCheRichiedeSblocco.id)

    const dirittiSegnalazione = this.sessionData.segnalazioneSelezionata.dirittiSegnalazioneOdv[dirittiSegnalazioneIndex]
    dirittiSegnalazione.motivazioneRichiestaSbloccoVisibilitaSegnalante = this.formRichiesta.controls.motivazioneRichiesta.value
    this.segnalazioneService
      .richiediSbloccoSegnalante(dirittiSegnalazione)
      .then(esito => {
        if (esito.esito === ESITO_OK) {
          const diritto = JSON.parse(esito.payload)
          this.sessionData.segnalazioneSelezionata.dirittiSegnalazioneOdv.splice(dirittiSegnalazioneIndex, 1, diritto)
          this.snackBar.open(
            $localize `La richiesta di sblocco è stata inviata al custode. All\'esito riceverai una notifica via email.`,
            null,
            {
              duration: 4000
            }
          )
          // this.segnalazioneSelezionata.chatAperte.push(this.chatSelezionata);
        } else {
          this.snackBar.open(
            $localize `Non è stato possibile inviare la richiesta di sblocco al custode.
        ${esito.descrizioneEsito}`,
            null,
            {
              duration: 4000
            }
          )
        }
        this.chiudiDialog()
      })
      .catch(error => {
        this.snackBar.open(
          $localize `Non è stato possibile inviare la richiesta di sblocco al custode.
      ${error.toString()}`,
          null,
          {
            duration: 4000
          }
        )
      })
  }
}


