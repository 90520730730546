import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { NgForm, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs'
import { Router } from '@angular/router'
import {
  AngularEditorComponent,
  AngularEditorConfig,
} from '@kolkov/angular-editor'

import { Subscription, Observable } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ComponentCanDeactivate } from '../../../can-deactivate/can-deactivate.component'
import { ElencoAllegatiComponent } from '../../../elenco-allegati/elenco-allegati.component'
import { Azienda } from '../../../modello/azienda'
import { AzioniFollowUp } from '../../../modello/azioni-follow-up'
import { DirittiLavorazione } from '../../../modello/diritti-lavorazione'
import { DirittiSegnalazione } from '../../../modello/diritti-segnalazione'
import { Documento } from '../../../modello/documento'
import { Lavorazione } from '../../../modello/lavorazione'
import { LavorazioneMaster } from '../../../modello/lavorazione-master'
import { Odv } from '../../../modello/odv'
import { SorgenteSegnalazione } from '../../../modello/sorgente-segnalazione'
import { Tema } from '../../../modello/tema'
import { User } from '../../../modello/user'
import { ConfermaCreazioneAzioneFollowUpDialogComponent } from '../../../pannello-lavorazioni/dialogs/conferma-creazione-azione-follow-up.dialog.component'
import { ConfermaCreazioneSorgenteDialogComponent } from '../../../pannello-lavorazioni/dialogs/conferma-creazione-sorgente.dialog.component'
import { ConfermaCreazioneTemaDialogComponent } from '../../../pannello-lavorazioni/dialogs/conferma-creazione-tema.dialog.component'
import { ConfermaScondivisioneSegnalazioneDialogComponent } from '../../../pannello-lavorazioni/pannello-lavorazione-segnalazione/conferma-scondivisione-segnalazione.dialog.component'
import { DirittiInoltroDialogComponent } from '../../../pannello-lavorazioni/pannello-lavorazione-segnalazione/diritti-inoltro.dialog.component'
import { AuthService } from '../../../servizi/auth.service'
import { AziendeService } from '../../../servizi/aziende.service'
import { AzioniFollowUpService } from '../../../servizi/azioni-follow-up.service'
import { ChatService } from '../../../servizi/chat.service'
import { DocumentiService } from '../../../servizi/documenti.service'
import {
  LavorazioneService,
  NotificaModificaDirittoLavorazione,
} from '../../../servizi/lavorazione.service'
import { SegnalazioneService } from '../../../servizi/segnalazione.service'
import { SorgentiSegnalazioneService } from '../../../servizi/sorgenti-segnalazione.service'
import { TemiService } from '../../../servizi/temi.service'
import { ThemeService } from '../../../servizi/theme.service'
import { SessionData } from '../../../sessione/dati-sessione'
import { MessaggiStore } from '../../../stores/messaggi.store'
import { ESITO_OK, Esito, ESITO_IN_PROGRESS } from '../../../utility/esito'
import {
  gestoreLavorazioneAbilitato,
  isMembroDiGruppo,
  isUtenteMembrodiOdvSceltoDaSegnalante,
  editabile,
  destinatarioEditabile,
  corpoSegnalazioneEditabile,
  segnalante,
  isGestoreSegnalazione,
  puoModificareLavorazione,
  gestoreLavorazione,
  coGestoreLavorazione,
  isGestoreLavorazione,
  isGestoreOCogestoreLavorazione,
  isPresaInCarico,
  promuoviStatoLavorazione,
  prossimoStatoLavorazione,
  iconaTransizione,
  puoAprireLavorazioni,
  puoChattareConSegnalante,
  gruppoOdvCoinvolto,
  isOdvGestoreSegnalazione,
  isOdvSceltoDaSegnalanteByOdvName,
  puoConsultareLavorazione,
  gruppoGestoreLavorazioneUtente,
} from '../../../utility/helper-segnalazioni'
import {
  openDialogGenerica,
  CHIUSA_INDEX,
  INAMMISSIBILE_INDEX,
  NON_COMPETENTE_INDEX,
  ALCUNE_LAVORAZIONI_CHIUSE_INDEX,
  apriChatConSegnalanteOGestore,
  statoLavorazioneLeggibile,
} from '../../../utility/utility'

@Component({
  selector: 'app-dettaglio-lavorazione',
  templateUrl: './dettaglio-lavorazione.component.html',
  styleUrls: ['./dettaglio-lavorazione.component.css'],
})
export class DettaglioLavorazioneComponent
  extends ComponentCanDeactivate
  implements OnInit, OnDestroy {
  @Input()
  form: NgForm[]
  @ViewChildren('fgestore') ngFormGestore: QueryList<NgForm>
  @ViewChild('allegatiGestore', { static: false })
  elencoAllegatiGestore: ElencoAllegatiComponent

  @ViewChild('inputFollowUp', { static: false }) inputFollowUp: ElementRef
  @ViewChild('editorNoteFollowUp', { static: true })
  editorNoteFollowUp: AngularEditorComponent

  // @ViewChild('inputFollowUp', { static: false, read: MatAutocompleteTrigger }) autoTriggerFollowUp: MatAutocompleteTrigger

  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup

  @Input() readOnly = true

  saving: boolean

  dirittiSegnalazione: DirittiSegnalazione[]

  indiceLavorazioneSelezionata: number
  idLavorazioneSelezionata: number

  formLavorazione: UntypedFormGroup

  destinatariSegnalazione: Odv[]
  destinatariSegnalazioneFiltrati: Odv[]

  destinatariSegnalazioneAziende: Azienda[]

  sorgentiSegnalazione: SorgenteSegnalazione[]
  temiSegnalazione: Tema[]
  azioniFollowUp: AzioniFollowUp[]
  azioniFollowUpFiltrate: AzioniFollowUp[]
  azioneSelezionata = false
  sottoscrizioneNotifichePerNoteCondivise: any
  sottoscrizioneNotifichePerNoteScondivise: any

  sottoscrizioni: Subscription[] = []

  consultazioneSegnalazione = 1
  comunicazioneConSegnalante = 1
  consultazioneLavorazione = 1
  aperturaLavorazioni = 1

  loading = true

  nomeOdvOmissisInModifica: string

  editorConfigTb: AngularEditorConfig = {
    showToolbar: true,
    toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }

  editorConfigNoTb: AngularEditorConfig = {
    showToolbar: false,
    editable: false,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: $localize`Inserisci testo qui...`,
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  }
  dialogRefDirittiInoltro: any
  sottoscrizioneAggiuntaDocumento: any
  dialogRefConfermaVisibilita: any
  dialogRefConfermaCambioVisibilitaSegnalazione: any
  dialogRefConfermaScondivisioneSegnalazione: any
  idSegnalazione: any

  constructor(
    public dialog: MatDialog,
    public segnalazioniService: SegnalazioneService,
    private router: Router,
    private sorgenteSegnalazioneService: SorgentiSegnalazioneService,
    private fb: UntypedFormBuilder,
    private temiService: TemiService,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private azioniFollowUpService: AzioniFollowUpService,
    private chatService: ChatService,
    private lavorazioneService: LavorazioneService,
    private aziendeService: AziendeService,
    private documentiService: DocumentiService,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore
  ) {
    super()
    this.readOnly = this.router.getCurrentNavigation().extras.state?.readOnly
    this.idSegnalazione =
      this.router.getCurrentNavigation().extras.state?.idSegnalazione
  }

  ngOnInit() {
    if (!SessionData.lavorazioneSelezionata || !this.idSegnalazione) {
      this.router.navigate(['lavorazioni'])
      return
    }

    this.loading = true
    this.recuperaDirittiSegnalazione()
    this.recuperaTutteLeSorgentiSegnalazione()
    this.recuperaTuttiITemi()
    this.createForm()
    this.updateFormGestore(this.lavorazione)
  }

  get lavorazione() {
    return SessionData.lavorazioneSelezionata
  }

  getIdSegnalazione() {
    return this.idSegnalazione
  }

  async attivaSottoscrizionePerModificheLavorazioni() {

    this.sottoscrizioneAggiuntaDocumento =
      this.documentiService.aggiungiDocumento.subscribe((epdoc) => {
        if (epdoc.tipoEntitaPrincipale !== 'lavorazione') {
          return
        }

        //  Attenzione questa lavorazione non è locale ma byRef è legata all'array lavorazioni del componente!!!
        const lavorazione = epdoc.entitaPrincipale as Lavorazione

        if (lavorazione.id != null) {
          this.documentiService
            .collegaDocumentoALavorazione(epdoc.documento, lavorazione)
            .then((esito: Esito) => {
              if (esito.esito === ESITO_OK) {
                //  individua la lavorazione nell'array globale
                // const lavorazioneToBeUpdated = this.lavorazioni.find(lav => lav.id === lavorazione.id);
                if (lavorazione.documenti === undefined) {
                  lavorazione.documenti = []
                }
                lavorazione.documenti?.push(JSON.parse(esito.payload))
                this.elencoAllegatiGestore.documenti = lavorazione.documenti
                this.elencoAllegatiGestore.refresh()
              } else {
                this.snackBar.open(
                  $localize`Il salvataggio della nota è fallito! ` +
                    esito.descrizioneEsito,
                  null,
                  {
                    duration: 6000,
                  }
                )
              }
            })
            .catch((errore) => {
              this.snackBar.open(
                $localize`Il salvataggio della nota è fallito! ` + errore,
                null,
                {
                  duration: 6000,
                }
              )
            })
        } else {
          this.segnalazioniService.notificaPerDocumentoAggiunto.next({
            canale: 'documento-aggiunto-lavorazione-' + lavorazione.id,
            documento: epdoc.documento,
          })
        }
      })
  }
  async recuperaLavorazione(idLavorazione: number): Promise<Lavorazione> {
    const lavorazione = await this.lavorazioneService
      .recuperaLavorazioneById(idLavorazione)
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          return JSON.parse(esito.payload)
        }
        return undefined
      })
    return lavorazione
  }

  aggiornaNoteCondivise(): any {
    console.log('aggiornaNoteCondivise: NOT IMPLEMENTED')
  }

  public ngOnDestroy(): void {
    this.sottoscrizioni.forEach((sottoscrizione) =>
      sottoscrizione.unsubscribe()
    )

    if (this.sottoscrizioneNotifichePerNoteCondivise) {
      this.sottoscrizioneNotifichePerNoteCondivise.unsubscribe()
    }
    if (this.sottoscrizioneNotifichePerNoteScondivise) {
      this.sottoscrizioneNotifichePerNoteScondivise.unsubscribe()
    }
    this.sottoscrizioneAggiuntaDocumento?.unsubscribe()
  }

  recuperaTutteLeSorgentiSegnalazione(): any {

    this.sorgenteSegnalazioneService
      .recuperaSorgentiSegnalazione()
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.sorgentiSegnalazione = JSON.parse(esito.payload)
        } else {
          this.snackBar.open(
            $localize`Si è verificato un errore durante il recupero delle sorgenti segnalazione! `,
            null,
            {
              duration: 6000,
            }
          )
        }
      })

  }

  recuperaDirittiSegnalazione(): any {
    if (this.lavorazione && this.lavorazione.dirittiOdv[0]) {
      this.segnalazioniService
        .recuperaDirittiSegnalazioneOdv(
          +this.lavorazione.dirittiOdv[0].segnalazione?.id
        )
        .then((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            this.dirittiSegnalazione = JSON.parse(esito.payload)
            //  elimina i diritti sui quali non si ha visibilità solo se sono un utente membro di
            //  gruppi non coinvolti dal segnalante
            if (
              !isUtenteMembrodiOdvSceltoDaSegnalante(
                this.segnalazione,
                this.authService.getUser()
              )
            ) {
              this.dirittiSegnalazione = this.dirittiSegnalazione.filter(
                (diritto) =>
                  isMembroDiGruppo(
                    this.authService.getUser(),
                    diritto.odvCheHaInoltrato
                  ) ||
                  isMembroDiGruppo(
                    this.authService.getUser(),
                    diritto.odvDestinatario
                  ) ||
                  this.dirittoDelGruppoCheMiHaInoltratoLaSegnalazione(
                    diritto,
                    this.dirittiSegnalazione
                  )
              )
            }
          } else {
            this.snackBar.open(
              $localize`Recupero diritti segnalazione Fallito. Errore: ` +
                esito.descrizioneEsito,
              null,
              {
                duration: 4000,
              }
            )
          }
        })
        .catch((error) => {
          this.snackBar.open(
            $localize`Recupero diritti segnalazione Fallito. Errore: ` + error,
            null,
            {
              duration: 4000,
            }
          )
        })
    }
  }

  dirittoDelGruppoCheMiHaInoltratoLaSegnalazione(
    diritto: DirittiSegnalazione,
    dirittiSegnalazione: DirittiSegnalazione[]
  ) {
    const result = dirittiSegnalazione.find((dir) =>
      isMembroDiGruppo(this.authService.getUser(), dir.odvDestinatario)
    )
    return diritto.odvDestinatario?.id === result?.odvCheHaInoltrato?.id
  }

  recuperaTuttiITemi(): any {
    this.temiService.recuperaTemi().then((esito) => {
      if (esito.esito === ESITO_OK) {
        this.temiSegnalazione = JSON.parse(esito.payload)
      } else {
        this.snackBar.open(
          $localize`Si è verificato un errore durante il recupero dei temi! `,
          null,
          {
            duration: 6000,
          }
        )
      }
    })
  }

  recuperaTutteLeAzioniFollowUp(): any {
    // console.log('-6-');

    this.azioniFollowUpService.recuperaAzioniFollowUp().then((esito) => {
      if (esito.esito === ESITO_OK) {
        this.azioniFollowUp = JSON.parse(esito.payload)
        this.azioniFollowUpFiltrate = JSON.parse(esito.payload)

        //  Ora elimina quelle già selezionate
        this.azioniFollowUpFiltrate = this.azioniFollowUpFiltrate.filter(
          (azione) => {
            let res = true
            if (this.lavorazione.azioniFollowUp) {
              res =
                this.lavorazione.azioniFollowUp.find(
                  (azionetmp) => azionetmp.azione === azione.azione
                ) === undefined
            }
            return res
          }
        )
      } else if (esito.esito === ESITO_IN_PROGRESS) {
        console.log('Recupero azioni follow up in corso...')
      } else {
        this.snackBar.open(
          $localize`Si è verificato un errore durante il recupero delle azioni follow up! `,
          null,
          {
            duration: 6000,
          }
        )
      }
    })
  }

  recuperaTuttiIDestinatari(): any {
    this.destinatariSegnalazione = []

    this.segnalazioniService
      .recuperaGruppiGestori()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.destinatariSegnalazione = JSON.parse(esito.payload)
          this.destinatariSegnalazioneFiltrati = this.destinatariSegnalazione
          //  Ora elimina quelle già selezionate
          this.destinatariSegnalazioneFiltrati =
            this.destinatariSegnalazioneFiltrati.filter((destinatario) => {
              let res = true
              if (this.lavorazione.dirittiOdv) {
                res =
                  this.lavorazione.dirittiOdv.find(
                    (diritto) => diritto.odv.nomeOdv === destinatario.nomeOdv
                  ) === undefined
              }
              return res
            })
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari Fallito. Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
      })

    this.aziendeService
      .recuperaAziendeConOdv()
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          this.destinatariSegnalazioneAziende = JSON.parse(esito.payload)
          this.filtraDestinatari()
        } else {
          this.snackBar.open(
            $localize`Recupero elenco destinatari aziendali Fallito. Errore: ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Recupero elenco destinatari aziendali Fallito. Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  filtraDestinatari() {
    if (this.destinatariSegnalazioneAziende) {
      this.destinatariSegnalazioneAziende.forEach((azienda) => {
        azienda.odvAzienda.forEach((odvAzienda) => {
          odvAzienda['checked'] = false
          if (this.segnalazione && this.segnalazione.dirittiSegnalazioneOdv) {
            this.segnalazione.dirittiSegnalazioneOdv.forEach((diritto) => {
              if (diritto.odvDestinatario.nomeOdv === odvAzienda.odv.nomeOdv) {
                odvAzienda['checked'] = true
              }
            })
          }
        })
      })
    }
  }

  aggiungiDestinatario(idOdv: number) {
    this.destinatariSegnalazioneAziende.forEach((azienda) => {
      const gruppo = azienda.odvAzienda.find(
        (odvAzienda) => odvAzienda.odv.id === idOdv
      )
      if (gruppo) {
        gruppo['checked'] = true
      }
    })
  }

  rimuoviDestinatario(idOdv: number) {
    this.destinatariSegnalazioneAziende.forEach((azienda) => {
      const gruppo = azienda.odvAzienda.find(
        (odvAzienda) => odvAzienda.odv.id === idOdv
      )
      if (gruppo) {
        gruppo['checked'] = false
      }
    })
  }

  private createForm() {
    //  Form Note gestore

    this.formLavorazione = this.fb.group({
      stato: '',
      sorgente: this.fb.control(''),
      ammissibile: this.fb.control(''),
      visibileAlSegnalante: this.fb.control(''),
      motivoNonAmmissibilita: this.fb.control(''),
      tema: this.fb.control(''),
      azioniFollowUpForm: this.fb.control({ value: '', disabled: true }),
      noteFollowUp: this.fb.control(''),
      dirittiOdv: this.fb.control({ value: '', disabled: true }),
      eliminareSegnalazione: this.fb.control(''),
      dataDecisioneEliminazione: this.fb.control({ value: '', disabled: true }),
    })

    if (!this.readOnly) {
      this.formLavorazione.controls.azioniFollowUpForm.valueChanges
        .pipe(debounceTime(400))
        .subscribe((data) => {
          if (
            !this.azioneSelezionata &&
            this.formLavorazione.controls.azioniFollowUpForm.dirty &&
            data &&
            data.toString().length > 0
          ) {
            this.azioniFollowUpService
              .recuperaAzioniFollowUpByName(data)
              .then((esito) => {
                if (esito.esito === ESITO_OK) {
                  this.azioniFollowUpFiltrate = JSON.parse(esito.payload)


                  //  Ora elimina quelle già selezionate
                  this.azioniFollowUpFiltrate =
                    this.azioniFollowUpFiltrate.filter((azione) => {
                      const res =
                        this.lavorazione.azioniFollowUp.find(
                          (azionetmp) => azionetmp.azione === azione.azione
                        ) === undefined
                      return res
                    })
                  } else {
                    this.snackBar.open(
                      $localize`Recupero azioni follow up Fallito. Errore: ` +
                        esito.descrizioneEsito,
                      null,
                      {
                        duration: 4000,
                      }
                    )
                  }
              })
          } else {
            // console.log('-2-');
            // this.recuperaTutteLeAzioniFollowUp();
            this.azioneSelezionata = false
          }
        })
      this.formLavorazione.controls.sorgente.valueChanges
        .pipe(debounceTime(400))
        .subscribe((data) => {
          if (
            this.formLavorazione.controls.sorgente.dirty &&
            data &&
            data.toString().length > 0
          ) {
            this.sorgenteSegnalazioneService
              .recuperaSorgentiSegnalazioneByName(data)
              .then((esito) => {
                if (esito.esito === ESITO_OK) {
                  this.sorgentiSegnalazione = JSON.parse(esito.payload)
                } else {
                  this.snackBar.open(
                    $localize`Recupero sorgenti segnalazione Fallito. Errore: ` +
                      esito.descrizioneEsito,
                    null,
                    {
                      duration: 4000,
                    }
                  )
                }
              })
          } else {
            this.recuperaTutteLeSorgentiSegnalazione()
          }
        })

      this.formLavorazione.controls.tema.valueChanges
        .pipe(debounceTime(400))
        .subscribe((data) => {
          if (
            this.formLavorazione.controls.tema.dirty &&
            data &&
            data.toString().length > 0
          ) {
            this.temiService.recuperaTemiByName(data).then((esito) => {
              if (esito.esito === ESITO_OK) {
                this.temiSegnalazione = JSON.parse(esito.payload)
              } else {
                this.snackBar.open(
                  $localize`Recupero temi segnalazione Fallito. Errore: ` +
                  esito.descrizioneEsito,
                  null,
                  {
                    duration: 4000,
                  }
                )
              }
            })
          } else {
            this.recuperaTuttiITemi()
          }
        })
    }
  }

  updateFormGestore(lavorazione: Lavorazione) {
    // this.idLavorazioneSelezionata = lavorazione.id;

    this.formLavorazione.controls.stato.setValue(
      lavorazione ? lavorazione.stato : ''
    )

    const sorgente =
      lavorazione &&
      lavorazione.lavorazioneMaster &&
      lavorazione.lavorazioneMaster.sorgente
        ? lavorazione.lavorazioneMaster.sorgente.nome
        : ''
    this.formLavorazione.controls.sorgente.setValue(sorgente)

    this.formLavorazione.controls.ammissibile.setValue(
      lavorazione && lavorazione.ammissibile === false ? 'false' : 'true'
    )

    this.formLavorazione.controls.motivoNonAmmissibilita.setValue(
      lavorazione && lavorazione.motivoNonAmmissibilita
        ? lavorazione.motivoNonAmmissibilita
        : ''
    )

    this.formLavorazione.controls.visibileAlSegnalante.setValue(
      lavorazione && lavorazione.visibileAlSegnalante === false
        ? 'false'
        : 'true'
    )

    this.formLavorazione.controls.tema.setValue(
      lavorazione &&
        lavorazione.lavorazioneMaster &&
        lavorazione.lavorazioneMaster.tema
        ? lavorazione.lavorazioneMaster.tema.tema
        : ''
    )

    this.formLavorazione.controls.noteFollowUp.setValue(
      lavorazione ? lavorazione.noteFollowUp : ''
    )

    this.formLavorazione.controls.dirittiOdv.setValue(
      lavorazione ? lavorazione.dirittiOdv : ''
    )

    this.aggiornaStatoForm(lavorazione)
  }

  aggiornaStatoForm(lavorazione: Lavorazione) {
    console.log('lavorazione', lavorazione)
    console.log(
      'this.isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)',
      this.isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione)
    )
    console.log(
      'lavorazione.stato === CHIUSA_INDEX',
      +lavorazione.stato === CHIUSA_INDEX
    )
    console.log('this.readOnly', this.readOnly)
    if (
      !lavorazione ||
      !this.isUtenteCorrenteGestoreOCogestoreLavorazione(lavorazione) ||
      +lavorazione.stato === CHIUSA_INDEX ||
      this.readOnly
    ) {
      this.formLavorazione.disable()
    } else {
      this.formLavorazione.enable()
    }
  }

  get utente(): User {
    return this.authService.getUser()
  }

  get odv(): boolean {
    return this.authService.getUser()?.odv
  }

  get editabile(): boolean {
    return editabile(this.segnalazione)
  }

  get destinatarioEditabile(): boolean {
    return destinatarioEditabile(this.segnalazione, this.authService.getUser())
  }

  get corpoSegnalazioneEditabile(): boolean {
    return corpoSegnalazioneEditabile(this.segnalazione)
  }

  get segnalante(): boolean {
    // @ToDo da rivedere in fase di test
    return segnalante(this.segnalazione, this.authService.getUser())
  }

  gestibile(lavorazione): boolean {
    const gest =
      this.odv &&
      lavorazione.stato !== CHIUSA_INDEX.toString() &&
      lavorazione.stato !== INAMMISSIBILE_INDEX.toString() &&
      lavorazione.stato !== NON_COMPETENTE_INDEX.toString() &&
      lavorazione.dirittiOdv.find(
        (diritto) =>
          (diritto.gestore || diritto.coGestore) &&
          isMembroDiGruppo(this.authService.getUser(), diritto.odv)
      ) !== undefined
    return gest
  }

  gestore(): boolean {
    const gestoreSegna = isGestoreSegnalazione(
      this.segnalazione,
      this.authService.getUser()
    )
    return gestoreSegna
  }

  nomeGestoreLavorazione(
    dirittiSegnalazione: DirittiSegnalazione,
    lavorazione: Lavorazione
  ): string {
    // const dirittiLavorazione = this.dirittiLavorazioneDaDirittiSegnalazione(dirittiSegnalazione);
    const dirittiLavorazione = lavorazione.dirittiOdv.find(
      (diritto) => diritto.gestore
    )
    if (dirittiLavorazione) {
      if (dirittiLavorazione.gestore) {
        // return dirittiLavorazione.odv.nomeOdv;
        return 'Gestore'
      } else if (dirittiLavorazione.odvCheHaCondiviso) {
        return dirittiLavorazione.odvCheHaCondiviso.nomeOdv
      }
    } else {
      const gestore = gestoreLavorazioneAbilitato(lavorazione)

      if (gestore && +gestore.id === +dirittiSegnalazione.odvDestinatario.id) {
        return 'Gestore'
      }
      return null
    }
  }

  possoPrendereInCaricoLavorazione(lavorazione): boolean {
    return puoModificareLavorazione(lavorazione, this.authService.getUser())
  }

  isOdvGestoreLavorazione(lavorazione: Lavorazione, odv: Odv): boolean {
    const gestore = gestoreLavorazione(lavorazione)
    if (odv && gestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return +odv.id === +gestore.id
    }
    return false
  }

  isOdvGestoreOCoGestoreLavorazione(
    lavorazione: Lavorazione,
    odv: Odv
  ): boolean {
    const gestore = gestoreLavorazione(lavorazione)
    if (odv && gestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return +odv.id === +gestore.id
    }
    const dirittiLavCoGestore = coGestoreLavorazione(lavorazione)
    if (odv && dirittiLavCoGestore) {
      // return isOdvGestoreLavorazione(lavorazione, odv);
      return (
        dirittiLavCoGestore.find((dlav) => +dlav.odv.id === +odv.id) !==
        undefined
      )
    }
    return false
  }

  isUtenteCorrenteGestoreLavorazione(lavorazione: Lavorazione): boolean {
    // const gestore = gestoreLavorazioneAbilitato(lavorazione);
    if (!lavorazione) {
      return false
    }
    const result = isGestoreLavorazione(
      lavorazione,
      this.authService.getUser()
    )
    return result
  }

  isUtenteCorrenteGestoreOCogestoreLavorazione(
    lavorazione: Lavorazione
  ): boolean {
    // const gestore = gestoreLavorazioneAbilitato(lavorazione);
    if (!lavorazione) {
      return false
    }
    const result = isGestoreOCogestoreLavorazione(
      lavorazione,
      this.authService.getUser()
    )
    return result
  }

  possoDialogareConSegnalante(): boolean {
    return (
      this.dirittiSegnalazione?.find(
        (diritto) =>
          diritto.comunicazioneConSegnalante &&
          isMembroDiGruppo(this.authService.getUser(), diritto.odvDestinatario)
      ) !== undefined
    )
  }

  possoInoltrareLavorazione(lavorazione) {
    return this.isUtenteCorrenteGestoreLavorazione(lavorazione)
  }

  hoInoltratoSegnalazione(dirittiSegnalazione) {
    if (
      dirittiSegnalazione.odvCheHaInoltrato &&
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiSegnalazione.odvCheHaInoltrato
      )
    ) {
      return true
    }
    return false
  }

  odvSceltoDaSegnalante(dirittiSegnalazione: DirittiSegnalazione) {
    return dirittiSegnalazione.sceltoDaSegnalante
  }

  sonoGestoreSceltoDaSegnalante() {
    const result =
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (diritti) =>
          diritti.sceltoDaSegnalante &&
          isMembroDiGruppo(this.authService.getUser(), diritti.odvDestinatario)
      ) !== undefined
    return result
  }

  gestoreDestinatarioSegnalante(dirittiSegnalazione: DirittiSegnalazione) {
    return (
      dirittiSegnalazione.odvDestinatario.id ===
      this.segnalazione.segnalante.utente.id
    )
  }

  lavorazioneChiusa(lavorazione: Lavorazione) {
    return +lavorazione.stato === +CHIUSA_INDEX
  }

  hoInoltratoIo(dirittiSegnalazione: DirittiSegnalazione): boolean {
    const result =
      dirittiSegnalazione.odvCheHaInoltrato !== null &&
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiSegnalazione.odvCheHaInoltrato
      )
    return result
  }

  presaInCarico(): boolean {
    return isPresaInCarico(this.segnalazione, this.authService.getUser())
  }

  salvaLavorazioneSegnalazione(lavorazione: Lavorazione) {
    if (!this.saving) {
      this.saving = true
      if (this.segnalazione.id == null || this.segnalazione.id.length === 0) {
        this.segnalazione.stato = ALCUNE_LAVORAZIONI_CHIUSE_INDEX.toString()
      }
      if (this.odv) {
        if (!lavorazione.lavorazioneMaster) {
          lavorazione.lavorazioneMaster = new LavorazioneMaster()
        }

        lavorazione.lavorazioneMaster.segnalazione = lavorazione.segnalazione

        //  metti a posto la sorgente
        if (this.sorgentiSegnalazione) {
          if (this.formLavorazione.controls.sorgente.value) {
            const tmpsorgente = this.sorgentiSegnalazione.find(
              (sorgente) =>
                sorgente.nome === this.formLavorazione.controls.sorgente.value
            )
            if (!tmpsorgente) {
              // console.log('sorgente non trovata, chiedi se deve essere creata!');
              this.openDialogCreazioneSorgente(
                this.formLavorazione.controls.sorgente.value
              )
              this.saving = false
              return
            }
            lavorazione.lavorazioneMaster.sorgente = tmpsorgente
          } else {
            lavorazione.lavorazioneMaster.sorgente = null
          }
        } else if (this.formLavorazione.controls.sorgente.value) {
          this.openDialogCreazioneSorgente(
            this.formLavorazione.controls.sorgente.value
          )
          this.saving = false
          return
        }

        //  metti a posto il tema
        if (this.temiSegnalazione) {
          if (this.formLavorazione.controls.tema.value) {
            const tmptema = this.temiSegnalazione.find(
              (tema) => tema.tema === this.formLavorazione.controls.tema.value
            )
            if (!tmptema) {
              // console.log('tema non trovato, chiedi se deve essere creata!');
              this.openDialogCreazioneTema(
                this.formLavorazione.controls.tema.value
              )
              this.saving = false
              return
            }
            lavorazione.lavorazioneMaster.tema = tmptema
          } else {
            lavorazione.lavorazioneMaster.tema = null
          }
        } else if (this.formLavorazione.controls.tema.value) {
          this.openDialogCreazioneTema(
            this.formLavorazione.controls.tema.value
          )
          this.saving = false
          return
        }

        //  Ammissibilità
        if (
          this.formLavorazione.controls.ammissibile.value ||
          this.formLavorazione.controls.ammissibile.value === undefined
        ) {
          lavorazione.ammissibile =
            this.formLavorazione.controls.ammissibile.value === 'true'
          lavorazione.motivoNonAmmissibilita =
            this.formLavorazione.controls.motivoNonAmmissibilita.value
        }

        if (
          this.odv &&
          (!this.formLavorazione.controls.motivoNonAmmissibilita ||
            !this.formLavorazione.controls.motivoNonAmmissibilita.value) &&
          this.formLavorazione.controls.ammissibile.value === 'false'
        ) {
          this.openDialogNoteAmmissibilitaObbligatorie()
          this.saving = false
          return
        }

        //  Visibilità
        if (
          this.formLavorazione.controls.visibileAlSegnalante.value ||
          this.formLavorazione.controls.visibileAlSegnalante.value === undefined
        ) {
          lavorazione.visibileAlSegnalante =
            this.formLavorazione.controls.visibileAlSegnalante.value === 'true'
        }

        //  metti a posto le azioni di follow up
        if (lavorazione.azioniFollowUp) {
          for (
            let index = 0;
            index < lavorazione.azioniFollowUp.length;
            index++
          ) {
            const azione = lavorazione.azioniFollowUp[index]

            const tmpAzione = this.azioniFollowUp.find(
              (innerAzione) => innerAzione.azione === azione.azione
            )
            if (!tmpAzione) {
              // console.log('azione non trovato, chiedi se deve essere creata!');
              this.openDialogCreazioneAzioneFollowUp(azione)
              this.saving = false
              return
            } else if (!azione.id) {
              azione.id = tmpAzione.id
            }
          }
        }
        //  e le note!!!
        lavorazione.noteFollowUp =
          this.formLavorazione.controls.noteFollowUp.value

      }

      this.lavorazioneService
        .salvaLavorazione(lavorazione, +this.segnalazione.id)
        .then((esito: Esito) => {
          // console.log(result);
          if (esito.esito === ESITO_OK) {
            const lavorazioneTmp = JSON.parse(esito.payload)
            SessionData.lavorazioneSelezionata = lavorazioneTmp

            this.filtraDestinatari()

            if (lavorazioneTmp) {
              this.formLavorazione.markAsPristine()
              this.formLavorazione.markAsUntouched()
              this.saving = false
              // this.createForm();
              this.snackBar.open($localize`Lavorazione salvata`, null, {
                duration: 2000,
              })
            } else {
              this.saving = false
              this.snackBar.open(
                $localize`Salvataggio Fallito. Codice Errore: `,
                null,
                {
                  duration: 4000,
                }
              )
            }
          } else {
            this.snackBar.open(
              $localize`Salvataggio Fallito. `,
              esito.descrizioneEsito,
              {
                duration: 4000,
              }
            )
          }
        })
        .catch((err) => {
          this.saving = false
          // console.log(err);
          this.snackBar.open(
            $localize`Salvataggio Fallito. Codice Errore: `,
            err,
            {
              duration: 4000,
            }
          )
        })
      // console.log('-3-');
      this.recuperaTutteLeAzioniFollowUp()
    } else {
      this.snackBar.open($localize`Salvataggio in corso!`, null, {
        duration: 2000,
      })
    }
  }

  openDialogNoteAmmissibilitaObbligatorie(): void {}

  openDialogCreazioneSorgente(sorgente): void {
    const dialogRef = this.dialog.open(
      ConfermaCreazioneSorgenteDialogComponent,
      {
        width: '500px',
        data: { nomeSorgente: sorgente },
        disableClose: true,
        hasBackdrop: true,
      }
    )

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.creaSorgente(sorgente).then(() => {
          this.salvaLavorazioneSegnalazione(this.lavorazione)
        })
      }
    })
  }

  async creaSorgente(sorgente: string): Promise<SorgenteSegnalazione> {
    const sorgenteSegnalazione: SorgenteSegnalazione =
      new SorgenteSegnalazione()
    sorgenteSegnalazione.nome = sorgente
    return this.sorgenteSegnalazioneService
      .creaSorgenteSegnalazione(sorgenteSegnalazione)
      .then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore creazione sorgente segnalazione`,
            esito.descrizioneEsito,
            {
              duration: 4000,
            }
          )
          return
        }
        const res = JSON.parse(esito.payload)
        this.sorgentiSegnalazione.push(res)
        this.snackBar.open($localize`Sorgente Segnalazione salvata`, null, {
          duration: 2000,
        })
      })
      .catch((err) => err)
  }

  openDialogCreazioneTema(tema): void {
    const dialogRef = this.dialog.open(ConfermaCreazioneTemaDialogComponent, {
      width: '500px',
      data: { nomeTema: tema },
      disableClose: true,
      hasBackdrop: true,
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.creaTema(tema).then(() => {
          this.salvaLavorazioneSegnalazione(this.lavorazione)
        })
      }
    })
  }

  async creaTema(tema: string): Promise<Tema> {
    const temaSegnalazione: Tema = new Tema()
    temaSegnalazione.tema = tema
    return this.temiService
      .creaTema(temaSegnalazione)
      .then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore creazione tema segnalazione`,
            esito.descrizioneEsito,
            {
              duration: 4000,
            }
          )
          return
        }
        const res = JSON.parse(esito.payload)
        this.temiSegnalazione.push(res)
        this.snackBar.open($localize`Tema Segnalazione salvato`, null, {
          duration: 2000,
        })
      })
      .catch((err) => err)
  }

  openDialogCreazioneAzioneFollowUp(azione): void {
    const dialogRef = this.dialog.open(
      ConfermaCreazioneAzioneFollowUpDialogComponent,
      {
        width: '500px',
        data: { nomeAzione: azione.azione },
        disableClose: true,
        hasBackdrop: true,
      }
    )
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.creaAzione(azione, this.lavorazione).then(() => {
          this.salvaLavorazioneSegnalazione(this.lavorazione)
        })
      } else {
        this.remove(azione)
      }
    })
  }

  async creaAzione(
    azioneFollowUp: AzioniFollowUp,
    lavorazione: Lavorazione
  ): Promise<AzioniFollowUp> {
    return await this.azioniFollowUpService
      .creaAzioniFollowUp(azioneFollowUp)
      .then((esito) => {
        if (esito.esito !== ESITO_OK) {
          this.snackBar.open(
            $localize`Errore creazione azione follow up`,
            esito.descrizioneEsito,
            {
              duration: 4000,
            }
          )
          return
        }
        const res = JSON.parse(esito.payload)
        this.azioniFollowUp.push(res)
        const tmpAzione = lavorazione.azioniFollowUp.find(
          (azione) => azione.azione === res.azione
        )
        tmpAzione.id = res.id

        this.snackBar.open($localize`Nuova azione di follow up salvata`, null, {
          duration: 2000,
        })
      })
      .catch((err) => err)
  }

  get competenza() {
    return this.segnalazione.stato !== NON_COMPETENTE_INDEX.toString()
  }

  nonDiCompetenza(lavorazione: Lavorazione) {
    lavorazione.stato = NON_COMPETENTE_INDEX.toString()
    this.lavorazioneService
      .setStatoLavorazione(lavorazione, this.segnalazione)
      .then(() => {
        this.snackBar.open(
          $localize`Cambio stato eseguito con successo`,
          null,
          {
            duration: 4000,
          }
        )

        this.segnalazione.presaInCarico = false
        this.segnalazione.gestore = null
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Cambio stato Fallito. Codice Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile promuovere lo stato della lavorazione ' +
            lavorazione.id +
            ' a non di competenza'
        )
      })
  }

  promuoviStato(lavorazione: Lavorazione) {
    const oldStato = lavorazione.stato
    promuoviStatoLavorazione(
      lavorazione,
      this.segnalazione,
      this.lavorazioneService
    )
      .then(() => {
        this.aggiornaStatoForm(this.lavorazione)
        this.snackBar.open($localize`Cambio stato eseguito`, null, {
          duration: 2000,
        })
      })
      .catch((error) => {
        lavorazione.stato = oldStato
        this.snackBar.open(
          $localize`Cambio stato Fallito. Codice Errore: ` + error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile promuovere lo stato della segnalazione ' +
            this.segnalazione.id +
            ' come letta da odv'
        )
      })
  }

  prossimoStato(lavorazione) {
    return prossimoStatoLavorazione(lavorazione)
  }

  iconaTransizione(lavorazione) {
    return iconaTransizione(lavorazione)
  }

  openChat(lavorazione: Lavorazione) {
    apriChatConSegnalanteOGestore(
      lavorazione,
      this.chatService,
      this.authService,
      this.router,
      this.snackBar,
      this.sessionData
    )
  }

  aggiornaDirittiLavorazione(
    dirittiLavorazione: DirittiLavorazione,
    modificaLavorazione: boolean,
    lavorazione: Lavorazione
  ) {
    this.lavorazioneService
      .aggiornaDirittiLavorazione(
        dirittiLavorazione ? dirittiLavorazione.id : -1,
        modificaLavorazione ? 1 : 0,
        lavorazione.id
      )
      .then((res) => {
        if (res.esito === ESITO_OK) {
          const dirittilavoraz = JSON.parse(res.payload) as DirittiLavorazione
          dirittilavoraz.lavorazione = undefined
          //  individua lavorazione da aggiornare
          const lavo = this.lavorazione.dirittiOdv.some(
            (diritto) => +diritto.id === +dirittilavoraz.id
          )
          if (lavo) {
            const indice = this.lavorazione.dirittiOdv.findIndex(
              (diritto) => +diritto.id === +dirittilavoraz.id
            )
            if (indice !== -1) {
              this.lavorazione.dirittiOdv.splice(indice, 1, dirittilavoraz)
            }
            this.snackBar.open(
              $localize`Diritto Lavorazione Aggiornato`,
              null,
              {
                duration: 2000,
              }
            )
          } else {
            this.snackBar.open(
              $localize`Diritto Lavorazione Non presente in locale`,
              null,
              {
                duration: 2000,
              }
            )
          }
        } else {
          this.snackBar.open(
            $localize`Salvataggio Diritto Lavorazione fallito. Errore: ` +
              JSON.stringify(res.payload),
            null,
            {
              duration: 4000,
            }
          )
        }
      })
      .catch((error) => {
        this.snackBar.open(
          $localize`Salvataggio Diritto Lavorazione fallito. Codice Errore: ` +
            error,
          null,
          {
            duration: 4000,
          }
        )
        console.error(
          'Non è stato possibile salvare il Diritto Lavorazione ' +
            dirittiLavorazione.id
        )
      })
  }

  puoRimuovereCondivisione(destinatario: DirittiLavorazione) {
    if (
      destinatario &&
      destinatario.odvCheHaCondiviso &&
      isMembroDiGruppo(
        this.authService.getUser(),
        destinatario.odvCheHaCondiviso
      )
    ) {
      return true
    }
    return false
  }

  puoModificareDirittiLavorazione(lavorazione: Lavorazione) {
    const result = lavorazione.dirittiOdv.some(
      (diritto) =>
        (diritto.odvCheHaCondiviso &&
          isMembroDiGruppo(
            this.authService.getUser(),
            diritto.odvCheHaCondiviso
          )) ||
        isMembroDiGruppo(this.authService.getUser(), diritto.odv)
    )
    return result
  }

  puoAprireLavorazioni() {
    const result = puoAprireLavorazioni(
      this.segnalazione,
      this.authService.getUser()
    )
    return result
  }

  possoGestireAutorizzazioneModificaLavorazione(
    dirittiLavorazione: DirittiLavorazione
  ) {
    const result =
      isMembroDiGruppo(
        this.authService.getUser(),
        dirittiLavorazione.odvCheHaCondiviso
      ) &&
      this.sessionData.configurazione.modificabilitaLavorazioniDaParteDiTerzi
    return result
  }

  possoGestireCondivisioneLavorazione(
    lavorazione: Lavorazione,
    dirittiLavorazione: DirittiLavorazione
  ) {
    const result =
      this.miaLavorazione(lavorazione) && !this.sonoIo(dirittiLavorazione.odv)
    return result
  }

  possoGestireAutorizzazioneComunicazioneConSegnalante(
    dirittiSegnalazione: DirittiSegnalazione
  ) {
    const result =
      (this.hoInoltratoSegnalazione(dirittiSegnalazione) ||
        this.sonoGestoreSceltoDaSegnalante()) &&
      !dirittiSegnalazione.sceltoDaSegnalante &&
      puoChattareConSegnalante(this.authService.getUser(), this.segnalazione)
    return result
  }

  sonoAbilitatoAllaGestioneIdentitaSegnalante() {
    return (
      this.dirittiSegnalazione.find(
        (dir) =>
          isMembroDiGruppo(this.authService.getUser(), dir.odvDestinatario) &&
          dir.puoVedereSegnalante
      ) !== undefined
    )
  }

  disinoltraLavorazione(destinatario: DirittiLavorazione) {
    // console.log('all\'inizio del disinoltro il numero di lavorazioni nell\'array è', this.lavorazioni?.length);

    let indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
      (diritto) => diritto.id === destinatario.id
    )
    if (indice === -1) {
      indice = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
        (diritto) => diritto.id === destinatario.id
      )
    }

    if (indice === -1) {
      this.snackBar.open($localize`Destinatario non presente.`, null, {
        duration: 4000,
      })
    } else {
    }
  }

  add(event: MatChipInputEvent, lavorazione: Lavorazione): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value

    // Add our action
    if ((value || '').trim()) {
      const tmpAzione = new AzioniFollowUp()
      tmpAzione.azione = value.trim()
      this.addAzione(tmpAzione, lavorazione)
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
    }
  }

  remove(azione: AzioniFollowUp, lavorazione?: Lavorazione): void {
    const index = lavorazione.azioniFollowUp.indexOf(azione)

    if (index >= 0) {
      lavorazione.azioniFollowUp.splice(index, 1)
      this.azioniFollowUpFiltrate.push(azione)
      this.formLavorazione.controls.azioniFollowUpForm.markAsDirty()
    }
  }

  selected(azione: AzioniFollowUp, lavorazione: Lavorazione) {
    this.azioneSelezionata = true
    // console.log('azione selezionata ' + azione.azione);
    this.addAzione(azione, lavorazione)
    // if (this.inputFollowUp) { this.inputFollowUp.nativeElement.value = ''; }
    this.formLavorazione.controls.azioniFollowUpForm.reset()
    this.formLavorazione.controls.azioniFollowUpForm.updateValueAndValidity()
    // console.log('-4-');
    this.recuperaTutteLeAzioniFollowUp()
  }

  selectedDestinatario(destinatario: Odv) {
    if (
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.find(
        (tmpdestinatario) =>
          tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
      )
    ) {
      // console.log('destinatario già incluso');
    } else {
      if (!this.segnalazione.dirittiSegnalazioneOdv) {
        this.segnalazione.dirittiSegnalazioneOdv =
          new Array<DirittiSegnalazione>()
      }
      const dirittiSegnalazione = new DirittiSegnalazione()
      dirittiSegnalazione.aperturaLavorazioni = false
      dirittiSegnalazione.comunicazioneConSegnalante = false
      dirittiSegnalazione.consultazioneSegnalazione = true
      dirittiSegnalazione.odvCheHaInoltrato = gruppoOdvCoinvolto(
        this.segnalazione,
        this.authService.getUser()
      )
      dirittiSegnalazione.odvDestinatario = destinatario
      dirittiSegnalazione.sceltoDaSegnalante = false
      this.segnalazione.dirittiSegnalazioneOdv.push(dirittiSegnalazione)
      // this.segnalazioneForm.get('destinatari').setValue(this.segnalazione.dirittiSegnalazioneOdv);
    }
    this.recuperaTuttiIDestinatari()
    // this.inputDestinatari.nativeElement.blur();
  }

  removeDestinatario(destinatario: Odv): void {
    const index = this.segnalazione.dirittiSegnalazioneOdv.findIndex(
      (tmpdestinatario) =>
        tmpdestinatario.odvDestinatario.nomeOdv === destinatario.nomeOdv
    )
    if (index >= 0) {
      this.segnalazione.dirittiSegnalazioneOdv.splice(index, 1)
    }
  }

  addDestinatario(event: MatChipInputEvent): void {
    const input: MatChipInput = event.chipInput
    const value = input.inputElement.value

    if (this.destinatariSegnalazioneFiltrati.length === 1) {
      this.selectedDestinatario(this.destinatariSegnalazioneFiltrati.pop())
    }

    // Reset the input value
    if (input) {
      input.inputElement.value = ''
      this.recuperaTuttiIDestinatari()
    }
  }

  addAzione(tmpAzione, lavorazione: Lavorazione) {
    let duplicato
    if (lavorazione.azioniFollowUp) {
      duplicato = lavorazione.azioniFollowUp.find(
        (azione) => azione.azione === tmpAzione.azione
      )
    } else {
      const tmp = new Array<AzioniFollowUp>()
      lavorazione.azioniFollowUp = tmp
    }

    if (!duplicato) {
      lavorazione.azioniFollowUp.push(tmpAzione)
    } else {
      // console.log('azione già presente!!!!!');
    }
    this.formLavorazione.controls.azioniFollowUpForm.reset()
    this.formLavorazione.controls.azioniFollowUpForm.updateValueAndValidity()
    // console.log('-4-');
    this.recuperaTutteLeAzioniFollowUp()
  }

  aggiornaDocumentoLavorazione(lavorazione: Lavorazione) {
    // console.log('debbo aggiornare il documento: ' + documento.id);
  }

  aggiungiDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    // console.log('debbo aggiungere il documento: ' + documento.id);
    if (!lavorazione.documenti) {
      lavorazione.documenti = []
    }

    if (lavorazione.id != null) {
      this.documentiService
        .collegaDocumentoALavorazione(documento, lavorazione)
        .then((esito: Esito) => {
          if (esito.esito === ESITO_OK) {
            if (lavorazione.documenti === undefined) {
              lavorazione.documenti = []
            }
            lavorazione.documenti?.push(JSON.parse(esito.payload))
            this.elencoAllegatiGestore.documenti = lavorazione.documenti
            this.elencoAllegatiGestore.refresh()
          } else {
            this.snackBar.open(
              $localize`Il salvataggio della nota è fallito! ` +
                esito.descrizioneEsito,
              null,
              {
                duration: 6000,
              }
            )
          }
        })
        .catch((errore) => {
          this.snackBar.open(
            $localize`Il salvataggio della nota è fallito! ` + errore,
            null,
            {
              duration: 6000,
            }
          )
        })
    } else {
      this.segnalazioniService.notificaPerDocumentoAggiunto.next({
        canale: 'documento-aggiunto-lavorazione-' + lavorazione.id,
        documento: documento,
      })
    }
  }

  collegaDocumentoALavorazione(documento: Documento, lavorazione: Lavorazione) {
    this.documentiService
      .collegaDocumentoALavorazione(documento, lavorazione)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          if (this.elencoAllegatiGestore.documenti === undefined) {
            console.warn(
              'this.elencoAllegatiGestore.documenti è undefined e non doveva esserlo, collegaDocumentoALavorazione(documento,lavorazione)',
              documento.id,
              lavorazione.id
            )
          } else {
            this.elencoAllegatiGestore.documenti.push(
              JSON.parse(esito.payload)
            )
            this.elencoAllegatiGestore.refresh()
          }
        } else {
          this.snackBar.open(
            $localize`Il salvataggio del documento è fallito! ` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch(() => {
        // console.log(errore);
        this.snackBar.open(
          $localize`Il salvataggio del documento è fallito!`,
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  toggleLavorazioneModificabile(
    event,
    dirittoLavorazione: DirittiLavorazione,
    lavorazione: Lavorazione
  ) {
    // evento.stopPropagation();
    const destinatario = dirittoLavorazione.odv
    const diritto = lavorazione.dirittiOdv.find(
      (dirittoTmp) => +dirittoTmp.odv.id === +destinatario.id
    )
    // if (isMembroDiGruppo(this.authService.getUser(), diritto.odvCheHaCondiviso)) {
    // if (!this.statoLavorazioneModificabile(dirittoSegnalazione)) {
    //   this.condividiLavorazione(event, dirittoSegnalazione);
    // }
    this.aggiornaDirittiLavorazione(
      diritto,
      !this.statoLavorazioneModificabile(dirittoLavorazione, lavorazione),
      lavorazione
    )
    // } else {
    //  this.snackBar.open($localize `Utente non abilitato alla modifica delle autorizzazioni!', null, {
    //    duration: 6000,
    //  });
    // }

    console.log('toggleComunicazioneConSegnalante!!!')
  }

  scondividiLavorazione(destinatario, lavorazione: Lavorazione) {
    this.lavorazioneService
      .scondividiLavorazione(
        lavorazione.id,
        destinatario.odv.id,
        +this.sessionData.segnalazioneSelezionata.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          if (lavorazione.dirittiOdv) {
            lavorazione.dirittiOdv = lavorazione.dirittiOdv.filter(
              (tempDest) => tempDest.odv.id !== destinatario.odv.id
            )
          }
        } else {
          this.snackBar.open(
            $localize`Salvataggio stato condivisione fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Salvataggio stato condivisione fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
    // evento.stopPropagation();
  }

  condividiLavorazione(destinatario: Odv, lavorazione: Lavorazione) {
    this.lavorazioneService
      .condividiLavorazione(
        lavorazione.id,
        gruppoOdvCoinvolto(this.segnalazione, this.authService.getUser()).id,
        destinatario.id,
        +this.sessionData.segnalazioneSelezionata.id
      )
      .then((esito) => {
        if (esito.esito === ESITO_OK) {
          if (!lavorazione.dirittiOdv) {
            lavorazione.dirittiOdv = []
          }
          const dirittiLavoraz = JSON.parse(
            esito.payload
          ) as DirittiLavorazione
          dirittiLavoraz.lavorazione = undefined
          //  si accerta di non duplicare il diritto
          const dirIndex = lavorazione.dirittiOdv.findIndex(
            (diritto) => diritto.id === dirittiLavoraz.id
          )
          if (dirIndex !== -1) {
            lavorazione.dirittiOdv.splice(dirIndex, 1, dirittiLavoraz)
          } else {
            lavorazione.dirittiOdv.push(dirittiLavoraz)
          }

          // evento.target.classList.add('condivisione-attiva');
          // evento.stopPropagation();
        } else {
          this.snackBar.open(
            $localize`Salvataggio stato condivisione fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        this.snackBar.open(
          $localize`Salvataggio stato condivisione fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
  }
  statoCondivisione(destinatario: Odv, lavorazione: Lavorazione) {
    const stato =
      lavorazione &&
      lavorazione.dirittiOdv &&
      lavorazione.dirittiOdv.find(
        (tmpOdv) => +tmpOdv.odv.id === +destinatario.id
      ) !== undefined
    return stato
  }

  statoCondivisioneSospesa(destinatario: DirittiSegnalazione) {
    const stato = destinatario.consultazioneSegnalazioneSospesa
    return stato
  }

  statoLavorazioneModificabile(
    destinatario: DirittiLavorazione,
    lavorazione: Lavorazione
  ) {
    const stato =
      lavorazione &&
      lavorazione.dirittiOdv &&
      lavorazione.dirittiOdv.find(
        (tmpOdv) => +tmpOdv.odv.id === +destinatario.odv.id && tmpOdv.coGestore
      ) !== undefined
    return stato
  }

  nessunaLavorazioneGestita() {
    const result =
      !this.lavorazione.dirittiOdv.find(
        (diritto) =>
          diritto.gestore &&
          isMembroDiGruppo(this.authService.getUser(), diritto.odv)
      ) !== undefined
    return result
  }

  dirittiSegnalazioniGestibili(): DirittiSegnalazione[] {
    return (
      this.segnalazione &&
      this.segnalazione.dirittiSegnalazioneOdv &&
      this.segnalazione.dirittiSegnalazioneOdv.filter(
        (diritto) =>
          isMembroDiGruppo(
            this.authService.getUser(),
            diritto.odvDestinatario
          ) &&
          !isOdvGestoreSegnalazione(
            this.segnalazione,
            diritto.odvDestinatario
          ) &&
          diritto.aperturaLavorazioni
      )
    )
  }

  aggiornaLavorazione(lavorazione: Lavorazione) {
    if (this.segnalazione) {
      const indice = this.segnalazione.lavorazioni.findIndex(
        (lavoraz) => +lavoraz.id === +lavorazione.id
      )
      if (indice !== -1) {
        this.segnalazione.lavorazioni.splice(indice, 1, lavorazione)
      }
    }
  }

  sonoIo(destinatario: Odv) {
    if (
      destinatario &&
      isMembroDiGruppo(this.authService.getUser(), destinatario)
    ) {
      return true
    }
    return false
  }

  esecutoreInoltro(destinatario: DirittiSegnalazione) {
    if (
      destinatario.odvCheHaInoltrato &&
      isMembroDiGruppo(
        this.authService.getUser(),
        destinatario.odvCheHaInoltrato
      )
    ) {
      return true
    }
    return false
  }

  miaLavorazione(lavorazione: Lavorazione) {
    const gestore = gestoreLavorazione(lavorazione)
    if (!gestore) {
      return false
    }
    return isMembroDiGruppo(this.authService.getUser(), gestore)

    // return gestore.nomeOdv === nomeOdvCondivisione;
  }

  destinatarioSceltoDaSegnalante(nomeOdvCondivisione: string) {
    const result = isOdvSceltoDaSegnalanteByOdvName(
      this.segnalazione,
      nomeOdvCondivisione
    )
    return result
  }

  convertiStatoLavorazione(indice): string {
    return indice ? statoLavorazioneLeggibile(indice) : 'Indefinito'
  }

  openPanelFollowUp(element): void {
    console.log(element)
    setTimeout( () => {
      // self.autoTriggerFollowUp.openPanel()
    }, 1)
  }

  prendiInCarico(odv?: Odv) {
    let lavorazione = new Lavorazione()

    const gruppoChePrendeInCarico = odv
      ? odv
      : gruppoOdvCoinvolto(this.segnalazione, this.authService.getUser())
    this.lavorazioneService
      .prendiInCaricoLavorazione(
        +this.segnalazione.id,
        gruppoChePrendeInCarico.id
      )
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.snackBar.open(
            $localize`La segnalazione è stata presa in carico!`,
            null,
            {
              duration: 6000,
            }
          )
          lavorazione = JSON.parse(esito.payload)

          if (lavorazione.chatConSegnalante) {
            this.messaggiStore.sottoscriviPerNuoviMessaggiChat(
              +lavorazione.chatConSegnalante.id
            )
          }

          const tmpIndex = this.segnalazione.lavorazioni.findIndex(
            (lavor) => +lavor.id === +lavorazione.id
          )
          if (tmpIndex !== -1) {
            this.segnalazione.lavorazioni.splice(tmpIndex, 1, lavorazione)
          } else {
            this.segnalazione.lavorazioni.push(lavorazione)
          }

          this.updateFormGestore(lavorazione)
        } else {
          this.snackBar.open(
            $localize`Presa in carico Fallita! ` + esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
  }

  salvaDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    const nuovoAllegato = +documento.id < 1
    this.lavorazioneService
      .collegaDocumentoALavorazione(documento, lavorazione)
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          const tmpDoc = JSON.parse(esito.payload)
          if (nuovoAllegato) {
            //  @ToDo non push per evitare di duplicare ma replace
            if (this.elencoAllegatiGestore.documenti === undefined) {
              console.warn(
                'this.elencoAllegatiGestore.documenti è undefined e non doveva esserlo, salvaDocumentoLavorazione(documento,lavorazione)',
                documento.id,
                lavorazione.id
              )
            } else {
              this.elencoAllegatiGestore.documenti.push(tmpDoc)
              this.elencoAllegatiGestore.refresh()
            }
          }
          this.snackBar.open($localize`Documento aggiornato`, null, {
            duration: 4000,
          })
        } else {
          this.snackBar.open(
            $localize`Il salvataggio dell\'allegato è fallito!` +
              esito.descrizioneEsito,
            null,
            {
              duration: 6000,
            }
          )
        }
      })
      .catch((errore) => {
        console.log(errore)
        this.snackBar.open(
          $localize`Il salvataggio dell\'allegato è fallito!` + errore,
          null,
          {
            duration: 6000,
          }
        )
      })
  }

  scollegaDocumentoLavorazione(documento: Documento, lavorazione: Lavorazione) {
    lavorazione.documenti = lavorazione.documenti.filter(
      (documentotemp) => documentotemp.nome !== documento.nome
    )
    if (this.sessionData.segnalazioneSelezionata.id !== undefined) {
      this.lavorazioneService
        .scollegaDocumentoDaLavorazione(documento, lavorazione)
        .then(() => {
          // console.log('eliminazione fisica del documento sul server eseguita');
          this.elencoAllegatiGestore.documenti = lavorazione.documenti
          this.elencoAllegatiGestore.refresh()
        })
        .catch((errore) => {
          console.log(errore)
          this.snackBar.open(
            $localize`Eliminazione fisica del file sul server fallita!`,
            null,
            {
              duration: 6000,
            }
          )
        })
    } else {
      this.elencoAllegatiGestore.documenti = lavorazione.documenti
      this.elencoAllegatiGestore.refresh()
    }
  }

  calcolaNomeGestore(lavorazione: Lavorazione) {
    const gestore = gestoreLavorazioneAbilitato(lavorazione)
    if (gestore) {
      return $localize `Lavorazione di ` + gestore.nomeOdv
    } else {
      return $localize `Non Gestita`
    }
  }

  openDialogCondividiLavorazione(
    event,
    destinatario: Odv,
    lavorazione: Lavorazione
  ): void {
    if (this.dialogRefDirittiInoltro === undefined) {
      this.dialogRefDirittiInoltro = this.dialog.open(
        DirittiInoltroDialogComponent,
        {
          width: '800px',
          data: {
            isLavorazione: true,
            destinatario: destinatario,
            segnalazioneAnonima: this.segnalazione.anonima,
            mancaConsenso: undefined,
            puoChattareConSegnalante: undefined,
            puoConsultareLavorazione: puoConsultareLavorazione(
              lavorazione,
              this.utente
            ),
            puoModificareLavorazione:
              puoModificareLavorazione(lavorazione, this.utente) &&
              this.sessionData.configurazione
                .modificabilitaLavorazioniDaParteDiTerzi,
            puoCreareLavorazioni: undefined,
            puoVedereSegnalante: undefined,
            puoVedereSegnalati: undefined,
            segnalazione: this.segnalazione,
            odvs: [
              lavorazione.dirittiOdv?.find((gestore) => gestore.gestore).odv,
            ],
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefDirittiInoltro.afterClosed().subscribe((result) => {
        // console.log('Inoltro al destinatario con result:' + JSON.stringify(result));
        if (result) {
          const gruppo = gruppoGestoreLavorazioneUtente(
            lavorazione,
            this.authService.getUser()
          )
          if (gruppo === undefined) {
            this.snackBar.open(
              $localize`Inoltro non possibile, l\'utente non risulta appartenente ad un gruppo gestore valido!`,
              null,
              {
                duration: 6000,
              }
            )
            this.dialogRefDirittiInoltro = undefined
            return
          }
          this.condividiLavorazione(destinatario, lavorazione)

          this.dialogRefDirittiInoltro = undefined
        }
      })
    }
  }

  openDialogConfermaScondivisioneLavorazione(
    event,
    dirittiLavorazione: DirittiLavorazione,
    lavorazione: Lavorazione
  ): void {
    if (this.dialogRefConfermaScondivisioneSegnalazione === undefined) {
      this.dialogRefConfermaScondivisioneSegnalazione = this.dialog.open(
        ConfermaScondivisioneSegnalazioneDialogComponent,
        {
          width: '800px',
          data: {
            nomeGruppoOdv: dirittiLavorazione.odv.nomeOdv,
            isLavorazione: true,
          },
          disableClose: true,
          hasBackdrop: true,
        }
      )
      this.dialogRefConfermaScondivisioneSegnalazione
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.scondividiLavorazione(dirittiLavorazione, lavorazione)
          }

          this.dialogRefConfermaScondivisioneSegnalazione = undefined
        })
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ngFormGestore?.dirty) {
      return confirm('Your changes are unsaved!! Do you like to exit')
    }
    return true
  }

  get segnalazione() {
    return this.sessionData.segnalazioneSelezionata
  }

  gruppiAiQualiPossoInoltrare(azienda: Azienda, lavorazione: Lavorazione) {
    const result = azienda.odvAzienda
      .filter(
        (odvAzienda) =>
          this.segnalazione.dirittiSegnalazioneOdv?.find(
            (diritto) => +diritto.odvDestinatario.id === +odvAzienda.odv.id
          ) !== undefined
      )
      .filter(
        (gruppo) =>
          lavorazione.dirittiOdv?.find(
            (diritto) => +diritto.odv.id === gruppo.id
          ) === undefined
      )
    return result
  }

  ciSonoPossibiliDestinatari(lavorazione: Lavorazione): boolean {
    return (
      this.destinatariSegnalazioneAziende?.find(
        (azienda) =>
          this.gruppiAiQualiPossoInoltrare(azienda, lavorazione).length > 0
      ) !== undefined
    )
  }

  openPanel(trigger) {
    if (!trigger.panelOpen) {
      console.log('apro...')
      trigger.openPanel()
    }
  }

  pianificaEliminazioneChanged(event) {
    // console.log(event)
    this.formLavorazione.controls.eliminareSegnalazione.setValue(
      event.checked ? 'true' : 'false'
    )
    if (event.checked) {
      this.formLavorazione.controls.dataDecisioneEliminazione.patchValue(
        new Date()
      )
    } else {
      this.formLavorazione.controls.dataDecisioneEliminazione.patchValue(null)
    }
    this.formLavorazione.markAsDirty()
  }
}
