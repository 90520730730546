import { Injectable } from '@angular/core'
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Configurazione } from '../modello/configurazione'
import { Segnalazione } from '../modello/segnalazione'
import { SessionData } from '../sessione/dati-sessione'
import { ESITO_OK } from '../utility/esito'
import { ConfigurazioneService } from './configurazione.service'
import { SegnalazioneService } from './segnalazione.service'

@Injectable({
  providedIn: 'root'
})
export class DatiSessioneResolver implements Resolve<Configurazione> {
  constructor (private sessionData: SessionData, private configurazioneService: ConfigurazioneService,
    private segnalazioniService: SegnalazioneService) { };

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Configurazione> | Configurazione {
    if (this.sessionData.configurazione) {
      return this.sessionData.configurazione
    }
    this.configurazioneService.recuperaConfigurazione()
      .pipe(
        map(esito => {
          if (ESITO_OK === esito.esito) {
            this.sessionData.configurazione = (JSON.parse(esito.payload) as Configurazione[])[0]
            return this.sessionData.configurazione
          } else {
            throw new Error(esito.payload)
          }
        })
      ).subscribe()
    this.segnalazioniService.recuperaSegnalazioni()
      .pipe(
        map(esito => {
          if (ESITO_OK === esito.esito) {
            this.sessionData.segnalazioni = JSON.parse(esito.payload) as Segnalazione[]
            return this.sessionData.configurazione
          } else {
            throw new Error(esito.payload)
          }
        })
      ).subscribe()
  }
}
