import { DirittiLavorazione } from './../modello/diritti-lavorazione'
import { Injectable } from '@angular/core'
import { Esito } from '../utility/esito'
import { AuthService } from './auth.service'
import { ServiziRest, GlobalVars } from '../utility/utility'
import { HttpClient } from '@angular/common/http'
import { Lavorazione } from '../modello/lavorazione'
import { Documento } from '../modello/documento'
import { Segnalazione } from '../modello/segnalazione'
import { SessionData } from '../sessione/dati-sessione'
import { SegnalazioneService } from './segnalazione.service'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LavorazioneService {
  notificaPerAggiornamentiLavorazione = new Subject()
  notificaPerAggiornamentiDirittoLavorazione = new Subject()
  notificaPerCondivisioneLavorazioni = new Subject()
  notificaPerScondivisioneLavorazioni = new Subject()

  sottoscrizioniAggiornamentiLavorazioni: string[] = new Array<string>()
  notifichePerStatoLavorazioneAggiornato = new Subject<{ idLavorazione: number; stato: number }>()
  sottoscrizioniInoltri: string[] = new Array<string>()
  sottoscrizioniAggiornamentiDirittiLavorazioni: string[] = new Array<string>()

  constructor (
    private http: HttpClient,
    private authenticationService: AuthService,
    private segnalazioneService: SegnalazioneService,
    private sessionData: SessionData) {
  }

  handleErrorPromise (error: Response | any) {
    console.error(error || error.message)
    return Promise.reject(error.error.message || error)
  }

  async recuperaLavorazioniBySegnalazioneEdUtente (idSegnalazione: any): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLavorazione + '/segnalazione/' +
      idSegnalazione).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async recuperaLavorazioniByUtente (): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLavorazioniByUser).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async recuperaLavorazioneById (idLavorazione: number): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLavorazione + '/' +
      idLavorazione).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async recuperaLavorazioneByIdEdOdv (idLavorazione: number, idOdv: number): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlLavorazione + '/byIdEdOdv' +
      '/' + idLavorazione +
      '/' + idOdv).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async salvaLavorazione (lavorazione: Lavorazione, idSegnalazione: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlLavorazione + '/' + idSegnalazione, lavorazione).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async prendiInCaricoLavorazione (idSegnalazione: number, idOdv: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlPrendiInCaricoLavorazione + '/' + idSegnalazione +
      '/' + idOdv, undefined).toPromise()
      .then((response: Esito) => (response as Esito))
      .catch(this.handleErrorPromise)
  }

  async scollegaDocumentoDaLavorazione (documento: Documento, lavorazione: Lavorazione): Promise<string> {
    return this.http.delete<string>(ServiziRest.urlScollegaDocumentoDaLavorazione +
      '/' + documento.id +
      '/' + lavorazione.id).toPromise()
      .then((response: string) => (response as string))
      .catch(this.handleErrorPromise)
  }

  async collegaDocumentoALavorazione (documento: Documento, lavorazione: Lavorazione): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlCreaOAggiornaCollegamentoTraDocumentoELavorazione +
      '/' + lavorazione.id, documento).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async setStatoLavorazione (lavorazione: Lavorazione, segnalazione: Segnalazione): Promise<Esito> {
    return this.http.patch<Esito>( ServiziRest.urlSetStatoLavorazione +
      '/' + lavorazione.id +
      '/' + lavorazione.stato +
      '/' + segnalazione.id,
    null).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async condividiLavorazione (idLavorazione: number, idOdvCheCondivide: number, idOdvDestinatario: number,
    idSegnalazione: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlCondividiLavorazione +
      '/' + idLavorazione +
      '/' + idOdvCheCondivide +
      '/' + idOdvDestinatario +
      '/' + idSegnalazione,
    null).toPromise()
  }

  async scondividiLavorazione (idLavorazione: number, idOdv: number, idSegnalazione: number): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlScondividiLavorazione +
      '/' + idLavorazione + '/' + idOdv + '/' + idSegnalazione, null).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  sottoscriviPerAggiornamentiCondivisioneLavorazione () {
    // console.log('Sottoscrivo per condivisione note per segnalazione: ' + segnalazione.id);
    this.authenticationService.getUser().odvMembership.forEach(gruppoOdv => {
      if (!this.sottoscrizioniInoltri.includes('lavorazione-condivisione-' + gruppoOdv.id)) {
        GlobalVars.socket.on('lavorazione-condivisione-' + gruppoOdv.id, (risposta: any) => {
          const idSegnalazione = risposta[0]
          const idLavorazione = risposta[1]
          const dirittiLavTmp = risposta[2]
          console.log('Ricevuto aggiornamento condivisione della lavorazione: ' + idLavorazione)
          if (this.sessionData.segnalazioneSelezionata &&
            (this.sessionData.segnalazioneSelezionata.id === idSegnalazione)) {
            // SessionData.cambiaSegnalazioneSelezionata(segnalazioneTmp.id);
            if (this.sessionData.segnalazioneSelezionata.dirittiLavorazione === undefined) {
              this.sessionData.segnalazioneSelezionata.dirittiLavorazione = []
            }
            this.sessionData.segnalazioneSelezionata.dirittiLavorazione.push(dirittiLavTmp)
            if (dirittiLavTmp.lavorazione === undefined) {
              dirittiLavTmp.lavorazione = new Lavorazione()
              dirittiLavTmp.lavorazione.id = idLavorazione
            }
            this.notificaPerCondivisioneLavorazioni.next(dirittiLavTmp)
          }
          // this.aggiornaElencoSegnalazioni(segnalazione);
        })
        this.sottoscrizioniInoltri.push('lavorazione-condivisione-' + gruppoOdv.id)
      }

      if (!this.sottoscrizioniInoltri.includes('lavorazione-scondivisione-' + gruppoOdv.id)) {
        GlobalVars.socket.on('lavorazione-scondivisione-' + gruppoOdv.id, (risposta: any) => {
          const segnalazioneTmp = risposta[0]
          const idLavorazione = risposta[1]
          const idOdv = risposta[2]
          console.log('Ricevuto aggiornamento scondivisione della segnalazione: ' + segnalazioneTmp.id)
          if (this.sessionData.segnalazioneSelezionata && this.sessionData.segnalazioneSelezionata.id === segnalazioneTmp.id) {
            this.sessionData.nuovaSegnalazioneSelezionata(segnalazioneTmp)
            this.notificaPerScondivisioneLavorazioni.next({ idLavorazione: idLavorazione, idOdv: idOdv })
          }
          // this.aggiornaElencoSegnalazioni(segnalazione);
        })
        this.sottoscrizioniInoltri.push('lavorazione-scondivisione-' + gruppoOdv.id)
      }
    })
  }

  async sottoscriviPerModificheLavorazione (idLav) {

    if (!this.sottoscrizioniAggiornamentiLavorazioni.includes(idLav)) {
      GlobalVars.socket.on('lavorazione-aggiornata-' + idLav, (idLavorazione: any) => {
        this.notificaPerAggiornamentiLavorazione.next(idLavorazione)
      })
      GlobalVars.socket.on('stato-lavorazione-aggiornato-' + idLav,  (stato: any) => {
        this.notifichePerStatoLavorazioneAggiornato.next({ idLavorazione:idLav, stato })
      })
      GlobalVars.socket.on('documento-aggiunto-lavorazione-' + idLav,
         (pacchetto: any) => {
          this.segnalazioneService.notificaPerDocumentoAggiunto
            .next({ canale: 'documento-aggiunto-lavorazione-' + pacchetto.idLavorazione, documento: pacchetto.documento })
        })
      GlobalVars.socket.on('documento-aggiornato-lavorazione-' + idLav,
         (pacchetto: any) => {
          this.segnalazioneService.notificaPerDocumentoAggiornato
            .next({ canale: 'documento-aggiornato-lavorazione-' + pacchetto.idLavorazione, documento: pacchetto.documento })
        })
      GlobalVars.socket.on('documento-eliminato-lavorazione-' + idLav,
         (pacchetto) => {
          this.segnalazioneService.notificaPerDocumentoEliminato
            .next({ canale: 'documento-eliminato-lavorazione-' + pacchetto.idLavorazione, idDocumento: pacchetto.idDocumento })
        })
      this.sottoscrizioniAggiornamentiLavorazioni.push(idLav)
    }
  }

  async sottoscriviPerModificheDirittiLavorazione (lavorazione: Lavorazione) {
    if (!this.sottoscrizioniAggiornamentiDirittiLavorazioni.includes(lavorazione.id.toString())) {
      GlobalVars.socket.on('diritto-lavorazione-aggiornato-' + lavorazione.id,
        (notifica: NotificaModificaDirittoLavorazione) => {
          this.notificaPerAggiornamentiDirittoLavorazione
            .next(notifica)
        })
      this.sottoscrizioniAggiornamentiDirittiLavorazioni.push(lavorazione.id.toString())
    }
  }

  async annullaSottoscrizionePerModificheLavorazione (idLavorazione) {
    GlobalVars.socket.removeListener('lavorazione-aggiornata-' + idLavorazione)
    const index = this.sottoscrizioniAggiornamentiLavorazioni.findIndex(id => id === idLavorazione)
    if (index !== -1) {
      this.sottoscrizioniAggiornamentiLavorazioni.splice(index, 1)
    }
  }

  async aggiornaDirittiLavorazione (idDirittoLavorazione: number,
    modificaLavorazione: number, idLavorazione: number
  ): Promise<Esito> {
    return this.http.patch<Esito>(ServiziRest.urlAggiornaDirittiLavorazione +
      '/' + idLavorazione +
      '/' + idDirittoLavorazione +
      '/' + modificaLavorazione,
    null).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  async getGestoreLavorazione (idLavorazione: number): Promise<Esito> {
    console.log('idLavorazione: ' + idLavorazione)
    return this.http.get<Esito>(ServiziRest.urlLavorazione + '/gestore/' +
      idLavorazione).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }
}

export interface NotificaModificaDirittoLavorazione {
  idLavorazione: number;
  dirittoLavorazione: DirittiLavorazione;
  idDirittoLavorazione: number;
}
