import {Messaggio} from './messaggio'
import {Odv} from './odv'
import {PinCode} from './pin-code'
import {Chat} from './chat'
import {DirittiSegnalazione} from './diritti-segnalazione'
import { Segnalante } from './segnalante'
import { IdentityProvider } from '../servizi/idp.service'

export class User {

  id: number

  userId: string

  password: string

  nome: string

  cognome: string

  lingua: string

  codiceFiscale: string

  email: string

  emailVerificata: boolean

  emailVerificaInviata: boolean

  pinCode?: PinCode

  mobile?: string

  sessionId?: string

  token?: string

  odv: boolean

  custode: boolean

  richiesteSbloccoSegnalanteLavorate: DirittiSegnalazione[]

  su: boolean

  amministratoreGestori: boolean

  disabilitato: boolean

  messaggiInviati: Messaggio[]

  newPinCode: any

  odvMembership?: Odv[]

  necessarioCambioPassword: boolean

  anonimo: boolean

  automatico: boolean

  partecipaAlleChat: Chat[]

  bloccato: boolean
  loginFalliti: number
  dataUltimoLoginFallito: Date
  comeSegnalante: Segnalante[]
  dataInvioLinkVerificaEmail: Date
  possoUsareEmailPerNotifiche: boolean

  idp: IdentityProvider

  motif: boolean

}

export function odvMembershipToString(user: User): string {
  let risultato = ''
  if (user.odvMembership) {
    user.odvMembership.forEach((odv, index) => {
      risultato += index === 0 ? odv.nomeOdv : ', ' + odv.nomeOdv
    })
  }
  return risultato
}
