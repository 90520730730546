import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from '@angular/core'
import { ActivatedRoute, Data, Router } from '@angular/router'
import { ConfigurazioneService } from '../servizi/configurazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { Esito, ESITO_OK } from '../utility/esito'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  estraiFaq,
  getVersione,
  SottoTipoContenuto,
  TipoContenuto,
} from '../utility/utility'
import { MatDialog } from '@angular/material/dialog'
import { VisualizzatorePdfDialogComponent } from '../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { Documento, TipoDocumento } from '../modello/documento'

import { AuthService } from '../servizi/auth.service'
import { Segnalazione } from '../modello/segnalazione'
import { Segnalante } from '../modello/segnalante'
import { LoginFallitaDialogComponent } from '../login/login.component'
import { User } from '../modello/user'
import { PinCode } from '../modello/pin-code'
import { LoginResponse } from '../modello/http-response-interfaces'
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { CustomValidators } from '../validators/CustomValidators'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { Faq } from '../modello/domanda'
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { ContenutiService } from '../servizi/contenuti.service'
import { forkJoin } from 'rxjs'
import { SessionDataService } from '../servizi/session-data.service'
import { UserBehaviorTrackingService } from '../servizi/user-behavior-tracking.service'
import { PincodeService } from '../servizi/pincode.service'

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations: [
    trigger('domanda', [
      state(
        'normale',
        style({
          backgroundColor: 'var(--colore-riferimento)',
          color: 'var(--colore-background)',
        })
      ),
      state(
        'selezionato',
        style({
          backgroundColor: 'var(--colore-background-chiaro)',
          color: 'var(--colore-riferimento)',
        })
      ),
      transition('normale <=> selezionato', animate(3000)),
    ]),
    trigger('risposta', [
      state(
        'normale',
        style({
          visibility: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'selezionato',
        style({
          visibility: 'visible',
          opacity: 1,
        })
      ),
      transition('* <=> selezionato', animate(1000)),
    ]),
  ],
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  @ViewChild('myVideo', { static: false })
  myVideo: ElementRef<HTMLVideoElement>
  @ViewChild('videoError', { static: false })
  videoError: ElementRef<HTMLDivElement>

  utente: User
  pinCode: string
  loginForm: UntypedFormGroup
  textOrPassword = 'password'
  faq: Faq[]
  selectedDomanda: string
  token: any
  subdomain: string

  constructor(
    private router: Router,
    private configurazioneService: ConfigurazioneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sessionData: SessionData,
    // private recaptcha3: NgRecaptcha3Service,
    private authService: AuthService,
    private segnalazioneService: SegnalazioneService,
    private route: ActivatedRoute,
    private contenutiService: ContenutiService,
    private cd: ChangeDetectorRef,
    private sessionDataService: SessionDataService,
    private userBehaviorTracking: UserBehaviorTrackingService,
    private pincodeService: PincodeService,
  ) {
    this.utente = new User()
    const hostname = window.location.hostname
    this.subdomain = hostname.split('.')[0]
    console.log(this.subdomain) // stampa "dae"
  }

  dataLoaded = false
  inizializzato = false

  ngOnInit() {
    this.userBehaviorTracking.startSession();
    this.authService.removeStoredCredentials()

    this.sessionDataService.initialize()
    this.sessionDataService.aggiornamentoDatiSessione.subscribe(() => {
      this.inizializzato = true
    })

    this.route.data.subscribe((data: Data) => {
      const esito = data['esito']

      if (esito.esito === ESITO_OK) {
        this.sessionData.configurazione = JSON.parse(esito.payload)[0]

        const faq$ = this.contenutiService.getVersioneContenuto(
          TipoContenuto.DOCUMENTO,
          SottoTipoContenuto.FAQ,
          this.codiceLingua
        )
        const normativa$ = this.contenutiService.getVersioneContenuto(
          TipoContenuto.DOCUMENTO,
          SottoTipoContenuto.NORMATIVA,
          this.codiceLingua
        )

        const testoHome$ = this.contenutiService.getVersioneContenuto(
          TipoContenuto.DOCUMENTO,
          SottoTipoContenuto.LANDING_PAGE,
          this.codiceLingua
        )

        forkJoin([faq$, normativa$, testoHome$]).subscribe(
          ([faqData, normativaData, homeData]) => {
            this.createForm()
            // Operazioni su faqData
            if (faqData.esito === ESITO_OK) {
              const faq = JSON.parse(faqData.payload) as VersioneContenuto
              this.faq = estraiFaq(faq.testo)
              this.cd.detectChanges()
              // this.createForm()
            } else {
              this.snackBar.open(
                $localize`Errore nel recupero delle FAQ. ` + esito['payload'],
                null,
                {
                  duration: 8000,
                }
              )
            }

            // Operazioni su normativaData
            if (normativaData.esito === ESITO_OK) {
              const trad = JSON.parse(
                normativaData.payload
              ) as VersioneContenuto
              this.sessionData.normativa = trad.testo
              this.cd.detectChanges()
              // this.createForm()
            } else {
              this.snackBar.open(
                $localize`Errore nel recupero della Normativa. ` +
                esito['payload'],
                null,
                {
                  duration: 8000,
                }
              )
            }

            // Operazioni su homeData
            if (homeData.esito === ESITO_OK) {
              const trad = JSON.parse(homeData.payload) as VersioneContenuto
              this.sessionData.testoHome = trad.testo
              this.cd.detectChanges()
              // this.createForm()
            } else {
              this.snackBar.open(
                $localize`Errore nel recupero del Testo in landing page. ` +
                esito['payload'],
                null,
                {
                  duration: 8000,
                }
              )
            }
            this.cd.detectChanges()
            this.dataLoaded = true
          }
        )

        return this.sessionData.configurazione
      } else {
        this.snackBar.open(
          $localize`Errore nel recupero della configurazione. ` +
          esito['payload'],
          null,
          {
            duration: 8000,
          }
        )
      }
    })

    this.caricaConfigurazione()
  }

  ngAfterViewInit(): void {
    if (this.myVideo) {
      this.myVideo.nativeElement.onerror = () => {
        this.myVideo.nativeElement.style.display = 'none'
        this.videoError.nativeElement.style.display = 'block'
      }
    }
    // dopo un timeout di 5 secondi verifico se i dati sono stati caricati
    setTimeout(() => {
      if (!this.dataLoaded || !this.inizializzato) {
        // ricarico la pagina
        window.location.reload()
      }
    }
      , 5000)
  }

  private createForm() {
    this.loginForm = new UntypedFormGroup({
      userId: new UntypedFormControl(
        '',
        this.modalitaPinUnico ? [] : Validators.required
      ),
      password: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          this.modalitaPinUnico
            ? CustomValidators.lunghezzaMinimaPin(
              this.sessionData.configurazione?.lunghezzaMinimaPIN
            )
            : CustomValidators.lunghezzaMinimaPassword(
              this.sessionData.configurazione?.lunghezzaMinimaPassword
            ),
          this.modalitaPinUnico
            ? CustomValidators.maiuscolePin(
              this.sessionData.configurazione?.maiuscolePIN
            )
            : CustomValidators.maiuscolePassword(
              this.sessionData.configurazione?.maiuscolePassword
            ),
          this.modalitaPinUnico
            ? CustomValidators.minuscolePin(
              this.sessionData.configurazione?.minuscolePIN
            )
            : CustomValidators.minuscolePassword(
              this.sessionData.configurazione?.minuscolePassword
            ),
          this.modalitaPinUnico
            ? CustomValidators.numericiPin(
              this.sessionData.configurazione?.numeriPIN
            )
            : CustomValidators.numericiPassword(
              this.sessionData.configurazione?.numeriPassword
            ),
          this.modalitaPinUnico
            ? CustomValidators.specialiPin(
              this.sessionData.configurazione.caratteriSpecialiPIN
            )
            : CustomValidators.specialiPassword(
              this.sessionData.configurazione?.caratteriSpecialiPassword
            ),
        ])
      ),
    })
  }

  get modalitaPinUnico(): boolean {
    return this.sessionData.configurazione?.modalitaPinUnicoPerSegnalazione
  }

  accessoAnonimo() {
    // console.log('Navigo verso accesso anonimo....');
    this.router.navigate(['/login-anonima'], {})
  }

  accessoUtente() {
    this.router.navigate(['/login'], {})
  }

  registrazioneUtente() {
    this.router.navigate(['/registrazione'], {})
  }

  recuperaPin() {
    this.router.navigate(['/reset-pin'], {})
  }

  richiestaPinAnonimo() {
    this.router.navigate(['/login-anonima-richiesta-pin'], {})
  }

  caricaConfigurazione() {
    this.configurazioneService.recuperaConfigurazione().subscribe(
      (esito) => {
        if (esito.esito === ESITO_OK) {
          this.sessionData.configurazione = JSON.parse(esito.payload)[0]
        } else {
          this.snackBar.open(
            $localize`Errore nel recupero della configurazione. ` +
            esito.descrizioneEsito,
            null,
            {
              duration: 4000,
            }
          )
        }
      },
      (errore) => {
        this.snackBar.open(
          $localize`Errore nel recupero della configurazione. ` +
          errore.toString(),
          null,
          {
            duration: 4000,
          }
        )
      }
    )
  }

  get versione(): string {
    return getVersione()
  }

  getTestoHome() {
    return this.sessionData.testoHome !== undefined
      ? this.sessionData.testoHome
      : ''
  }

  getFAQ() {
    return this.faq
  }

  getNormativa() {
    return this.sessionData.normativa !== undefined
      ? this.sessionData.normativa
      : ''
  }

  apriDialogProceduraWhistleblowing() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.proceduraWhistleblowingDocumento,
        testo: this.sessionData.configurazione.proceduraWhistleblowing,
        titolo: $localize`Procedura Whistleblowing`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.PROCEDURA_WHISTLEBLOWING,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  apriDialogInformativaTrattamentoDati() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.informativaTrattamentoDocumento,
        testo: this.sessionData.configurazione.informativaTrattamento,
        titolo: $localize`Informativa Privacy`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo:
          SottoTipoContenuto.INFORMATIVA_SUL_TRATTAMENTO_DEI_DATI_PERSONALI,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  apriDialogManualeUtente() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.manualeUtenteDocumento,
        testo: this.sessionData.configurazione.manualeUtente,
        titolo: $localize`Manuale Utente`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.MANUALE_UTENTE,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  apriMisureDiSicurezza() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.misureDiSicurezzaDocumento,
        testo: this.sessionData.configurazione.misureDiSicurezza,
        titolo: $localize`Misure di Sicurezza`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.MISURE_DI_SICUREZZA,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  getLogoCliente() {
    return this.sessionData.logoCliente !== undefined &&
      this.sessionData.logoCliente.base64 !== undefined
      ? this.sessionData.logoCliente.base64
      : this.getLogoSecure()
  }

  getLogoSecure() {
    return this.sessionData.logoSecure !== undefined &&
      this.sessionData.logoSecure.base64 !== undefined
      ? this.sessionData.logoSecure.base64
      : '/assets/img/logo-secure.png'
  }

  get configurazione() {
    return this.sessionData.configurazione
  }
  get password() {
    return this.loginForm.get('password')
  }

  async apriSegnalazione() {

    const isBehaviorNormal = await this.userBehaviorTracking.sendBehaviorData();

    if (!isBehaviorNormal) {
      console.warn('Login non eseguito a causa di comportamento sospetto');
      return;
    }
    this.authService
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .loginNuovaSegnalazione()
      .then((data: User) => {
        if (this.authService.isLoggedIn()) {
          // SessionData.utente = data;
          // console.log('Navigo nella home.....');
          const segnalazione = new Segnalazione()
          const segnalante: Segnalante = new Segnalante()

          segnalazione.segnalante = segnalante
          segnalazione.segnalante.utente = this.authService.getUser()
          segnalazione.segnalante.utente.lingua = this.codiceLingua
          segnalazione.anonima = false
          segnalazione.stato = '0'

          segnalazione.presaInCarico = false
          segnalazione.documenti = new Array<Documento>()

          this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
          this.sessionData.pinCode = data?.pinCode?.pincode
          // console.log('avvio creazione nuova segnalazione:');
          // naviga verso segnalazione e passa il pinCode come parametro
          this.pincodeService.pinCode = data?.pinCode?.pincode;
          this.router.navigate(['/segnalazione'])
        } else {
          this.openDialogLoginFallita('Login Fallita')
        }
      })
      .catch((err) => {
        console.log('errore login:' + err)
        this.openDialogLoginFallita(err)
      })
  }
  // .catch((error) => {
  //  console.log(error)
  //  this.snackBar.open($localize `Impossibile eseguire l\'apertura del modulo. Rivolgersi al supporto. ', null, {
  //    duration: 8000,
  //  })
  // })
  // }

  openDialogLoginFallita(err?: string): void {
    const dialogRef = this.dialog.open(LoginFallitaDialogComponent, {
      width: '500px',
      data: { err },
      disableClose: true,
      hasBackdrop: true,
    })

    dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed');
    })
  }

  async loginSegnalazione() {

    const isBehaviorNormal = await this.userBehaviorTracking.sendBehaviorData();

    if (!isBehaviorNormal) {
      console.warn('Login non eseguito a causa di comportamento sospetto');
      return;
    }

    if (!this.configurazione.modalitaPinUnicoPerSegnalazione) {
      this.utente.userId = this.loginForm.controls.userId.value
    } else {
      this.utente.userId = undefined
    }

    // console.log('utente: ' + this.utente);
    if (!this.utente.pinCode) {
      this.utente.pinCode = new PinCode()
    }
    this.utente.pinCode.pincode = this.loginForm.controls.password.value
    this.pinCode = this.loginForm.controls.password.value

    // this.recaptcha3.getToken({ action: 'login_segnalazione' }).then(token => {
    this.authService
      .loginConPin(
        this.utente,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        JSON.stringify({ recaptchav3_token: this.token })
      )
      .then((data: LoginResponse) => {
        if (+data.answerCode === -2) {
          //  Necessario cambio password
          // this.openDialogCambioPassword();
          console.error('Ricevuto obbligo cambio passowrd')
        } else if (this.authService.isLoggedIn()) {
          // SessionData.utente = data;
          // console.log('Navigo nella home.....');
          //  ora recupera la segnalazione
          this.segnalazioneService.recuperaSegnalazioni().subscribe(
            (esito) => {
              if (esito.esito === ESITO_OK) {
                this.sessionData.segnalazioni = JSON.parse(esito.payload)
                if (this.sessionData.segnalazioni.length > 0) {
                  this.sessionData.nuovaSegnalazioneSelezionata(
                    this.sessionData.segnalazioni[0]
                  )
                  this.pincodeService.pinCode = this.pinCode;

                  this.router.navigate(['/segnalazione'])
                } else {
                  this.snackBar.open(
                    $localize`Nessuna segnalazione associata al PIN.`,
                    null,
                    {
                      duration: 8000,
                    }
                  )
                }
              }
            },
            (errore) => {
              this.snackBar.open(
                $localize`Impossibile recuperare la segnalazione. Rivolgersi al supporto.` +
                errore,
                null,
                {
                  duration: 8000,
                }
              )
            }
          )
        } else {
          this.openDialogLoginFallita(data.answerCode.toString())
        }
      })
      .catch((err) => {
        // console.log('errore login:' + err);
        this.openDialogLoginFallita(err)
      })
  }
  //   }).catch((error) => {
  //     console.log(error)
  //     this.snackBar.open($localize `Impossibile eseguire la login. Rivolgersi al supporto. ', null, {
  //       duration: 8000,
  //     })
  //   })
  // }

  login() {
    if (this.modalitaPinUnico) {
      this.loginSegnalazione()
      return
    }
    this.utente.userId = this.loginForm.controls.userId.value
    // console.log('utente: ' + this.utente);
    if (!this.utente.pinCode) {
      this.utente.pinCode = new PinCode()
    }
    this.utente.pinCode.pincode = this.loginForm.controls.password.value
    this.pinCode = this.loginForm.controls.password.value
    // this.utente.email = this.loginForm.controls.idUtente.value;
    // this.utente.pinCode.pincode = this.loginForm.controls.password;
    // ...
    // this.recaptcha3.getToken({ action: 'login' }).then(token => {
    this.authService
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .login(this.utente, JSON.stringify({ recaptchav3_token: this.token }), undefined, undefined)
      .subscribe((esito: Esito) => {
        if (ESITO_OK === esito.esito) {
          const payload: LoginResponse = JSON.parse(esito.payload)
          if (payload.idToken !== null) {
            //    Nuovo login dobbiamo memorizzare userPk, sessionId e ruolo
            // console.log('Rilevato nuovo login memorizzo sessionId e userPk');
            // console.log('response.idToken:' + payload.idToken);
            // console.log('utente:' + JSON.stringify(payload.user));
            this.authService.setSession(payload)
            this.router.navigate(['/home'])
          } else {
            //  Il backend ha risposto con un codice di errore
            this.snackBar.open(
              $localize`Impossibile eseguire la login. Rivolgersi al supporto. ` +
              payload.answerCode,
              null,
              {
                duration: 8000,
              }
            )
          }
        } else {
          this.snackBar.open(esito.payload, null, {
            duration: 8000,
          })
        }
      }, ((err) => {
        // console.log('errore login:' + err);
        this.openDialogLoginFallita(err)
      }))
    // }).catch((error) => {
    //   console.log(error)
    //   this.snackBar.open($localize `Impossibile eseguire la login. Rivolgersi al supporto. ', null, {
    //     duration: 8000,
    //   })
    // })
  }

  // keyDownFunction(event) {
  //   if (event.keyCode === 13 && !this.loginForm.invalid) {
  //     this.login()
  //     // rest of your code
  //   }
  // }

  visualizza(idDomanda: string) {
    const domandaSelezionata = this.faq.find((d) => d.id === idDomanda)
    if (domandaSelezionata.stato === 'normale') {
      domandaSelezionata.stato = 'selezionato'
    } else {
      domandaSelezionata.stato = 'normale'
    }
    const previous = this.faq.find((d) => d.id === this.selectedDomanda)
    if (previous) {
      previous.stato = 'normale'
    }
    // this.faq = [this.faq.find(dom => dom.id == idDomanda), ...this.faq.filter(dom => dom.id !=== idDomanda)]
    console.log(document.getElementById(idDomanda))
    this.selectedDomanda = idDomanda
    // document.getElementById(idDomanda).style.visibility = 'visible'
  }

  domandaSelezionata(idDomanda: string) {
    return this.selectedDomanda === idDomanda
  }

  apriDialogPrivacyPolicy() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione.privacyPolicyDocumento,
        testo: this.sessionData.configurazione.privacyPolicy,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        titolo: 'Cookies & Privacy Policy',
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.PRIVACY,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: true,
        canPrint: true,
      },
    })
  }

  calcolaInnerHtmlRisposta(risposta: string) {
    // console.log(risposta)
    // console.log(risposta, risposta.indexOf(' ...'))
    const pngPos = risposta.indexOf('.png')
    const trePuntiPos = risposta.indexOf('...')
    let htmlImg = ''
    // console.log('trePuntiPos', pngPos)
    if (trePuntiPos >= 0) {
      const immagine = risposta.substr(
        trePuntiPos + 3,
        pngPos - trePuntiPos + 1
      )
      htmlImg = `<br><br><center><img class="immagine-faq" height="120px" width="auto" src="/assets/img/${immagine}"/>`
      risposta = risposta.substring(0, trePuntiPos - 1)
    }
    // console.log(htmlImg)
    return risposta + htmlImg
  }

  scrollToTop(): void {
    setTimeout(
      () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' }),
      0
    )
  }

  onClickLogin(event) {
    this.login()
  }

  onClickNuovaSegnalazione(event) {

    this.apriSegnalazione()

  }

  get mostraLinkProcedureWhistleblowing() {
    return this.configurazione?.mostraProcedureWhistleblowingInLanding
  }

  get mostraLinkInformativa() {
    return this.configurazione?.mostraPrivacyPolicyInLanding
  }

  get mostraLinkManualeUtente() {
    return this.configurazione?.mostraManualeUtenteInLanding
  }

  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  // Deve essere possibile cambiare il valore alle seguenti variabili css:
  // --colore-background: #44c8f5;
  // --colore-background-chiaro: #ededed;
  // --colore-background-toolbar: #4b208b; /* Qui devi mettere un valore fisso o utilizzare JS per modificare il valore a runtime */
  // --colore-testo-toolbar: #e0f4f8; /* Qui devi mettere un valore fisso o utilizzare JS per modificare il valore a runtime */
  // --colore-riferimento: #206d8b;
  // --colore-testo: #444;
  // --colore-testo-chiaro: #fff;
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.userBehaviorTracking.trackMouseMove(e);
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    this.userBehaviorTracking.trackKeyStroke(e);
  }

  @HostListener('document:click', ['$event'])
  onClick(e: MouseEvent) {
    this.userBehaviorTracking.trackClick(e);
  }

  @HostListener('document:touchend', ['$event'])
  onTouch(e: TouchEvent) {
    this.userBehaviorTracking.trackClick(e);
  }
}
