import { ESITO_IN_PROGRESS, ESITO_OK } from './../utility/esito'
import { RicercaService } from './../servizi/ricerca.service'
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
} from '@angular/core'
import { Messaggio } from '../modello/messaggio'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { Router } from '@angular/router'
import { InfoUtenteDialogComponent } from '../info-utente/info-utente.dialog.component'
import { AuthService } from '../servizi/auth.service'
import { SessioneScadutaDialogComponent } from '../elenco-segnalazioni/sessione-scaduta.dialog.component'
import { RoutingState } from '../routing-helper/routing-state'
import { ChatService } from '../servizi/chat.service'
import { Esito } from '../utility/esito'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { CookiesPolicyComponent } from '../login/cookies-policy/cookies-policy.component'
import { TerminiServizioComponent } from '../login/termini-servizio/termini-servizio.component'
import { PrivacyPolicyComponent } from '../login/privacy-policy/privacy-policy.component'
import { MessaggiStore } from '../stores/messaggi.store'
import { Subscription } from 'rxjs'
import { ColorPickerComponent } from '../componenti/color-picker/color-picker.component'
import { AutorizzazioneOscuramento } from '../modello/autorizzazione-oscuramento'
import { OscuramentiService } from '../servizi/oscuramenti.service'
import { apriSegnalazioneSelezionata, SottoTipoContenuto, TipoContenuto } from '../utility/utility'
import { VisualizzatorePdfDialogComponent } from '../componenti/visualizzatore-pdf/visualizzatore-pdf.component'
import { TipoDocumento } from '../modello/documento'
import { NewClientDialogComponent } from '../componenti/new-client-dialog/new-client-dialog.component'
import { ClientiService } from '../servizi/clienti.service'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() mostraFiltro = true

  // dialogRefUtente = undefined;
  messaggiNonLetti: Messaggio[]
  sottoscrizioneNonLetti: Subscription
  sottoscrizioneLetti: Subscription
  sottoscrizioneSessioneScaduta: Subscription
  sottoscrizioneConsensi: Subscription
  numeroRichiesteSboccoDaLavorare = 0
  colorPickerDialogRef: any
  autorizzazioniPendenti: AutorizzazioneOscuramento[]
  autorizzazioniPendentiTotali: any
  consensiDaAutorizzare: any
  existingClients: any

  constructor(
    private dialog: MatDialog,
    private segnalazioniService: SegnalazioneService,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private ricercaService: RicercaService,
    private routingState: RoutingState,
    private chatService: ChatService,
    private sessionData: SessionData,
    private messaggiStore: MessaggiStore,
    private oscuramentiService: OscuramentiService,
    private clientiService: ClientiService,
  ) {
    this.recuperaAutorizzazioniPendenti()
    this.recuperaConsensiDaAutorizzare()
  }

  ngOnInit() {
    this.messaggiStore.onInit()
    this.sottoscrizioneNonLetti =
      this.messaggiStore.messaggiNonLettiAggiornati.subscribe((messaggi) => {
        this.messaggiNonLetti = messaggi
      })
    // this.messaggiStore.caricaMessaggiNonLetti()
    this.messaggiNonLetti = this.messaggiStore.messaggiNonLetti

    this.recuperaNumeroRichiesteSbloccoDaLavorare()

    this.authService.sessioneScaduta.subscribe(() => {
      this.router.navigate(['/landing-page'], { queryParams: { initialize: true } })
    })

    this.sottoscrizioneSessioneScaduta =
      this.authService.sessioneScaduta.subscribe(() => {
        this.sessioneScaduta()
      })

    // si sottoscrive al subject eliminaAutorizzazionePendente
    this.oscuramentiService.eliminaAutorizzazionePendente.subscribe((idAutorizzazione) => {
      // elimina l'autorizzazione pendente dall'elenco
      this.autorizzazioniPendenti = this.autorizzazioniPendenti.filter((autorizzazione) =>
        autorizzazione.id !== idAutorizzazione)
      // elimina l'autorizzazione pendente dall'elenco totale
      this.autorizzazioniPendentiTotali = this.autorizzazioniPendentiTotali.filter((autorizzazione) =>
        autorizzazione.id !== idAutorizzazione)
    })

    this.sottoscrizioneConsensi = this.segnalazioniService.notificaConsensoAggiornato
      .subscribe((consenso) => {
        this.recuperaConsensiDaAutorizzare()
      })
  }

  recuperaAutorizzazioniPendenti() {
    this.oscuramentiService.recuperaAutorizzazioniPendenti().subscribe((esito) => {
      if (esito.esito === ESITO_OK) {
        this.autorizzazioniPendentiTotali = JSON.parse(esito.payload)
        this.autorizzazioniPendenti = this.autorizzazioniPendentiTotali.filter((autorizzazione) =>
          autorizzazione.utente.id === this.authService.getUser().id)
      } else {
        throw new Error(esito.payload)
      }
    })
  }

  getSegnalazione(autorizzazione: AutorizzazioneOscuramento) {
    return autorizzazione.oscuramentoDatiManifestamenteNonNecessari.segnalazione
  }

  getGestore(autorizzazione: AutorizzazioneOscuramento) {
    return autorizzazione.odv
  }

  ngOnDestroy(): void {
    if (this.sottoscrizioneLetti) {
      this.sottoscrizioneLetti.unsubscribe()
    }
    if (this.sottoscrizioneNonLetti) {
      this.sottoscrizioneNonLetti.unsubscribe()
    }
    if (this.sottoscrizioneSessioneScaduta) {
      this.sottoscrizioneSessioneScaduta.unsubscribe()
    }
    if (this.sottoscrizioneConsensi) {
      this.sottoscrizioneConsensi.unsubscribe()
    }
  }

  recuperaNumeroRichiesteSbloccoDaLavorare() {
    this.segnalazioniService
      .recuperaNumeroRichiesteSbloccoDaLavorare()
      .then((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.numeroRichiesteSboccoDaLavorare = JSON.parse(esito.payload)
        } else {
          throw new Error(esito.payload)
        }
      })
      .catch((errore: Error) => {
        this.snackBar.open(
          $localize`Non è stato possibile caricare il numero di richieste di sblocco ancora da lavorare ` +
          errore.message,
          null,
          {
            duration: 4000,
          }
        )
      })
  }

  recuperaConsensiDaAutorizzare() {
    this.segnalazioniService
      .consensiDaAutorizzare()
      .subscribe((esito: Esito) => {
        if (esito.esito === ESITO_OK) {
          this.consensiDaAutorizzare = JSON.parse(esito.payload)
        } else {
          throw new Error(esito.payload)
        }
      })
  }


  openChatSegnalazioneDaId(messaggio: Messaggio): void {
    const tempsegnalazione = this.sessionData.segnalazioni.find(
      (segnalazione) => +segnalazione.id === +(messaggio.chat.segnalazione ? messaggio.chat.segnalazione.id :
        messaggio.segnalazione ? messaggio.segnalazione.id : undefined)
    )
    if (tempsegnalazione) {
      this.segnalazioniService.recuperaSegnalazione(+tempsegnalazione.id).subscribe
        ((esito) => {
          if (esito.esito === ESITO_OK) {
            const segnalazione = JSON.parse(esito.payload)
            this.sessionData.nuovaSegnalazioneSelezionata(segnalazione)
            this.chatService
              .recuperaChatById(messaggio.chat?.id.toString())
              .then((esito) => {
                if (ESITO_OK === esito.esito) {
                  const chat = JSON.parse(esito.payload)
                  this.sessionData.nuovaChatSelezionata(chat)
                  this.sessionData.messaggiSegnalazioneSelezionata = []
                  // console.log('this.router.url:' + this.router.url);
                  //  se già mi trovo nella pagina di chat manager utilizzo un emit
                  if (this.router.url === '/chat-manager') {
                    this.messaggiStore.aggiornaStatoMessaggioLetto(messaggio)
                    this.chatService.notificaPerChatSelezionata.next(chat)
                  } else {
                    this.router.navigate(['chat-manager'])
                  }
                } else {
                  console.error(esito.descrizioneEsito)
                }
              })
          } else if (esito.esito === ESITO_IN_PROGRESS) {
            // Gestisci lo stato in progress come preferisci
            // Ad esempio, potresti mostrare un messaggio di attesa all'utente
            console.log(esito.descrizioneEsito)
          } else {
            throw new Error(esito.payload)
          }
        }
        )
    }
  }

  back() {
    console.log(this.routingState.getPreviousUrl())
    this.router.navigateByUrl(this.routingState.getPreviousUrl())
    // this.location.back();
  }

  canGoBack(): boolean {
    return this.routingState.getPreviousUrl() !== '/index'
  }

  applyFilter(valore: string) {
    this.ricercaService.filtraElenco.emit(valore)
  }

  infoUtente() {
    this.openDialogInfoUtente()
  }

  openDialogInfoUtente(): void {
    const dialogRefUtente = this.dialog.open(InfoUtenteDialogComponent, {
      width: '800px',
      data: {},
      disableClose: true,
      hasBackdrop: true,
    })
    dialogRefUtente.backdropClick().subscribe(() => {
      // Close the dialog
      dialogRefUtente.close()
    })
    dialogRefUtente.afterClosed().subscribe(() => { })
  }

  logout() {
    const navigationPromise = this.router.navigate(['/landing-page'], { queryParams: { initialize: true } })

    navigationPromise
      .then((isNavigationSuccessful) => {
        if (isNavigationSuccessful) {
          this.authService.logout().catch((error) => {
            console.error('Logout error:', error)
          })
        }
      })
      .catch((error) => {
        console.error('Navigation error:', error)
      })
  }

  apriGestioneUtenti() {
    this.router.navigate(['/utenti'])
  }

  apriGestioneGruppi() {
    this.router.navigate(['/gruppi'])
  }

  apriGestioneAziende() {
    this.router.navigate(['/aziende'])
  }

  apriHome() {
    this.router.navigate(['/home'])
  }

  apriGestioneLavorazioni() {
    this.router.navigate(['/lavorazioni'])
  }

  get puoAprireElencoSegnalazioni() {
    const result =
      this.sessionData.configurazione?.modalitaPinUnicoPerSegnalazione &&
      this.authService.getUser()?.automatico

    return !result
  }

  get puoAprireElencoLavorazioni() {
    const result =
      this.sessionData.configurazione?.modalitaPinUnicoPerSegnalazione &&
      this.authService.getUser()?.automatico

    return !result
  }

  apriStatistiche() {
    this.router.navigate(['/statistiche'])
  }

  apriLogOperazioni() {
    this.router.navigate(['/log-operazioni'])
  }

  apriConfigurazione() {
    this.router.navigate(['/configurazione'])
  }

  apriRichesteSblocco() {
    this.router.navigate(['/richieste-sblocco'])
  }

  apriInformative() {
    this.router.navigate(['informative'])
  }

  apriGestioneChat() {
    this.router.navigate(['/chat-manager'])
  }

  superUtente() {
    if (this.authService.getUser()) {
      return this.authService.getUser()?.su
    } else {
      return false
    }
  }

  amministratoreGestori() {
    if (this.authService.getUser()) {
      return this.authService.getUser()?.amministratoreGestori
    } else {
      return false
    }
  }

  custode() {
    if (this.sessionData.configurazione?.custodiaIdentita) {
      if (this.authService.getUser()) {
        return this.authService.getUser()?.custode
      } else {
        return false
      }
    }
  }

  odv() {
    if (this.authService.getUser()) {
      return this.authService.getUser()?.odv
    } else {
      return false
    }
  }

  get utenteSemplice() {
    if (this.authService.getUser()?.automatico) {
      return true
    } else {
      return false
    }
  }

  sessioneScaduta() {
    const dialogRef = this.dialog.open(SessioneScadutaDialogComponent, {
      width: '800px',
      data: {},
      disableClose: true,
      hasBackdrop: true,
    })
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/landing-page'], { queryParams: { initialize: true } })
    })
  }

  apriDialogCookiesPolicy() {
    this.dialog.open(CookiesPolicyComponent, {
      width: '60%',
      data: {},
    })
  }

  apriDialogTerminiServizio() {
    this.dialog.open(TerminiServizioComponent, {
      width: '60%',
      data: {},
    })
  }

  apriDialogPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyComponent, {
      width: '60%',
      data: {},
      panelClass: 'alternate-theme',
    })
  }

  getLogoCliente() {
    return (this.sessionData.logoCliente !== undefined && this.sessionData.logoCliente.base64 !== undefined ?
      this.sessionData.logoCliente.base64 : this.getLogoSecure())
  }

  getLogoSecure() {
    return (this.sessionData.logoSecure !== undefined && this.sessionData.logoSecure.base64 !== undefined ?
      this.sessionData.logoSecure.base64 : '/assets/img/logo-secure.png')
  }


  mittente(messaggio: Messaggio) {
    if (messaggio.mittente) {
      if (messaggio.mittente.automatico) {
        return $localize`Segnalante`
      } else {
        return this.calcolaGruppoMittente(messaggio)
      }
    } else {
      return $localize`Anonimo`
    }
  }

  calcolaGruppoMittente(messaggio: Messaggio) {
    // ricavo i gruppi partecipanti alla chat:
    const gruppi = messaggio.chat.gruppiPartecipanti
    // ricavo il gruppo del mittente:
    const gruppiMittente = messaggio.mittente.odvMembership
    // incrocia gruppi e gruppoMittente restituendo il nome del primo gruppo che matcha perchè ha lo stesso id
    const gruppo = gruppi?.find((gruppo1) =>
      gruppiMittente.find((gruppoMittente) => gruppoMittente.id === gruppo1.id)
    )
    return gruppo?.nomeOdv
  }

  contenutoMessaggio(messaggio: Messaggio) {
    if (
      messaggio.testo === undefined ||
      messaggio.testo === null ||
      messaggio.testo === ''
    ) {
      if (messaggio.audioHash) {
        return $localize`[audio]`
      }
    }
    return messaggio.testo?.length > 20
      ? messaggio.testo.slice() + '...'
      : messaggio.testo
  }

  messaggioDa(messaggio) {
    return (
      '(' +
      (messaggio.segnalazione ? messaggio.segnalazione.progressivo : messaggio.chat.segnalazione.progressivo) +
      ')' +
      $localize`Messaggio da ` +
      this.mittente(messaggio) +
      ':' +
      this.contenutoMessaggio(messaggio)
    )
  }

  openColorPicker(): void {
    if (this.colorPickerDialogRef) {
      this.colorPickerDialogRef.close()
      this.colorPickerDialogRef = undefined
    } else {
      this.colorPickerDialogRef = this.dialog.open(ColorPickerComponent, {
        width: '800px',
        hasBackdrop: false,
      }).afterClosed().subscribe(() => {
        this.colorPickerDialogRef = undefined
      }
      )
    }
  }

  apriSegnalazioneSelezionata(autorizzazione: AutorizzazioneOscuramento) {
    // apri la segnalazione
    const segnalazione = autorizzazione.oscuramentoDatiManifestamenteNonNecessari.segnalazione
    apriSegnalazioneSelezionata(segnalazione, this.segnalazioniService, this.sessionData, this.router, this.snackBar)
  }


  apriDialogManualeGestore() {
    const dialogRef = this.dialog.open(VisualizzatorePdfDialogComponent, {
      width: '100vw',
      maxWidth: '1024px',
      data: {
        src: this.sessionData.configurazione?.manualeGestoreDocumento,
        testo: this.sessionData.configurazione?.manualeGestore,
        titolo: $localize`Manuale Gestore`,
        tipoDocumento: TipoDocumento.TIPO_INFORMATIVA,
        tipo: TipoContenuto.DOCUMENTO,
        sottotipo: SottoTipoContenuto.MANUALE_GESTORE,
        lingua: this.codiceLingua,
        bypassAuth: true,
        canDownload: false,
        canPrint: false,
      },
    })
  }

  get motif() {
    return this.authService.getUser()?.motif
  }

  aggiungiNuovoCliente() {
    // recuperare la lista dei clienti esistenti
    this.clientiService.getClients().subscribe((esito) => {
      if (esito.esito === ESITO_OK) {
        this.existingClients = JSON.parse(esito.payload)
        this.openNewClientDialog()
      } else {
        this.snackBar.open($localize`Errore nella creazione dell'ambiente per il nuovo cliente: ${esito.payload}`, null, {
          duration: 8000,
        })
      }
    })
  }

  openNewClientDialog(newClientName?, existingClientName?, ipaddress?) {
    // recuperare la lista dei clienti esistenti

    const dialogRef = this.dialog.open(NewClientDialogComponent, {
      width: '50vw',
      data: {
        existingClients: ['', ...this.existingClients],
        newClientName: newClientName, existingClientName: existingClientName, ipaddress: ipaddress
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed', result)
      // Qui puoi gestire il risultato
      if (result) {
        this.clientiService.createNewClient(result.newClientName, result.existingClientName, result.ipaddress).subscribe((esito) => {
          if (esito.esito === ESITO_OK) {
            this.snackBar.open($localize`Cliente creato correttamente`, null, {
              duration: 2000,
            })
          } else {
            this.snackBar.open($localize`Errore nella creazione dell'ambiente per il nuovo cliente: ${esito.payload}`, null, {
              duration: 8000,
            })
            // riapri la dialog passando i valori inseriti
            this.openNewClientDialog(result.newClientName, result.existingClientName, result.ipaddress)
          }
        })
      }
    })
  }


  get codiceLingua() {
    // ricava il codice della lingua corrente dalla url
    return window.location.href.split('/')[3]
  }

  onFocus() {
    this.segnalazioniService.notificaFocusInputRicerca.next()
  }
}
