import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { SessionData } from '../../sessione/dati-sessione'

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {

  @ViewChild('languageSelector', { static: false }) languageSelector: ElementRef

  selectedLanguage: string

  languages = []
  showLanguages: any
  shiftAmount = 0
  constructor(private router: Router, private sessionData: SessionData) {}

  ngOnInit(): void {
    // recupero la lingua corrente
    this.selectedLanguage = window.location.href.split('/')[3]
    // cicla sulle lingue e crea un array di oggetti con codice e nome
    this.lingue().forEach((lang) => {
      this.languages.push({
        code: lang,
        name: lang,
        flag: `/assets/img/flags/${lang}.svg`,
      })
    })
  }

  dropdownVisible = false

  toggleLanguages() {
    this.showLanguages = !this.showLanguages

    if (this.showLanguages) {
      const element = this.languageSelector.nativeElement
      const distanceToLeft = element.offsetLeft
      const distanceToRight = window.innerWidth - (element.offsetLeft + element.offsetWidth)

      this.shiftAmount = distanceToRight < distanceToLeft ? this.languages.length * 28 : 0
    } else {
      this.shiftAmount = 0
    }

  }

  languageSelected(language: any) {
    this.selectedLanguage = language
    const url = document.URL
    // costruisco una espressione regolare con le lingue considerando che la ricerca
    // deve essere case insensitive e trovare tutte le occorrenze
    // del tipo /it/ o /en/ o /fr/ ecc. includendo quindi i due slash
    const regex = new RegExp('/(' + this.lingue().join('|') + ')/', 'gi')
    // sostituisco la lingua nella url
    const newUrl = url.replace(regex, `/${this.selectedLanguage['code']}/`)
    // navigo alla nuova url
    window.open(newUrl, '_self')
  }

  get coloreSfondo() {
    const colore = this.sessionData.colorCombo.coloreBackgroundToolbar
    return colore
  }

  get coloreTesto() {
    const colore = this.sessionData.colorCombo.coloreBordoSelettoreLingua
    return colore
  }

  get sonoPronto() {
    return this.sessionData.colorCombo !== undefined
  }

  lingue() {
    return this.sessionData.configurazione?.lingue === undefined ||
      this.sessionData.configurazione?.lingue === null ||
      this.sessionData.configurazione?.lingue?.trim() === ''
      ? []
      : this.sessionData.configurazione?.lingue.split(',')
  }

  get doubleBaloon() {
    return '/assets/img/double-baloon.svg'
  }

}
