import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

import { Esito } from '../utility/esito'
import { ConfigurazioneService } from '../servizi/configurazione.service'

@Injectable()
export class LoginResolver implements Resolve<Esito> {
  constructor(private configurazioneService: ConfigurazioneService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Esito> |
    Promise<Esito> | Esito {
    return this.configurazioneService.recuperaConfigurazione()
  }
}
