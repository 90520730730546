import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { SessionData } from '../sessione/dati-sessione'
import { openDialogGenerica } from '../utility/utility'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  private lastHeartbeatTime = 0
  private sub
  timeoutAvvisoScadenzaSessione: NodeJS.Timeout
  timeoutScadenzaSessione: NodeJS.Timeout
  sessioneInScadenza = new Subject()
  dialogRef: any

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private sessionData: SessionData,
    private route: Router
  ) {
    this.startHeartbeat()
  }

  private startHeartbeat() {
    document.addEventListener('mousemove', this.onUserActivity.bind(this))
    document.addEventListener('keydown', this.onUserActivity.bind(this))
    this.authService.isLoggedIn()
    this.lastHeartbeatTime = Date.now()
    this.avviaTimer()

    this.sub = this.sessioneInScadenza.subscribe(() => {
      console.log(
        `********************** sessione in scadenza nei prossimi ${this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione} secondi !!! *********************`
      )
      this.dialogRef = openDialogGenerica(
        this.dialog,
        $localize`Sessione in scadenza`,
        $localize`La tua sessione scadrà nei prossimi ${this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione} secondi. Per non perdere i dati ancora non salvati è necessario rinnovarla`,
        $localize`Clicca il bottone sotto per aggiornare la sessione`,
        () => this.authService.rinnovaToken(),
        undefined,
        undefined,
        undefined,
        '50vw',
        this.sessionData.configurazione.sogliaAvvisoSecondiScadenzaSessione
      )
    })
  }

  private onUserActivity(event: MouseEvent | KeyboardEvent) {
    // this.sub.unsubscribe()

    // chiudi la finestra di dialogo se è aperta
    this.dialogRef?.close()

    const currentTime = Date.now()
    const timeSinceLastHeartbeat =
      (currentTime - this.lastHeartbeatTime) / 1000
    if (timeSinceLastHeartbeat > 15) {
      this.authService.isLoggedIn()
      this.lastHeartbeatTime = currentTime
      this.avviaTimer()
    }
  }
  avviaTimer() {
    // annullo il timeout di scadenza della sessione
    clearTimeout(this.timeoutAvvisoScadenzaSessione)
    clearTimeout(this.timeoutScadenzaSessione)
    if (!this.sessionData || !this.sessionData.configurazione) {
      return
    }
    // ne predispongo uno nuovo
    const durataSessione = this.sessionData?.configurazione?.durataSessione
    const sogliaAvvisoSecondiScadenzaSessione =
      this.sessionData?.configurazione?.sogliaAvvisoSecondiScadenzaSessione
    const tempoScadenzaSessione =
      durataSessione - sogliaAvvisoSecondiScadenzaSessione
    this.timeoutAvvisoScadenzaSessione = setTimeout(() => {
      this.sessioneInScadenza.next()
    }, tempoScadenzaSessione * 1000)
    this.timeoutScadenzaSessione = setTimeout(() => {
      if (this.route.url !== '/login') {
        if (this.authService.getUser()?.odv) {
          this.route.navigate(['/login'])
        } else {
          this.route.navigate(['/landing-page'])
        }
        this.authService.logout()
      }
    }, durataSessione * 1000)
  }
}
