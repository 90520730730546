import { inject } from '@angular/core'
import {
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    ResolveFn
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfigurazioneBan } from '../modello/configurazione'
import { ConfigurazioneService } from '../servizi/configurazione.service'
import { ESITO_OK } from '../utility/esito'


export const ConfigurazioneBanResolver: ResolveFn<ConfigurazioneBan> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  configurazioneService: ConfigurazioneService = inject(ConfigurazioneService)
): Observable<ConfigurazioneBan> =>
  configurazioneService.recuperaConfigurazioneBan().pipe(
  map(esito => {
      if (esito.esito === ESITO_OK) {
          return JSON.parse(esito.payload)
      } else {
          throw new Error(esito.descrizioneEsito)
      }
  })
)
