<form (ngSubmit)="conferma()">
<div class="flex-column">
  <h2 mat-dialog-title class="titolo-dialog">{{data.oggetto}}</h2>
  <mat-dialog-content class="flex-column">
    <div *ngIf="data.messaggio" [innerHtml]="data.messaggio">
    </div>

    <div class="flex-column" *ngIf="data.labelAltraAzione">
      <button i18n mat-button (click)="onClick()">{{data.labelAltraAzione}}</button>
      <br>
    </div>
    <div class="flex-row flex-100" style="align-items: baseline;">
      <div style="font-weight: bold; text-align: center;">
        {{data.domanda}}
      </div>
      <div *ngIf="data.input" class="flex-row flex-70">
        <mat-form-field>
          <input matInput #reply [(ngModel)]="risposta" i18n-placeholder placeholder="Inserisci la risposta">
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="flex-row flex-center-space-around">
    <button *ngIf="!data.onlyOk && !data.annulla" i18n mat-button mat-dialog-close>No</button>
    <button *ngIf="!data.onlyOk && data.annulla" i18n mat-button mat-dialog-close>Annulla</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button *ngIf="!data.continua" i18n mat-button (click)="conferma()" type="submit">Sì</button>
    <button *ngIf="data.continua" i18n mat-button (click)="conferma()" type="submit">Continua</button>
  </mat-dialog-actions>
</div>
</form>
