import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { openDialogGenerica, ServiziRest } from '../utility/utility'
import { Esito } from '../utility/esito'
import { MatDialog } from '@angular/material/dialog'
import { AuthService } from './auth.service'
import { Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class UserBehaviorTrackingService {
  private behaviorData = {
    mouseMovements: [],
    keyStrokes: [],
    sessionDuration: 0, // Durata della sessione in millisecondi
    clicks: 0
  }

  private sessionStartTime: number

  constructor(private http: HttpClient,
    public dialog: MatDialog,
    public authenticationService: AuthService,
    public router: Router,
    private snackBar: MatSnackBar, ) {
    this.startSession()
  }

  startSession() {
    this.sessionStartTime = new Date().getTime()
  }

  trackMouseMove(event: MouseEvent) {
    this.behaviorData.mouseMovements.push({
      x: event.clientX,
      y: event.clientY,
      timestamp: new Date().getTime()
    })
  }

  trackClick(event: MouseEvent | TouchEvent) {
    this.behaviorData.clicks++
  }

  trackKeyStroke(event: KeyboardEvent) {
    this.behaviorData.keyStrokes.push({
      key: event.key,
      timestamp: new Date().getTime()
    })
  }

  async sendBehaviorData(): Promise<boolean> {
    // Aspetta un breve periodo per catturare tutti gli eventi
    await new Promise(resolve => setTimeout(resolve, 10))
    this.behaviorData.sessionDuration = new Date().getTime() - this.sessionStartTime
    return new Promise((resolve) => {
    const res = this.http.post(ServiziRest.urlBehaviourData, this.behaviorData).subscribe(
      (esito: Esito) => {
        if (esito.esito === 0) {
          console.log('Comportamento normale')
          resolve(true)
        } else {
          console.warn('Comportamento sospetto rilevato')
          // Usa la dialog generica per sottoporre all'utente un test di Turing
          const num2 = Math.floor(Math.random() * 10)
          const num1 = num2 + Math.floor(Math.random() * (10 - num2))
          const operazione = Math.random() > 0.5 ? '+' : '-'

          const domandaMatematica = `${num1} ${operazione} ${num2}`
          const rispostaCorretta = operazione === '+' ? num1 + num2 : num1 - num2
          openDialogGenerica(
            this.dialog,
            $localize`Dimostra di non essere un robot risolvendo questo semplice calcolo`,
            '',
            domandaMatematica,
            (risposta) => resolve(this.verificaRisposta(risposta, rispostaCorretta)),
            () => resolve(this.esci()),
            undefined,
            undefined,
            undefined,
            30,
            undefined,
            undefined,
            true,
            true,
            true,
            true
          )
        }
        // Handle response here
        this.behaviorData = { mouseMovements: [], keyStrokes: [], sessionDuration: 0, clicks: 0 }
      },
      error => {
        console.error('Errore durante il controllo del comportamento', error)
        resolve(false)
      }
    )
  })

}

  verificaRisposta(risposta, rispostaCorretta): boolean {
    // Todo: verifica la risposta
    if (+risposta === +rispostaCorretta) {
      console.log('Risposta corretta')
      return true
    } else {
      console.warn('Risposta errata')
      // informa con una messagebox che l'utente è stato disconnesso
      this.snackBar.open($localize`Risposta non corretta, ritenta!`, undefined, {
        duration: 6000,
      })
      // esegui il logout
      this.esci()
      return false
    }
  }

  esci(): boolean {
    this.startSession()
    this.behaviorData = { mouseMovements: [], keyStrokes: [], sessionDuration: 0, clicks: 0 }
    const odv = this.authenticationService.getUser()?.odv
    this.authenticationService.logout()
    // se la pagina corrente è la login o la landing page, non fare nulla
    // controlla la prima parte dell'url, se è diversa da login o landing-page, allora fai il redirect
    if (this.router.url.indexOf('/login') !== -1 || this.router.url.indexOf('/landing-page') !== -1) {
      return false
    }
    this.router.navigate([odv ? '/login' : odv === undefined ? '' : '/landing-page'])
    return false
  }
}
