<span class="bottone-flottante-alto-sinistra no-print">
  <button mat-mini-fab (click)='back();$event.stopPropagation();' *ngIf='canGoBack()' i18n-matTooltip
    matTooltip="Torna Indietro">
    <mat-icon class="icona-fab" aria-label="Torna indietro">keyboard_arrow_left</mat-icon>
  </button>
</span>

<div style="width:0px;height:0px; overflow:hidden; display:none">

  <a *ngIf="false" mat-button style="width:0px;height:0px; overflow:hidden; display:none" routerLink="."
    id="termini-servizio" (click)="apriDialogTerminiServizio()" i18n>Termini di Servizio</a>

  <a mat-button style="width:0px;height:0px; overflow:hidden; display:none" routerLink="." id="privacy-policy"
    (click)="apriDialogPrivacyPolicy()" i18n>Privacy</a>

  <a *ngIf="false" mat-button style="width:0px;height:0px; overflow:hidden; display:none" routerLink="."
    id="cookies-policy" (click)="apriDialogCookiesPolicy()" i18n>Cookies Policy</a>

  <a mat-button style="width:0px;height:0px; overflow:hidden; display:none" routerLink="." i18n>Faq</a>
</div>

<mat-toolbar class="barra-principale" >
  <span class="language-selector-container-inner distanziatore-selettore-lingua">
    <app-language-selector></app-language-selector>
  </span>
  <span class="flex-fill">
    <img src="{{getLogoCliente()}}" class="logo-cliente logo-centrato logo-dimensione-toolbar">
  </span>


  <span *ngIf="!mostraFiltro" class="flex-fill">
  </span>
  <span *ngIf="mostraFiltro" class="flex-row-20">
    <mat-form-field class="no-print" style="width: 90%; padding: 20px;">
      <input matInput (keyup)="applyFilter($event.target.value)" (focus)="onFocus()" i18n-placeholder placeholder="Trova">
      <mat-label></mat-label>
    </mat-form-field>
  </span>

  <span class="no-print">
    <!-- aggiungo la chip per i consensi da autorizzare -->
    <mat-chip-list *ngIf="consensiDaAutorizzare && consensiDaAutorizzare.length > 0">
      <mat-chip style="cursor:pointer; background-color: rgb(219, 219, 0); color: rgba(0, 0, 0, 0.800);"
        class="height-auto"
        *ngIf="consensiDaAutorizzare!=null && consensiDaAutorizzare.length==1"
        (click)="apriSegnalazioneSelezionata(consensiDaAutorizzare[0])" i18n>
        Richiesta di consenso</mat-chip>
    </mat-chip-list>
  </span>

  <span class="no-print">
    <mat-chip-list *ngIf="autorizzazioniPendenti && autorizzazioniPendenti.length > 0">
      <mat-chip style="cursor:pointer; background-color: rgb(219, 219, 0); color: rgba(0, 0, 0, 0.800);"
        class="height-auto"
        *ngIf="autorizzazioniPendenti!=null && autorizzazioniPendenti.length==1"
        (click)="apriSegnalazioneSelezionata(autorizzazioniPendenti[0])" i18n>
        {{getGestore(autorizzazioniPendenti[0]).nomeOdv}} - Segnalazione {{getSegnalazione(autorizzazioniPendenti[0]).progressivo}}</mat-chip>

      <mat-chip style="cursor:pointer; background-color: rgb(219, 219, 0); color: rgba(0, 0, 0, 0.800);"
        *ngIf="autorizzazioniPendenti!=null && autorizzazioniPendenti.length>1"
        [matMenuTriggerFor]="menuAutorizzazioni" i18n>Hai
        {{autorizzazioniPendenti.length}} autorizzazioni pendenti</mat-chip>
    </mat-chip-list>
    <mat-menu #menuAutorizzazioni="matMenu">
      <button i18n mat-menu-item *ngFor="let autorizzazione of autorizzazioniPendenti"
        (click)="apriSegnalazioneSelezionata(autorizzazione)" class="testo-piccolo">
        {{getGestore(autorizzazione).nomeOdv}} - Segnalazione {{getSegnalazione(autorizzazione).progressivo}}</button>
    </mat-menu>
  </span>

  <span class="no-print">
    <mat-chip-list *ngIf="messaggiNonLetti && messaggiNonLetti.length>0" class="destra" style='margin-right: 5px;'>
      <mat-chip style="cursor:pointer; background-color: rgb(219, 219, 0); color: rgba(0, 0, 0, 0.800);"
        class="height-auto" *ngIf="messaggiNonLetti!=null && messaggiNonLetti.length==1"
        (click)="openChatSegnalazioneDaId(messaggiNonLetti[0])" i18n>Hai
        un nuovo messaggio</mat-chip>
      <mat-chip style="cursor:pointer; background-color: rgb(219, 219, 0); color: rgba(0, 0, 0, 0.800);"
        *ngIf="messaggiNonLetti!=null && messaggiNonLetti.length>1" [matMenuTriggerFor]="menu" i18n>Hai
        {{messaggiNonLetti.length}} nuovi messaggi</mat-chip>
    </mat-chip-list>
    <mat-menu #menu="matMenu">
      <button i18n mat-menu-item *ngFor="let messaggio of messaggiNonLetti"
        (click)="openChatSegnalazioneDaId(messaggio)" class="testo-piccolo">
        {{messaggioDa(messaggio) }}</button>
    </mat-menu>
  </span>
  <span class="no-print">
    <button mat-icon-button class="destra" (click)="apriHome()" *ngIf="puoAprireElencoSegnalazioni" i18n-matTooltip
      matTooltip="Home">
      <mat-icon class="icone-barra-principale">home</mat-icon>
    </button>
  </span>
  <span class="no-print">
    <button mat-icon-button class="destra" (click)="apriGestioneLavorazioni()" *ngIf="false" i18n-matTooltip
      matTooltip="Lavorazioni">
      <mat-icon class="icone-barra-principale">cases</mat-icon>
    </button>
  </span>
  <span class="no-print" *ngIf="superUtente()">
    <button mat-icon-button class="destra" (click)="apriGestioneAziende()" i18n-matTooltip
      matTooltip="Anagrafica Aziende">
      <mat-icon class="icone-barra-principale">business</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="superUtente() || amministratoreGestori()">
    <button mat-icon-button class="destra" (click)="apriGestioneUtenti()" i18n-matTooltip
      matTooltip="Amministrazione Utenti">
      <mat-icon class="icone-barra-principale">people</mat-icon>
    </button>
  </span>
  <span class="no-print" *ngIf="superUtente()">
    <button mat-icon-button class="destra" (click)="apriGestioneGruppi()" i18n-matTooltip
      matTooltip="Amministrazione Gruppi">
      <mat-icon class="icone-barra-principale">groups</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="!utenteSemplice">
    <button mat-icon-button class="destra" (click)="infoUtente()" i18n-matTooltip matTooltip="Info Utente">
      <mat-icon class="icone-barra-principale">person</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="odv()">
    <button mat-icon-button class="destra" (click)="apriGestioneChat()" i18n-matTooltip matTooltip="Gestione Chat">
      <mat-icon class="icone-barra-principale">message</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="odv()">
    <button mat-icon-button class="destra" (click)="apriLogOperazioni()" i18n-matTooltip matTooltip="Log Operazioni">
      <mat-icon class="icone-barra-principale">assignment</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="odv()">
    <button mat-icon-button class="destra" (click)="apriStatistiche()" i18n-matTooltip matTooltip="Statistiche">
      <mat-icon class="icone-barra-principale">pie_chart</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="false">
    <button mat-icon-button class="destra" (click)="apriInformative()" i18n-matTooltip matTooltip="Informative">
      <mat-icon class="icone-barra-principale">article</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="superUtente()">
    <button mat-icon-button class="destra" (click)="apriConfigurazione()" i18n-matTooltip matTooltip="Configurazione">
      <mat-icon class="icone-barra-principale">ballot</mat-icon>
    </button>
  </span>
  <span class="no-print" *ngIf="superUtente()">
    <button mat-icon-button (click)="openColorPicker()">
      <mat-icon class="icone-barra-principale">color_lens</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="custode()">
    <button mat-icon-button class="destra" (click)="apriRichesteSblocco()" i18n-matTooltip
      matTooltip="Richieste di sblocco" matBadge="{{numeroRichiesteSboccoDaLavorare}}"
      [matBadgeHidden]="numeroRichiesteSboccoDaLavorare == 0" matBadgeColor="warn" matBadgeSize="medium">
      <mat-icon class="icone-barra-principale">security</mat-icon>
    </button>
  </span>

  <span class="no-print" *ngIf="odv() || superUtente() || custode() || amministratoreGestori()">
    <button mat-icon-button (click)="apriDialogManualeGestore()" i18n-matTooltip
    matTooltip="Manuale gestore">
    <mat-icon class="icone-barra-principale">help</mat-icon>
  </button>
  </span>

  <span class="no-print" *ngIf="motif">
    <button mat-icon-button (click)="aggiungiNuovoCliente()" i18n-matTooltip
    matTooltip="Aggiungi nuovo cliente">
    <mat-icon class="icone-barra-principale">dashboard_customize</mat-icon>
  </button>
  </span>

  <span class="no-print">
    <button mat-icon-button (click)="logout()" i18n-matTooltip matTooltip="Esci">
      <mat-icon class="icone-barra-principale">exit_to_app</mat-icon>
    </button>
  </span>
</mat-toolbar>
