
import { Injectable } from '@angular/core'

import { Segnalazione } from '../modello/segnalazione'
import { User } from '../modello/user'
import { Messaggio } from '../modello/messaggio'
import { Chat } from '../modello/chat'
import * as moment from 'moment'
import { Configurazione } from '../modello/configurazione'
import { Lavorazione } from '../modello/lavorazione'
import { VersioneContenuto } from '../modello/versione-contenuto'
import { ColorCombination } from '../modello/color-combination'

@Injectable()
export class SessionData {
  testoHome: string
  normativa: string

  constructor() { }

  static filtroGestore = '3'

  static lavorazioniInCorso = true
  static nessunaLavorazioneInCorso = true
  static alcuneLavorazioniChiuse = true
  static bozza = true
  static tutteLeLavorazioniChiuse = true
  static ritirata = true


  //  Dati di stato della maschera statistiche
  static dataDa = moment().subtract(50, 'year').startOf('year')
  static dataA = moment()
  static statisticheTemaTutte = false
  static statisticheTemaMie = true
  static statisticheTemaOdv = false

  static statisticheSorgenteTutte = false
  static statisticheSorgenteMie = true
  static statisticheSorgenteOdv = false

  static statisticheInammissibilitaTutte = false
  static statisticheInammissibilitaMie = true
  static statisticheInammissibilitaOdv = false

  static statisticheAzioniTutte = false
  static statisticheAzioniMie = true
  static statisticheAzioniOdv = false
  static lavorazioneSelezionata: Lavorazione

  static soloTerminiCancellazioneSospesi = false


  segnalazioni: Array<Segnalazione> = []

  configurazione: Configurazione

  segnalazioneSelezionata: Segnalazione
  messaggiSegnalazioneSelezionata: Array<Messaggio> = []
  static segnalazioneSelezionataElenco: Segnalazione
  chatSelezionata: Chat

  static navigazione: string[] = []
  static utenti: User[]
  public logoCliente: VersioneContenuto
  public logoSecure: VersioneContenuto
  public colorCombo: ColorCombination
  pinCode: string

  cambiaSegnalazioneSelezionata(idSegnalazione: number) {
    this.segnalazioneSelezionata = this.segnalazioni.find(segnalazione => +segnalazione.id === idSegnalazione)

    this.messaggiSegnalazioneSelezionata = []
  }

  nuovaSegnalazioneSelezionata(segnalazione: Segnalazione) {
    this.segnalazioneSelezionata = segnalazione
    if (!this.segnalazioni) {
      return
    }
    const indice = this.segnalazioni.findIndex(tmpsegnalazione => tmpsegnalazione.id === segnalazione.id)
    if (indice !== -1) {
      this.segnalazioni.splice(indice, 1, segnalazione)
    } else {
      this.segnalazioni.push(segnalazione)
    }
    if (segnalazione?.id) {
      sessionStorage.setItem('idSegnalazioneSelezionata', segnalazione.id.toString())
    } else {
      sessionStorage.removeItem('idSegnalazioneSelezionata')
    }

    this.messaggiSegnalazioneSelezionata = []
  }

  getSegnalazioneSelezionata() {
    return this.segnalazioneSelezionata

  }

  nuovaChatSelezionata(chat: Chat) {
    this.chatSelezionata = chat
    // this.sessionData.segnalazioneSelezionata = chat.segnalazione;
  }

  reset(): any {
    SessionData.utenti = []
    this.segnalazioneSelezionata = undefined
    SessionData.segnalazioneSelezionataElenco = undefined
    this.messaggiSegnalazioneSelezionata = []
    this.segnalazioni = []
    localStorage.clear()
  }

  getLogoCliente(): VersioneContenuto {
    return this.logoCliente
  }



}
