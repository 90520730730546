import { Injectable } from '@angular/core'
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Configurazione } from '../modello/configurazione'
import { ConfigurazioneService } from '../servizi/configurazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { ESITO_OK } from '../utility/esito'

@Injectable({
    providedIn: 'root'
})
export class ConfigurazioneResolver implements Resolve<Configurazione> {

    constructor(private sessionData: SessionData, private configurazioneService: ConfigurazioneService) { };

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Configurazione> |
        Promise<Configurazione> | Configurazione {
        if (this.sessionData.configurazione) {
            return this.sessionData.configurazione
        }
        return this.configurazioneService.recuperaConfigurazione().pipe(
            map(esito => {
                if (esito.esito === ESITO_OK) {
                    return JSON.parse(esito.payload)[0]
                } else {
                    throw new Error(esito.descrizioneEsito)
                }
            })
        )
    }
}
