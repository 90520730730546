import { Injectable } from '@angular/core'
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { Segnalazione } from '../modello/segnalazione'
import { SegnalazioneService } from '../servizi/segnalazione.service'
import { SessionData } from '../sessione/dati-sessione'
import { ESITO_IN_PROGRESS, ESITO_OK } from '../utility/esito'

@Injectable({
  providedIn: 'root',
})
export class SegnalazioneResolver implements Resolve<Segnalazione> {
  constructor(
    private sessionData: SessionData,
    private segnalazioneService: SegnalazioneService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Segnalazione> | Promise<Segnalazione> | Segnalazione {
    if (this.sessionData.segnalazioneSelezionata) {
      return this.sessionData.segnalazioneSelezionata
    }
    const idSegnalazioneSelezionata = sessionStorage.getItem(
      'idSegnalazioneSelezionata'
    )
    console.log('resolver segnalazione', idSegnalazioneSelezionata)
    if (!idSegnalazioneSelezionata) {
      return undefined
    }
    return this.segnalazioneService
      .recuperaSegnalazione(+idSegnalazioneSelezionata)
      .pipe(
        map((esito) => {
          if (ESITO_OK === esito.esito) {
            this.sessionData.segnalazioneSelezionata = JSON.parse(
              esito.payload
            ) as Segnalazione
            return this.sessionData.segnalazioneSelezionata
          } else if (esito.esito === ESITO_IN_PROGRESS) {
            // Gestisci lo stato in progress come preferisci
            // Ad esempio, potresti mostrare un messaggio di attesa all'utente
            console.log(esito.descrizioneEsito)
          } else {
            throw new Error(esito.payload)
          }
        })
      )
  }
}
