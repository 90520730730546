import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { Odv } from '../modello/odv'
import { AuthService } from '../servizi/auth.service'
import { GestoriSegnalazioniDataService } from '../servizi/gestori-segnalazioni-data.service'
import { GestoriSegnalazioniService } from '../servizi/gestori-segnalazioni.service'
import { RicercaService } from '../servizi/ricerca.service'
import { SessionData } from '../sessione/dati-sessione'

import { compare, openDialogGenerica } from '../utility/utility'
import { ModificaGruppoDialogComponent } from './modifica-gruppo.dialog.component'

@Component({
  selector: 'app-gestione-gruppi',
  templateUrl: './gestione-gruppi.component.html',
  styleUrls: ['./gestione-gruppi.component.scss']
})
export class GestioneGruppiComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  dataSource
  colonneVisualizzate = ['id', 'nomeOdv', 'predefinito', 'azioni']
  gruppoSelezionato: Odv
  gruppi: Odv[]
  sortedData: Odv[]
  gruppo: Odv
  subscriptions: Subscription[] = []

  constructor(private authService: AuthService, private dialog: MatDialog,
    private snackBar: MatSnackBar, private gruppiService: GestoriSegnalazioniService,
    private gruppiDataService: GestoriSegnalazioniDataService, private sessionData: SessionData,
    private ricercaService: RicercaService, private router: Router, private route: ActivatedRoute,) { }
  ngAfterViewInit(): void {
    if (this.paginator) {
      const paginatorIntl = this.paginator._intl
      paginatorIntl.nextPageLabel = ''
      paginatorIntl.previousPageLabel = ''
      paginatorIntl.lastPageLabel = ''
      paginatorIntl.firstPageLabel = ''
    }
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      // console.log(data);
      const configurazione = data['configurazione']
      if (configurazione) {
        this.sessionData.configurazione = configurazione
      } else {
        console.error(data)
        this.router.navigate(['/landing-page'], { queryParams: { initialize: true} })
      }
      return
    })

    const sub = this.gruppiService.gruppiGestoriChanged.subscribe(odvs => {
      this.gruppi = odvs
      this.aggiornaDataSource()
    }, error => {
      this.snackBar.open(error.message, null, {
        duration: 4000,
      })
    })
    this.subscriptions.push(sub)

    this.subscriptions.push(this.ricercaService.filtraElenco.subscribe((cercami: string) => {
      this.applyFilter(cercami)
    }))

    this.subscriptions.push(this.gruppiDataService.recuperaOdvs().subscribe(
      () => { },
      (error) => {
        this.snackBar.open(error.message, null, {
          duration: 4000,
        })
      }
    ))
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe())
  }

  aggiornaDataSource() {
    this.sortedData = this.gruppi.slice()
    this.dataSource = new MatTableDataSource(this.gruppi)
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  sortData(sort: Sort) {
    const data = this.gruppi.slice()
    if (!sort.active || sort.direction === '') {
      this.sortedData = data
      return
    }

    this.sortedData = data.sort((a: Odv, b: Odv) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc)
        case 'nomeOdv': return compare(a.nomeOdv, b.nomeOdv, isAsc)
        case 'predefinito': return compare(a.predefinito, b.predefinito, isAsc)
        default: return 0
      }
    })
  }

  applyFilter(filterValue: string) {

    if (this.dataSource) {
      this.dataSource.filterPredicate =
        (data: Odv, filter: string) => data['id'].toString().indexOf(filter) !== -1
          || (data['nomeOdv'] && data['nomeOdv'].toLowerCase().indexOf(filter) !== -1)
      filterValue = filterValue.trim() // Remove whitespace
      filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue
    }

  }

  selected(gruppo: Odv) {
    this.gruppoSelezionato = gruppo
  }

  aggiungiGruppo() {
    console.log('aggiungo gruppo...')
    const odv = new Odv()
    this.openDialogModificaGruppo(odv)
  }

  get puoCreareGruppi() {
    return true
  }

  get superUtente() {
    return this.authService.getUser().su
  }

  openDialogConfermaEliminazioneGruppo(gruppo: Odv) {
    openDialogGenerica(this.dialog, $localize`Eliminazione Gruppo ` + gruppo.nomeOdv, '',
    $localize`Confermi la cancellazione del gruppo?`,
      () => this.eliminaGruppo(gruppo),
      () => { })
  }

  eliminaGruppo(gruppo: Odv) {
    console.log('elimino', gruppo)
    this.gruppiDataService.cancellaOdv(gruppo).subscribe(
      (message) => {
        this.snackBar.open(message, null, {
          duration: 4000,
        })
      },
      (error) => {
        this.snackBar.open(error.message, null, {
          duration: 4000,
        })
      },
    )
  }

  openDialogModificaGruppo(gruppo: Odv, errore?: string) {
    this.gruppo = gruppo
    const dialogRef = this.dialog.open(ModificaGruppoDialogComponent, {
      width: '800px',
      height: 'auto',
      data: { 'gruppo': gruppo, 'errore': errore },
      disableClose: true,
      hasBackdrop: true
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log('result:', result)
      if (result) {
        //  SessionData.password = result;
        this.gruppo = result.data
        this.salvaGruppo(this.gruppo)
      }
    })
  }

  salvaGruppo(gruppo: Odv) {
    if (!gruppo) {
      this.snackBar.open($localize `Gruppo non definito!`, null, {
        duration: 4000,
      })
      return
    }
    let chiamata: Observable<Odv>
    if (!gruppo.id) {
      chiamata = this.gruppiDataService.creaOdv(gruppo)
    } else {
      chiamata = this.gruppiDataService.aggiornaOdv(gruppo)
    }

    chiamata.subscribe(
      odv => {
        this.snackBar.open($localize `Operazione eseguita con successo`, null, {
          duration: 4000,
        })
      },
      error => {
        this.snackBar.open(error.message, null, {
          duration: 6000,
        })
      }
    )
  }


}
