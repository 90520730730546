import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-conferma-decisione-dialog',
  templateUrl: 'conferma-decisione.html',
})
export class ConfermaDecisioneDialogComponent implements AfterViewInit {
  risposta: string
  // Utilizza ElementRef invece di HTMLElement e specifica che si tratta di un input
  @ViewChild('reply') rispostaInput: ElementRef<HTMLInputElement>


  constructor(
    public dialogRef: MatDialogRef<ConfermaDecisioneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

    // Implementa il metodo ngAfterViewInit per impostare il focus
    ngAfterViewInit() {
      // Controlla se l'elemento input esiste e se l'opzione data.input è true
      if (this.data.input && this.rispostaInput) {
        // Utilizza un timeout per attendere il completamento del caricamento del DOM
        setTimeout(() => {
          this.rispostaInput.nativeElement.focus()
        }, 0) // Il timeout può essere 0 poiché ngAfterViewInit viene eseguito dopo il caricamento del DOM
      }
    }

  onClick() {
    this.data.altraAzione()
  }

  conferma() {
    this.dialogRef.close(this.rispostaInput?.nativeElement?.value || true)
  }
}
