import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Esito, ESITO_OK } from '../utility/esito'
import { ServiziRest } from '../utility/utility'
import { Documento, TipoDocumento } from '../modello/documento'
import { Subject } from 'rxjs'
import { Informativa } from '../modello/informativa'
import { Lavorazione } from '../modello/lavorazione'
import { Messaggio } from '../modello/messaggio'
import { Segnalazione } from '../modello/segnalazione'
import { SessionData } from '../sessione/dati-sessione'

@Injectable({
  providedIn: 'root'
})
export class DocumentiService {
  constructor (private http: HttpClient, private authenticationService: AuthService, private sessionData: SessionData) { }

  aggiungiDocumento = new Subject<{tipoEntitaPrincipale: string; entitaPrincipale: object; documento: Documento}>()

  handleErrorPromise (error: Response | any) {
    console.error(error.message || error)
    return Promise.reject(error.error || error)
  }

  cancellaDocumento (documento: Documento): Promise<null> {
    return this.http.delete(ServiziRest.urlDocumenti + '/' + documento.id).toPromise()
      .then((response) => null)
      .catch(this.handleErrorPromise)
  }

  salvaDocumento (documento: Documento): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlDocumenti, documento).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  downloadDocumento (documento: Documento, tipo: TipoDocumento, bypassAuth = false): Promise<string> {
    return this.http.get<Documento>((bypassAuth ? ServiziRest.urlDocumentiBypassAuth : ServiziRest.urlDocumenti) + '/' + documento.hash +
    '/' + tipo).toPromise()
      .then((response: Documento) => (response.base64 as string))
      .catch(this.handleErrorPromise)
  }

  eliminaDocumento (documento: Documento): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.delete<Esito>(ServiziRest.urlDocumenti + '/' + documento.id, options).toPromise()
      .then(response => response)
      .catch(this.handleErrorPromise)
  }

  scollegaDocumentoDaSegnalazione (documento: Documento, segnalazione: Segnalazione): Promise<string> {
    return this.http.delete<string>(ServiziRest.urlScollegaDocumentoDaSegnalazione + '/' + documento.id +
      '/' + segnalazione.id).toPromise()
      .then((response: string) => (response as string))
      .catch(this.handleErrorPromise)
  }

  scollegaDocumentoDaInformativa (informativa: Informativa): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.delete<Esito>(ServiziRest.urlScollegaDocumentoDaInformativa +
      '/' + informativa.id, options).toPromise()
      .then((response: Esito) => (response as Esito))
      .catch(this.handleErrorPromise)
  }

  collegaDocumentoASegnalazione (documento: Documento, segnalazione: Segnalazione): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlCreaOAggiornaCollegamentoTraDocumentoESegnalazione +
      '/' + segnalazione?.id, documento).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  collegaDocumentoAInformativa (informativa: Informativa): Promise<Esito> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const options = { headers: headers }
    return this.http.post<Esito>(ServiziRest.urlCollegaDocumentoAInformativa,
      informativa, options).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  collegaDocumentoALavorazione (documento: Documento, lavorazione: Lavorazione): Promise<Esito> {
    return this.http.post<Esito>(ServiziRest.urlCreaOAggiornaCollegamentoTraDocumentoELavorazione +
      '/' + lavorazione.id, documento).toPromise()
      .then((response: Esito) => response)
      .catch(this.handleErrorPromise)
  }

  scollegaDocumentoDaLavorazione (documento: Documento, lavorazione: Lavorazione): Promise<string> {
    return this.http.delete<string>(ServiziRest.urlScollegaDocumentoDaLavorazione +
      '/' + documento.id +
      '/' + lavorazione.id).toPromise()
      .then((response: string) => (response as string))
      .catch(this.handleErrorPromise)
  }

  scollegaDocumentoDaMessaggio (documento: Documento, messaggio: Messaggio): Promise<string> {
    return this.http.delete<string>(ServiziRest.urlScollegaDocumentoDaMessaggio + '/' + documento.id +
      '/' + this.sessionData.segnalazioneSelezionata.id).toPromise()
      .then((response: string) => (response as string))
      .catch(this.handleErrorPromise)
  }

  public async recuperaFileDaRepository (nomeFile: string, tipoFile: string): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlDownloadDocumentoDaRepository + '/' + nomeFile + '/' + tipoFile).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }

  public async getDocumentiAssociatiASegnalazione (segnalazione: Segnalazione): Promise<Esito> {
    return this.http.get<Esito>(ServiziRest.urlDocumentiAssociatiASegnalazione + '/' + segnalazione.id).toPromise()
      .then(esito => esito)
      .catch(this.handleErrorPromise)
  }
}
